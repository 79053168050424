/**
* Dalton Behavior to handle Login, logout and Register user events
*
*/
SNI.Application.addBehavior('dalton/login-logout', (context) => {

  let dalton = context.getService('dalton/dalton-interface'),
      debug = context.getService('logger').create('behavior.dalton.login-logout');

  /** @clickHandlers
   *  User Events (clicks): Login, Logout, Register
   */
  const clickHandlers = {
    'idsp-login': (event, element) => {
      debug.log('request Dalton login');
      event.preventDefault();
      event.stopPropagation();
      dalton.login();
    },
    'idsp-logout': (event, element) => {
      debug.log('request Dalton logout');
      event.preventDefault();
      event.stopPropagation();
      dalton.logout();
    },
    'idsp-register': (event, element) => {
      debug.log('request Dalton registration');
      event.preventDefault();
      event.stopPropagation();
      dalton.register();
    }
  };

  async function init() {
    debug.log('init');
    dalton.ckState();
  }

  return {

    init,

    onclick(event, element, elementType) {
      if (elementType in clickHandlers) {
        clickHandlers[elementType](event, element);
      }
    },

  };

});
