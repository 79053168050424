import '../services/logger';
import '../services/device-type';
import '../services/modal';
import '../services/utility';
import '../services/template';
import '../services/saves-modal';
import '../services/cloudinary';
import {
  saveAsset,
  getAssetCard,
  getAssets,
  getBoards,
  createBoard,
  addToBoard,
  removeFromBoard,
  setApiEndpoint,
  setSavesToken
} from '../libs/saves-api';


/*
 * Saves the asset on click.
 */
SNI.Application.addModule('asset-save', (context) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  const mdManager = context.getGlobal('mdManager');
  const detailId = mdManager.getDetailId();
  const [assetType, assetType2, assetTypeDisp] = {
    'recipe-beta': ['recipe', 'foodnetwork', 'Recipe'],
    'recipe':  ['recipe', 'foodnetwork', 'Recipe'],
    'singlevideopage': ['video', 'foodnetwork-video', 'Video'],
    'photogallerypage': ['gallery', 'foodnetwork-gallery', 'Collection'],
    'photoGalleryPage': ['gallery', 'foodnetwork-gallery', 'Collection'],
    'verticalPhotoGallery': ['gallery', 'foodnetwork-gallery', 'Collection'],
  }[mdManager.getPageType()];
  const parentEl = context.getElement();
  const mName = 'asset-save';
  const debug = context.getService('logger').create('module.' + mName);
  const dalton = SNI.Config.useDaltonLogin ? context.getService('dalton/dalton-interface') : null;
  const userInterface = SNI.Config.useDaltonLogin ? context.getService('user/user-data') : null;
  const modalService = context.getService('modal');
  const template = context.getService('template');
  const SavesModal = context.getService('saves-modal');
  const Cloudinary = context.getService('cloudinary');

  const $saveButton = $(parentEl).find('[data-type="save-asset"]');
  const savedText = 'Saved';
  const saveHtml = `<span class="a-Button__a-ButtonText">Save ${assetTypeDisp}</span>`;
  const savedHtml = `<span class="a-Button--Saved__a-TextWrap">${savedText}</span> <span class="a-Button--Saved__a-Divider">|</span> <span class="a-Button--Saved__a-AddToBoard" data-type="add-to-board">Add to My Boards</span>`;

  let config, cardID, state;

  let defaults = {
    savesEndpointUrl: 'https://api.foodnetwork.com/fn-web/v1',
    modalClassName: 'add-to-board',
    folders: []
  };

  // KD - Remove/refactor after shopping list launch successful
  const newSLVersion = context.getGlobal('isShoppingListEnabled');
  debug.log('newSLVersion: ', newSLVersion);

  function mapFolders() {
    return config.folders.map(item => {
      item.title = escapeHtml(item.title);
      item.image = Cloudinary.cropImg(item.images[0], 'w_110,h_90,fl_progressive,q_70,c_fill');
      return template.wrapSavesBoard(item);
    });
  }

  function updateContent(modal) {
    if (!modal) return;

    // Make html templates from folders
    let boards = mapFolders();

    // Updated content for modal
    let content = {
      modalId: detailId,
      header: {
        title: 'Add To'
      },
      footer: {
        actionText: 'Done'
      },
      body: {
        html: template.addToBoardModalBody(boards)
      }
    };

    // Update Content for modal and show updated modal.
    modal.loadContent(content);
    modal.show();

    // Bind event listners to the modal ( Modals are unsubscribing from all listners when modal.hide() or modal.show() called )
    bindClickListeners(detailId, modal);
  }

  function escapeHtml(text) {
    'use strict';
    return text.replace(/[\"&<>]/g, function(a) {
      return { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;' }[a];
    });
  }

  // Set/Unset disabled for button
  function toggleButton(id) {
    if ($(`#${id} input:checked`).length === 0 && !config.folders.some((folder) => folder.containsCard)) {
      $(`#${id} [data-button-primary]`)
        .addClass('o-Modal__a-Button--Disabled')
        .attr('disabled', true);
    } else {
      $(`#${id} [data-button-primary]`)
        .removeClass('o-Modal__a-Button--Disabled')
        .removeAttr('disabled', true);
    }
  }

  // Bind click listners to addToBoard modal
  let bindClickListeners = function(modalId, modal) {

    // Set/Unset disabled for button
    toggleButton(modalId);

    // Get Updated list of boards (used below)
    let getUpdatedBoards = () => {
      getBoards(cardID, function(boards) {
        config.folders = boards.folders;
      }, function(err) {
        debug.error('getBoards error: ', arguments);
      });
    };

    // On Primary button clicked
    $(`#${modalId} [data-button-primary]`).off().on('click', function() {

      // Add or Remove asset from board
      $(`#${modalId} input:checkbox`).each(function() {
        const currentFolder = config.folders.find(coll => coll.id === $(this).attr('id'));
        const isChecked = $(this).is(':checked');

        if (isChecked && !currentFolder.containsCard) {
          // Add Asset to Selected Folder
          addToBoard(cardID, currentFolder.id, function() {

            // Get Updated list of boards
            getUpdatedBoards();

            debug.log('Add To Board Success');
          }, function() {
            debug.log('Add To Board Error', arguments);
          });
        } else if (!isChecked && currentFolder.containsCard) {
          // Remove asset from unchecked folder
          removeFromBoard(cardID, currentFolder.id, function() {
            debug.log('Remove From Board Success');
            currentFolder.containsCard = false;

            // Get Updated list of boards
            getUpdatedBoards();
          }, function() {
            debug.log('Remove From Board Error', arguments);
          });
        }

      });

      modal.hide();
    });

    //On board select
    $(`#${modalId} .o-Modal__a-Boards__board`).off().on('change', function(event) {

      const $input = $(this).find('input');

      // Find board with clicked input ID
      let selectedBoard = config.folders.find(coll => coll.id === $input.attr('id'));

      // Set board.selected to preserve selection when new board created
      selectedBoard.selected = $input.is(':checked');

      // Set/Unset disabled for button
      toggleButton(modalId);
    });

    // On Close Click
    $(`#${modalId} [data-type="modal-close"]`).off().on('click', function() {
      modal.hide();
    });

    // On Create New Board Click
    $(`#${modalId} [data-create-board]`).off().on('click', function() {
      modal.hide();

      // Show New Board Modal
      invokeNewBoardOverlay(modalId+'_new', modal);
    });
  };

  // Add To Board Modal
  function invokeOverlay() {

    // Do not create new modal if there is a modal with this modalId
    let addToBoardModal = modalService.getModal(detailId);
    if (addToBoardModal) {
      updateContent(addToBoardModal);
      return;
    }

    // Make html templates from folders
    let boards = mapFolders();

    // Content for addToBoardModal
    addToBoardModal = createModal(
      detailId,
      'Add To',
      'Done',
      template.addToBoardModalBody(boards)
    );

    // Add Class to modal for RB like styling
    addToBoardModal.addClass(config.modalClassName);
    addToBoardModal.show();

    // Bind event listners to the addToBoardModal ( Modals are unsubscribing from all listners when addToBoardModal.hide() or addToBoardModal.show() called )
    bindClickListeners(detailId, addToBoardModal);
  }

  // Invoke New Board Modal Window.
  function invokeNewBoardOverlay(modalId, addToBoardModal) {

    // Bind Clicks for New Board Modal
    function bindClicks() {

      // On Close Click
      $(`#${modalId} [data-type="modal-close"]`).off().on('click', function() {
        // Hide New Board Modal on success.
        newBoardModal.hide();

        // Show Add To Board Modal
        addToBoardModal.show();

        // Bind event listners to the modal ( Modals are unsubscribing from all listners when modal.hide() or modal.show() called )
        bindClickListeners(detailId, addToBoardModal);
      });

      // On Backdrop Click
      $('.o-Modal.add-to-board.add-to-board--new.in').off().on('click', function() {
        // Hide New Board Modal on success.
        newBoardModal.hide();

        // Show Add To Board Modal
        addToBoardModal.show();

        // Bind event listners to the modal ( Modals are unsubscribing from all listners when modal.hide() or modal.show() called )
        bindClickListeners(detailId, addToBoardModal);

        // Unsubscribe listener
        $('.o-Modal.add-to-board.add-to-board--new.in').off();
      });

      // On Esc Click
      $('body').off().on('keyup.modaldismiss', function(e) {
        // If key is Esc
        if (e.keyCode === 27) {
          // Hide New Board Modal on success.
          newBoardModal.hide();

          // Show Add To Board Modal
          addToBoardModal.show();

          // Bind event listners to the modal ( Modals are unsubscribing from all listners when modal.hide() or modal.show() called )
          bindClickListeners(detailId, addToBoardModal);
        }
      });

      // On Enter Click
      $(`#${modalId} form`).submit(function() {
        submitNewBoard();
        return false;
      });

      // Stop Propagition on dialog body clicked
      $('[data-modal-dialog]').on('click', function(e) {
        e.stopPropagation();
      });

      // On Primary Button Click.
      $(`#${modalId} [data-button-primary]`).off().on('click', function() {
        submitNewBoard();
      });

      function submitNewBoard() {
        let $input = $(`#${modalId} input`);

        // Grab the input value.
        let title = $input.val();
        debug.log('Adding New Board: ', title);

        if (!title) return;

        // Create new Board
        createBoard(title, function(folder) {

          // Make new folder selected by default
          folder.selected = true;

          // Push new folder to config.folders
          config.folders.unshift(folder);

          // Hide New Board Modal on success.
          newBoardModal.hide();

          // Clear input
          $input.val('');

          // Show Add to Board Modal And Update Modal Content
          updateContent(addToBoardModal);
        }, function(err) {
          // On createBoard Error
          debug.error(`createBoard(${ title }, authToken, callback) error: `, err);
        });
      }
    }

    // Do not create new modal if there is a modal with this modalId
    let newBoardModal = modalService.getModal(modalId);
    if (newBoardModal) {
      newBoardModal.show();
      bindClicks();
      return;
    }

    // Content for New Board Modal
    newBoardModal = createModal(
      modalId,
      'Board Name',
      'Done',
      '<input type="text" name="newBoardName" placeholder="New Board name..." aria-label="New Board name">'
    );

    // Add Class to modal for RB like styling
    newBoardModal.addClass(config.modalClassName);
    // Add class with --new modifier to indicate that it's new board
    newBoardModal.addClass(`${config.modalClassName}--new`);
    // Show New Board Modal
    newBoardModal.show();
    // Bind event listners to the modal ( Modals are unsubscribing from all listners when modal.hide() or modal.show() called )
    bindClicks();
  }

  function createModal(id, title = '', actionText = 'Done', html) {
    const content = {
      modalId: id+'_content',
      header: { title },
      footer: { actionText },
      body: { html }
    };

    return modalService.create({
      modalId: id,
      content,
      overrides: { noScroll: true }
    });
  }

  function setSavedState() {
    debug.log('setSavedState');
    $saveButton
      .removeClass('a-Button--Save')
      .addClass('a-Button--Saved')
      .removeAttr('data-type')
      .attr('title', `Saved ${assetTypeDisp} | Add to My Boards`)
      .find('svg.a-Icon--Bookmark').hide().end()
      .find('span').remove().end()
      .append(savedHtml);
  }

  function removeSavedState() {
    $saveButton
      .removeClass('a-Button--Saved')
      .addClass('a-Button--Save')
      .attr('data-type', 'save-asset')
      .attr('title', `Save ${assetTypeDisp}`)
      .find('svg.a-Icon--Bookmark').show().end()
      .find('span').remove().end()
      .append(saveHtml);
  }

  function openaddToBoardModal() {
    if (config.folders && config.folders.length > 0) {
      invokeOverlay();
    } else {
    // Get list of boards
      getBoards(cardID, function(boards) {
        config.folders = boards.folders;
        invokeOverlay();
      }, function(err) {
        debug.error('Open add-to-board modal error', arguments);
      });
    }
  }

  function onAssetInit() {
    debug.log('onAssetInit() ');
    getAssetCard({id: detailId, type: assetType2}, function(card) {
      cardID = card.id;
      setSavedState();
    }, function(err) {
      debug.log('Asset not saved');
    });
  }

  function onAssetSave() {
    let assetToSave = {
      brand: 'foodnetwork',
      id: detailId,
      asset: assetType,
    };

    debug.log('onAssetSave ' + detailId + ' ' + assetType);

    saveAsset(assetToSave, function(resp) {
      // update the button to say 'Saved' and get cardID
      debug.log('onAssetSave callback ' + resp);
      if (resp) {
        debug.log('onAssetSave callback resp true');
        getAssetCard({id: detailId, type: assetType2}, function(card) {
          cardID = card.id;
          debug.log('getAssetCard callback ' + cardID);
          setSavedState();
        });

        getAssets(function(cards) {
          debug.log('getAssets callback' + cards.count);
          if (cards.count <= 1) {
            SavesModal.showAssetSavedDialog(assetTypeDisp);
          }
        });
      }
    });
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      setSavesToken(userInterface.getUserToken());
      if (state === 'INIT') {
        debug.log('logged in INIT ' + userInterface.getUserToken());
        onAssetInit();
      } else if (state === 'SAVE_ASSET') {
        debug.log('logged in SAVE_ASSET');
        onAssetSave();
      }
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      // RESET BUTTON TO DEFAULT UNSAVED hidePrivateNotes();
      if (state === 'INIT') {
        debug.log('logged out INIT');
        removeSavedState();
      } else if (state === 'SAVE_ASSET') {
        // nothing done; back to INIT
        debug.log('logged out SAVE_ASSET');
        state = 'INIT';
      }
    },
  };

  function configSavesEndpointUrl() {
    if (config.savesEndpointUrl){
      setApiEndpoint(config.savesEndpointUrl);
    }
  }

  let onclick = (event, element, elementType) => {
    switch (elementType) {
      case 'save-asset':
        event.preventDefault();
        debug.log('click save-asset');
        state = 'SAVE_ASSET';
        if (!userInterface.getLoginStatus()) {
          debug.log('pop Dalton login');
          dalton.login();
        } else {
          onAssetSave();
        }
        break;

      case 'add-to-board':
        event.preventDefault();
        openaddToBoardModal();
        break;

      default:
        break;
    }
  };

  function init() {
    debug.log('init');
    config = Object.assign({}, defaults, context.getConfig());

    configSavesEndpointUrl();

    state = 'INIT';
  }

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    init,

    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    },

    onclick,

    setSavedState,
    escapeHtml,
  };
});
