SNI.Application.addService('logger', function(application) {

  let util  = application.getService('utility');
  let debugMode = util.isDebug();
  let devConsole = application.getGlobal('console');

  function output(level, msg, namespace) {
    if (debugMode && devConsole && devConsole[level]) {
      logFormat(msg, namespace);

      devConsole[level].apply
        ? devConsole[level].apply(devConsole, msg)
        : devConsole[level](msg);
    }
  }

  function logFormat(args, namespace) {
    var idlen = 30,
        minlen = 5,
        seplen = (namespace.length < idlen) ? idlen - namespace.length : minlen,
        repeatChar = function(chr, times) { return new Array(times).join(chr); };

    args.unshift(`[SNI-CORE] ${repeatChar(' ', minlen)} ${namespace} ${repeatChar('-', seplen)}>`);
  }

  class Logger {

    constructor(namespace) {
      this.namespace = namespace;
    }

    log(...msg) {
      output('log', msg, this.namespace);
    }

    warn(...msg) {
      output('warn', msg, this.namespace);
    }

    error(...msg) {
      output('error', msg, this.namespace);
    }

    table(...msg) {
      output('table', msg, this.namespace);
    }

  }

  return {

    create(namespace) {
      return new Logger(namespace);
    }

  };

});
