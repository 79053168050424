import './logger';

SNI.Application.addService('saves-modal', (application) => {

  const modalService = application.getService('modal');

  function createModal(id, title = '', actionText = 'Done', html) {
    const content = {
      modalId: id+'_content',
      header: { title },
      footer: { actionText },
      body: { html }
    };

    return modalService.create({
      modalId: id,
      content,
      overrides: { noScroll: true }
    });
  }

  // On First Asset Saved
  function showAssetSavedDialog(assetTypeDisp, modalID = 'savedAssetModal') {
    const hideAssetClickEvent = 'click.hideSavedAssetModal';
    const position = $('.o-HeaderFresh__a-Icon--bookmark').offset();
    const hideModal = () => savedAssetModal.hide();
    const showModal = () => savedAssetModal.show();
    const setPosition = () => $('.asset-saved .o-Modal__m-Dialog').css({left: position.left - 100});
    const scrollTop = () => $('html, body').animate({ scrollTop: 0 }, 'slow');
    const enableScroll = () => $('body').removeClass('modal-force-open');
    const hideBackdrop = () => $('[data-modal-backdrop]').css({opacity : 0});
    const configureAndShow = () => {
      setPosition();
      scrollTop();
      showModal();
      hideBackdrop();
      enableScroll();
    };

    let savedAssetModal = modalService.getModal(modalID);

    if (savedAssetModal) {
      return configureAndShow();
    }

    // Content for Asset Saved Modal
    savedAssetModal = createModal(
      modalID,
      `${assetTypeDisp} Saved`,
      'Got It',
      '<p>Find all your saved items here.</p>'
    );

    savedAssetModal.addClass('asset-saved');
    configureAndShow();

    $('#savedAssetModal [data-button-primary]').off(hideAssetClickEvent, hideModal).on(hideAssetClickEvent, hideModal);
    $('[data-modal-backdrop]').off(hideAssetClickEvent, hideModal).on(hideAssetClickEvent, hideModal);
  }

  return {
    showAssetSavedDialog,
  };

});
