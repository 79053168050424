import './utility';

SNI.Application.addService('track-shopping', context => {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  const shopTrack = context.getGlobal('shopTrack'),
        debug = context.getService('logger').create('service.track-shopping'),
        utils = context.getService('utility'),
        mdManager = context.getGlobal('mdManager');

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  function trackShopping(data, eventType) {
    let componentName = data.componentname || mdManager.getParameter('componentName'),
        partner = data.partner || mdManager.getParameter('partner'),
        productData = utils.escapeHtml(data.title || data.products),
        imageCount = data.imagecount || mdManager.getParameter('imageCount'),
        productCount = data.productcount || mdManager.getParameter('productCount');

    const omniture = context.getGlobal('s');

    if (omniture && shopTrack && mdManager) {
      debug.log('trackShopping: metadata: ', {componentName, partner, productData, imageCount, productCount, eventType});
      shopTrack(
        this,
        'shopping',
        componentName,
        'n/a',
        partner,
        productData,
        imageCount,
        productCount,
        'n/a',
        'n/a',
        'n/a',
        eventType
      );
    }
  }

  function trackProductClick(metadata) {
    trackShopping(metadata, 'click');
  }

  function trackProductImpression(metadata) {
    trackShopping(metadata, 'impression');
  }

  return {
    trackShopping,
    trackProductClick,
    trackProductImpression
  };

});
