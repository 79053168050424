SNI.Application.addBehavior('media-stream', function(context) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let behavior;

  const mediaStreamService = context.getService('media-stream');
  const messageHandlers = {
    'mediaStream.readyForNextSS': () => {
      mediaStreamService.gotoSS('next');
    },
    'mediaStream.readyForPreviousSS': () => {
      mediaStreamService.gotoSS('previous');
    },
    'ajax.areaContentLoading': () => {
      mediaStreamService.lowerCurtain();
    },
    'ajax.areaContentLoaded': () => {
      mediaStreamService.raiseCurtain();
    },
    'ajax.modulesInitialized': (data) => {
      mediaStreamService.initializeNewContent(data);
    }
  };

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  behavior = {
    messages: Object.keys(messageHandlers),
    onmessage: function(msg, data) {
      messageHandlers[msg](data);
    }
  };

  return behavior;
});
