/* eslint-disable no-console */

SNI.Application.addService('debug', function(application) {

  var util = application.getService('utility'),
      module = {
        _getMode: function() {
          if ( util.getUrlParam(window.location.href, 'debug') === 'true' || localStorage.getItem('debug') === 'true') {
            return true;
          } else {
            return false;
          }
        }
      };

  function addConsoleMethods(module, methods){
    var method = methods.pop();
    if ( typeof method === 'undefined' ){
      return true;
    } else {
      module[method] = function() {
        var self = this,
            args = Array.prototype.slice.call(arguments);

        if (self.mode === false) { return; }

        if (window.console && window.console[method])
          if (console[method].apply)
            console[method].apply(console, args);
          else
            // Some browsers don't support .apply() for console[method], so just pass an array
            console[method](args);
      };
      addConsoleMethods(module, methods);
    }
  }

  addConsoleMethods(module, ['log', 'warn', 'error']);

  module.mode = module._getMode();

  return module;
});
