SNI.Application.addBehavior('horizontal-scroll', context => {

  const debug = context.getService('logger').create('behaviors.horizontal-scroll');
  const utility = context.getService('utility');
  const templateService = context.getService('template');
  const device = context.getService('device-type');

  let element = context.getElement();
  let $element = $(element);
  let $scrollElem = $element.find('[data-horizontal-scroll]');
  let currentState,
      inited = false;
  const defaults = {
    offsetLeft: 0,
    offsetRight: 0,
    fullWidth: 0,
    viewportWidth: 0,
    rightButtonExists: false,
    leftButtonExists: false,
    throttleTime: 250,
    scrollDistance: 1000,
    allowedScrollOptions: ['left', 'right']
  };
  const baseConfig = context.getConfig();
  let $activeItem;
  let activePos;
  let $firstElem;
  let firstOffset = 0;
  let autoAdvance = baseConfig && baseConfig.autoAdvance;
  /*
    Auto Advance is used by the Episode Season List
    If the auto advance config is present then it will find the active item and scroll to it
  */
  if (autoAdvance) {
    $activeItem = $element.find('.is-Active');
    if ($activeItem && $activeItem.length){
      activePos = $activeItem.get(0).getBoundingClientRect();
    }
    $firstElem = $scrollElem.children().get(0);
    if ($firstElem) {
      firstOffset = $firstElem.offsetLeft;
    }
  }

  const getElementFullWidth = () => $scrollElem[0].scrollWidth;
  const getElementViewportWidth = () => $scrollElem.innerWidth() + 21; // Account for 21px inner elements margins to make sure buttons do not appear when only a margins goes off the screen.
  const getScrollOffsetLeft = () => $scrollElem.scrollLeft();
  const getScrollOffsetRight = () => getElementFullWidth() - (getElementViewportWidth() + getScrollOffsetLeft());

  const onScroll = () => {
    currentState.offsetLeft = getScrollOffsetLeft();
    currentState.offsetRight = getScrollOffsetRight();
    initScrollButtons();
  };

  const scroll = (direction) => {
    if (!currentState.allowedScrollOptions.includes(direction)) {
      return debug.warn(`Invalid 'direction' param. ${currentState.allowedScrollOptions} options allowed. Received ${direction}`);
    }

    const scrollLeft = direction === 'left'
      ? currentState.offsetLeft -= currentState.scrollDistance
      : currentState.offsetLeft += currentState.scrollDistance;

    $scrollElem.stop().animate({
      scrollLeft
    }, 500);
  };

  const initScrollButtons = () => {
    // Add Right Scroll Button
    if (!currentState.rightButtonExists && currentState.fullWidth > currentState.viewportWidth) {
      $scrollElem.parent().append(templateService.scrollRightButton());
      currentState.rightButtonExists = true;
    }

    // Remove Right Scroll Button
    if (currentState.rightButtonExists && currentState.offsetRight <= 0) {
      $element.find('[data-type="scroll-right"]').remove();
      currentState.rightButtonExists = false;
    }

    // Add Left Scroll Button
    if (!currentState.leftButtonExists && currentState.offsetLeft > 0) {
      $scrollElem.parent().append(templateService.scrollLeftButton());
      currentState.leftButtonExists = true;
    }

    // Remove Left Scroll Button
    if (currentState.leftButtonExists && currentState.offsetLeft <= 0) {
      $element.find('[data-type="scroll-left"]').remove();
      currentState.leftButtonExists = false;
    }
  };

  const getInitialOffset = () => {
    let offset = 0;
    if ($activeItem && $activeItem.length) {
      offset = ($activeItem && $activeItem.length) ? activePos.x : getScrollOffsetLeft();
    }
    return offset;
  };

  const initHorizontalScroll = () =>  {
    let offsetLeft = getInitialOffset();
    const offsetAmount = offsetLeft - firstOffset;
    if (autoAdvance) {
      // Advance the scroll but take into account the initial position of the component
      debug.log('Scroll Left:', offsetAmount);
      $scrollElem.scrollLeft(offsetAmount);
    }
    if (device.isMobile || device.isTablet) {
      //Do not apply scroll buttons for mobile
      return;
    }

    requestAnimationFrame(() => {
      // make dropdown element rendered on the page in hidden mode
      // to get proper width and make calculations
      $element.css({
        visibility: 'hidden',
        display: 'block'
      });

      currentState = Object.assign({}, defaults, {
        fullWidth: getElementFullWidth(),
        viewportWidth: getElementViewportWidth(),
        offsetLeft: offsetAmount,
        offsetRight: getScrollOffsetRight(),
        scrollDistance: getElementViewportWidth(),
      }, baseConfig);

      initScrollButtons();

      $scrollElem.on('scroll', utility.throttle(onScroll, currentState.throttleTime));

      // return dropdown to hidden state after all calculations performed
      $element.css({
        visibility: '',
        display: ''
      });
      inited = true;
    });
  };

  return {
    messages: ['hoverHeader'],
    init() {
      const settings = context.getConfig('horizontalScroll');
      if (!inited && ( !settings || !settings.deferInit )) {
        initHorizontalScroll();
      }
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'scroll-right':
          event.preventDefault();
          scroll('right');

          break;

        case 'scroll-left':
          event.preventDefault();
          scroll('left');

          break;

        default:
          break;
      }
    },

    onmessage(name,data) {
      if (name === 'hoverHeader') {
        if (!inited) {
          initHorizontalScroll();
        }
      }
    }
  };
});
