export const buildURL = (brand, term) => {

  let host = (typeof window !== 'undefined' && window.location && window.location.host) ? `${window.location.host}/` : '';
  let protocol = host === '' ? '' : '//';

  const local = [/localhost/];
  const qa = [/fnkweb-qa.sni.foodnetwork.com/, /qa.fnkweb.sni.foodnetwork.com/];
  const prod = [/\/*.fnk-web.api.digitalstudios.discovery.com/, /fnkweb.sni.foodnetwork.com/, /kitchen.foodnetwork.com/];
  const any = [...local, ...qa, ...prod];
  const isFood = any.some(item => item.test(host));

  if (isFood) {
    brand = 'food';
    host = 'www.foodnetwork.com/';
  }
  
  return `${protocol}${host}apps/sni-foundation/servlets/typeAhead?searchTerm=${term}&site=${brand}`;
};


export const fetchBrandResults = brand => (emitter, { type, value }) => {
  if (type === 'value') {
    $.ajax(buildURL(brand, value)).done(data => {
      emitter.emit(data);
    });
  }
};

export default fetchBrandResults(SNI.Config.brand);
