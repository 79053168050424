/**
* REFACTORED: Dalton functionality now attached in header module, but this stub remains
*               to create an ID needed for a11y
*
*/

SNI.Application.addModule('nav-profile', (context) => {

  const debug = context.getService('logger').create('module.nav-profile');

  if (SNI.Config.useDaltonLogin) {
    debug.log('NOOP -- Dalton behavior applied in header module.');
  }

  function init() {
    debug.log('init');
  }

  return {

    init,

  };

});
