import { fromEvents, combine, merge } from 'kefir';
import { resultsList } from './resultTemplates';
import resultFilter from './resultFilter';
import fetchResults from './fetchResults';
import { showHints, hideHints } from './hints';

export const MAX_TYPEAHEAD_SUGGESTIONS = 8;
export const SEARCH_TERM_LENGTH = 3;

/**
 * @param {JQueryElement} $element The jquery wrapper element for the associated module
 * @param {HTMLElement} hintsContainerEl The element that contains the rendered hints
 * @param {Array<stream>} clearStreams A group of streams that on value should clear the typeahead hints
 */
export const clearHints = (
  $element,
  clearStreams,
  debug,
  hide = hideHints
) =>
  merge(clearStreams).onValue(() => {
    hideHints($element, debug);
  });

/**
 * @param {JQueryElement} $element The jquery wrapper element for the associated module
 * @param {string} parentModuleClass
 * @param {stream} searchTextStream A stream emitting the text for the current searched phrase
 * @param {stream} typeAheadServiceStream A stream emitting all the results matching the current search term
 * @param {function} resultsFilter A filter fed to kefirs combine that filters down the typeahead results into a small hints list
 * @param {function} show alias to showHints for easier testing
 */
export const typeAhead = (
  $element,
  parentMoleculeClass,
  searchTextStream,
  typeAheadServiceStream,
  resultsFilter,
  debug,
  show = showHints
) => {

  // debug.log('typeAhead(): $element: ', $element);

  combine([searchTextStream, typeAheadServiceStream], resultsFilter)
    .map(
      ({ searchText, hints }) => {
        // debug.log('typeAhead().combine().map(): searchText: ', searchText);
        // debug.log('typeAhead().combine().map(): hints: ', hints);
        return (hints.length > 0 && searchText.length >= SEARCH_TERM_LENGTH)
          ? resultsList(parentMoleculeClass, hints, searchText)
          : '';
      }
    )
    .onValue(hints => {
      // debug.log('typeAhead().combine().map().onValue(): $element: ', $element);
      // debug.log('typeAhead().combine().map().onValue(): hints: ', hints);
      show($element, hints, debug);
    });
};

/**
 * @param {stream} searchStream A stream emitting strings to search for
 * @param {SEARCH_TERM_LENGTH} len The length of the search term to be sent to the server
 * @param {function} fetch Makes a request to the typeahead search service
 */
export const typeAheadServiceStream = (
  searchStream,
  len = SEARCH_TERM_LENGTH,
  fetch = fetchResults
) =>
  searchStream
    .filter(searchText => searchText.length >= len) // Only accept strings of valid length
    .map(searchText => searchText.slice(0, len)) // Get the first len characters to be used as search term
    .skipDuplicates() // Only fetch results for the term once
    .withHandler(fetch); // Do the fetch to the search service

SNI.Application.addBehavior('typeahead', context => {
  return {
    onmousedown(event, element, elementType) {
      // Ensure focusout isn't fired
      if (elementType === 'typeahead-hint') {
        event.preventDefault();
      }
    },
    onclick(event, element, elementType) {
      const input = context.getElement().querySelector('input');
      switch (elementType) {
        case 'typeahead-hint':
          input.value = element.innerText;
          $(input).submit();
      }
    },
    init() {
      const debug = context.getService('logger').create('behavior.typeahead.index'); // uncomment to use
      const element = context.getElement();
      const $element = $(element);
      const input = element.querySelector('input');
      const listenForFocusOut = context.getConfig('listenForFocusOut');
      const parentMoleculeClass =
        context.getConfig('selectorPrefix') || 'm-SearchBox';

      const hasFocusListener = typeof listenForFocusOut !== 'undefined' && listenForFocusOut !== null ? listenForFocusOut : true;

      // Activation streams. These are the source for typeahead results.
      const inputStream = fromEvents(input, 'input', e => {
        return e.target.value.toLowerCase();
      });
      const focusStream = fromEvents(input, 'focus', e => {
        return e.target.value.toLowerCase();
      });

      // Search streams. These power the typeahead functionality.
      const searchStream = merge([inputStream, focusStream]);
      const searchTextStream = searchStream.debounce(300);


      // Deactivation streams. Should result in typeahead hint menu going away.
      const belowMinLengthTextStream = inputStream.filter(
        text => text.length < SEARCH_TERM_LENGTH
      );
      const losesFocusStream = fromEvents(
        element,
        'focusout',
        e => !element.contains(e.relativeTarget)
      );

      let clearHintHandlers = [belowMinLengthTextStream];
      if (hasFocusListener) clearHintHandlers.push(losesFocusStream);

      clearHints($element, clearHintHandlers, debug);

      typeAhead(
        $element,
        parentMoleculeClass,
        searchTextStream,
        typeAheadServiceStream(searchStream),
        resultFilter(MAX_TYPEAHEAD_SUGGESTIONS, SEARCH_TERM_LENGTH),
        debug
      );
    }
  };
});
