SNI.Application.addModule('footer', (context) => {
  'use strict';

  let defaults = {
    brazeApiUrl: "",
    brazeApiKey: "",
    brandSlug: ""
  };

  let userInterface = context.getService('user/user-data');
  let debug = context.getService('logger').create('module.footer');
  let check = context.getService('check').new(debug);
  let config = Object.assign({}, defaults, context.getConfig());
  let skimLinksLoaded = false;


  const disableOptimizelyPopOut = () => {
    const performOptOut = () => {
      window.optimizely.push({
        type: 'optOut',
        isOptOut: true
      });
    };

    if (window.optimizely) {
      performOptOut();
      return;
    }

    // https://docs.developers.optimizely.com/web-experimentation/reference/add-listener#example-call-1
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'initialized'
      },
      handler: performOptOut
    });
  };
  /*  Simple module to handle scroll top link with animation  */

  const messageHandlers = {
    'optanon-loaded': data => {
      // When 'C0004' is in the active groups we consider that as OK to proceed.  
      // When it is not present, then that is an opt out.
      let OnetrustActiveGroups = check.exists('OnetrustActiveGroups') ? context.getGlobal('OnetrustActiveGroups') : null;

      if (OnetrustActiveGroups && OnetrustActiveGroups.includes('C0004')) {
        const {disableAffiliation, permittedPage, skimId} = data;

        if (disableAffiliation === 'false' && permittedPage) {
          if (!skimLinksLoaded && skimId) {
            let skimLinksScript = document.createElement('script');
            skimLinksScript.src = `https://s.skimresources.com/js/${skimId}.skimlinks.js`;
            skimLinksScript.type = 'text/javascript';
            skimLinksScript.async = true;
            document.body.appendChild(skimLinksScript);
            skimLinksLoaded = true;
          }
        }
      } else {
        disableOptimizelyPopOut();
      }
    }

  }; //end messageHandlers

  return {
    behaviors: ['lazy-load', 'hover-intent'],
    messages: Object.keys(messageHandlers),
    init: async function() {
      let braze;
      if (config.brandSlug === "food" || config.brandSlug === "hgtv") {
        braze = await import('@braze/web-sdk' /* Braze library */);

        braze.initialize(config.brazeSdkKey, {
          baseUrl: config.brazeSdkUrl,
          enableLogging: true
        });
  
        // show all in-app messages without custom handling
        braze.automaticallyShowInAppMessages();

        // set the current user's external ID before starting a new session
        // you can also call `changeUser` later in the session after the user logs in
        if (userInterface.getLoginStatus()) braze.changeUser(userInterface.getUserId());
  
        // `openSession` should be called last - after `automaticallyShowInAppMessages`
        braze.openSession();
      }
    },
    onmessage(msg, data) {
      messageHandlers[msg](data);
    },
    onclick: function(event, element, elementType) {
      if (elementType==='scroll-top') {
        event.preventDefault();

        // https://stackoverflow.com/questions/4034659/is-it-possible-to-animate-scrolltop-with-jquery
        $('html, body').animate({ scrollTop: 0}, 200);
      }

      if (elementType === 'one-trust') {
        let Optanon = check.exists('Optanon') ? context.getGlobal('Optanon') : null;
        if (Optanon) {
          Optanon.ToggleInfoDisplay();
        } else {
          debug.error('Optanon not present');
        }
      }
    }
  };
});
