SNI.Application.addService('user/user-data', (application) => {

  const debug = application.getService('logger').create('service.user/template');
  let check = application.getService('check').new(debug);
  const USER_NAME_KEY = '_dalton_login_user_name';
  const IS_LOGGED_IN_KEY = '_dalton_login_status';
  const AVATAR_KEY = '_dalton_login_user_avatar';
  const USER_TOKEN_KEY = '_dalton_login_token';
  const USER_ID_KEY = '_dalton_login_user_id';
  const TRACKING_LOGIN_KEY = '_gigya_login_status';
  const TRACKING_LOGIN_NAME = '_gigya_login_user_name';
  const TRACKING_LOGIN_ID = '_gigya_login_user_id';

  function setUserAvatar(avatar) {
    return setStorageItem(AVATAR_KEY, avatar);
  }

  function getUserAvatar() {
    return getStorageItem(AVATAR_KEY);
  }

  function clearUserAvatar() {
    return clearStorageItem(AVATAR_KEY);
  }

  function setUserName(name) {
    return setStorageItem(USER_NAME_KEY, name);
  }

  function getUserName() {
    return getStorageItem(USER_NAME_KEY);
  }

  function clearUserName() {
    return clearStorageItem(USER_NAME_KEY);
  }

  function setLoginStatus(status) {
    return setStorageItem(IS_LOGGED_IN_KEY, status);
  }

  /**
   * Used for analytics login tracking.
   * The tracking values have gigya in the naming, but is actually using values from idsp/Dalton
   * The naming will be updated in a future ticket
   * @param status
   */
  function setLoginTracking(status) {
    let eventType = status ? 'loggedIn' : 'loggedOut';
    let event = new CustomEvent(eventType, {
      bubbles: true,
      cancelable: true,
      detail: null
    });

    try {
      localStorage.setItem(TRACKING_LOGIN_KEY, status);
      localStorage.setItem(TRACKING_LOGIN_NAME,getUserName());
      localStorage.setItem(TRACKING_LOGIN_ID,getUserId());
    } catch (e) {
      document.cookie = `${TRACKING_LOGIN_KEY}=${status}`;
      document.cookie = `${TRACKING_LOGIN_NAME}=${getUserName()}`;
      document.cookie = `${TRACKING_LOGIN_ID}=${getUserId()}`;
    }
    document.dispatchEvent(event);
  }

  function getLoginStatus() {
    return getStorageItem(IS_LOGGED_IN_KEY) === 'true';
  }

  function setUserToken(token) {
    return setStorageItem(USER_TOKEN_KEY, token);
  }

  function getUserToken() {
    return getStorageItem(USER_TOKEN_KEY);
  }

  function clearUserToken() {
    return clearStorageItem(USER_TOKEN_KEY);
  }

  function setUserId(id) {
    return setStorageItem(USER_ID_KEY, id);
  }

  function getUserId() {
    return getStorageItem(USER_ID_KEY);
  }

  function clearUserId() {
    return clearStorageItem(USER_ID_KEY);
  }

  function getUser() {
    return {
      id: getUserId(),
      userName: getUserName(),
      avatar: getUserAvatar(),
      loginStatus: getLoginStatus(),
      token: getUserToken(),
    };
  }

  function getStorageItem(item) {
    if (check.supports('localStorage') && localStorage.getItem(item)){
      return localStorage.getItem(item);
    } else {
      return '';
    }
  }

  function setStorageItem(item, value) {
    if (check.supports('localStorage')) {
      localStorage.setItem(item, value);
    }
  }

  function clearStorageItem(item) {
    if (check.supports('localStorage')) {
      window.localStorage.removeItem(item);
    }
  }

  return {
    getUser,
    setUserAvatar, getUserAvatar,clearUserAvatar,
    setUserName, getUserName, clearUserName,
    setUserId, getUserId, clearUserId,
    setLoginStatus, getLoginStatus, setLoginTracking,
    setUserToken, getUserToken, clearUserToken,
  };
});
