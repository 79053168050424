/**
*  Service to provide interface to Dalton client SDK
*
*
*/

SNI.Application.addService('dalton/dalton-interface', (application) => {

  const brandMap = new Map([['food', 'fn'], ['cook', 'cctv']]);

  let debug = application.getService('logger').create('service.dalton.dalton-interface'),
      userInterface = application.getService('user/user-data'),
      IDSP,
      cfgIDSP = {
        api: SNI.Config.daltonApiEndpoint,
        formsLoc: SNI.Config.daltonFormsEndpoint,
        dalton: SNI.Config.daltonDirectEndpoint,
        core: 'cctv',
        assetsLoc: '',
        autoLoadCss: true,
        brand: brandMap.get(SNI.Config.brand),
        init: false,
        ready: false,
        depsReady: false,
        disable: false,
      },
      idsp_requested = false;

  const isCooking = SNI.Config.brand === 'cook';
  const defaultUsername = isCooking ? 'Cooking Channel User' : 'Food Network User';

  //===========================================================================
  // * Loading the SDK script and waiting for it to be ready; all asynchronous.
  //===========================================================================

  // reference to hoist the promise resolve function to module scope:
  let flag_IDSP_isReady;

  // promise to latch the ready flag:
  let p_IDSP_isReady = new Promise(function(resolve, reject) {
    flag_IDSP_isReady = resolve;                   // eslint-disable-line no-func-assign
  });

  // SDK will let us know when it's ready:
  window.__IDSP_isReady = function(theIDSP) {
    debug.log('window.__IDSP_isReady() called; setting module var IDSP and resolving promise');
    IDSP = theIDSP;
    // resolve the promise to unblock
    flag_IDSP_isReady();
    return;
  };

  // lets us wait for the IDSP to get ready:
  async function ck_IDSP_isReady() {
    debug.log('ck_IDSP_isReady()');
    return p_IDSP_isReady;
  }

  // load the SDK by creating and inserting a <script> element
  async function loadSDK() {
    debug.log('loadSDK() start');
    let sdkElt = document.createElement('script');
    sdkElt.setAttribute('src', SNI.Config.daltonFormsEndpoint + 'main.js');
    sdkElt.setAttribute('type', 'text/javascript');
    debug.log('loadSDK() constructing promise with load listener');
    let p = new Promise(function(resolve, reject) {
      sdkElt.addEventListener('load', function loadListener() {
        sdkElt.removeEventListener('load', loadListener);
        debug.log('loadSDK() loadListener Dalton SDK loaded');
        resolve(true);
      });
    });
    debug.log('loadSDK() appending <script>');
    document.body.appendChild(sdkElt);
    debug.log('loadSDK() return onload promise');
    return p;
  }

  // check that the SDK is ready, loading if necessary
  async function ckSDKReady() {
    if (!IDSP && !idsp_requested) {
      idsp_requested = true;
      debug.log('ckSDKReady() Dalton SDK is not loaded');
      await loadSDK();
      debug.log('ckSDKReady() after loadSDK() ' + typeof(IDSP));
      await ck_IDSP_isReady();
      debug.log('ckSDKReady() after ck_IDSP_isReady() ' + typeof(IDSP));
      IDSP.setConfig(cfgIDSP);
      // let non-AEM platforms (FNK) know the IDSP is loaded and configured:
      document.dispatchEvent( new CustomEvent('IDSP-CORE:is-ready', { detail: { IDSP } }) );
    } else if (!IDSP && idsp_requested) {
      // avoid double-loading the SDK:
      debug.log('ckSDKReady() Dalton SDK is being loaded');
      return false;
    }
    if (IDSP) {
      debug.log('ckSDKReady() Dalton SDK is loaded, ready, and configured');
      return true;
    }
  }

  //===========================================================================
  // * handlers for SDK responses which save state (logged in/out, user data)
  //   with auxillary methods for retrieving and broadcasting user data
  //===========================================================================

  // centralize handling result of login/logout/register/etc.
  async function handleLogInOut(res) {
    debug.log('handleLogInOut(): ' + JSON.stringify(res));
    let { isLoggedIn, token } = res;
    userInterface.setLoginStatus(isLoggedIn);
    if (isLoggedIn) {
      userInterface.setUserToken(token);
      await IDSP.getUser({ cb: handleUser });
    } else {
      userInterface.clearUserToken();
      userInterface.clearUserId();
      userInterface.clearUserName();
      userInterface.clearUserAvatar();
    }
    userInterface.setLoginTracking(isLoggedIn);
    broadcastState();
  }

  function handleUser(res) {
    debug.log('handleUser(): ' + JSON.stringify(res));
    let {user, success} = res;
    if (success) {
      if (user.tid)
        userInterface.setUserId(user.tid);
      // let username = user?.userProfileResponses?.buffet?.attributes?.nickname;
      let username = '';
      try {
        username = user.userProfileResponses.buffet.attributes.unmoderatedNickname || user.userProfileResponses.buffet.attributes.nickname;
      } catch (e) {
        debug.log('handleUser(): did not retrieve user nickname');
      }
      if (!username)
        // username = user?.firstName;
        try { username = user.firstName; } catch (e) { debug.log('handleUser(): did not retrieve user firstName'); }
      if (!username)
        try { username = user.userEmailResponses.find(x => x.primary).emailAddress.split('@')[0]; } catch (e) { debug.log('handleUser(): did not retrieve user email'); }
      if (!username)
        username = defaultUsername;
      userInterface.setUserName(username);
      // I wish: if (user?.userProfileResponses?.buffet?.attributes?.thumbnailURL)
      try {
        let avatarurl = new URL(user.userProfileResponses.buffet.attributes.umnoderatedThumbnailURL || user.userProfileResponses.buffet.attributes.thumbnailURL);
        if (avatarurl)
          userInterface.setUserAvatar('//' + avatarurl.hostname + avatarurl.pathname);
      } catch (e) {
        debug.log('handleUser(): did not retrieve user avatar');
      }
    } else {
      debug.log('handleUser(): getUser() failed');
    }
  }

  // profile form
  //  - returns a user if saved
  //  - returns a minimal indication if chained to password reset form => log out
  //  - returns a logged in response if further chained to login form
  function handleProfile(res) {
    debug.log('handleProfile(): ' + JSON.stringify(res));
    if (res.resetPassword === true)
      handleLogInOut({ isLoggedIn: false, token: '' });
    else if (res.isLoggedIn === true)
      handleLogInOut(res);
    else if (res.user) {
      handleUser({ ...res, success: true });
      broadcastState();                         // possible user (nickname or avatar) update to UI
    }
  }

  // forgot password return => probably don't want to change login state
  function handleForgot(res) {
    debug.log('handleForgot(): ' + JSON.stringify(res));
  }

  // check and broadcast login/logout state
  function broadcastState() {
    debug.log('broadcastState()');
    if (userInterface.getLoginStatus()) {
      debug.log('broadcasting logged-in');
      application.broadcast('dalton:logged-in', { nickname: userInterface.getUserName(), avatar: userInterface.getUserAvatar() } );
    } else {
      debug.log('broadcasting logged-out');
      application.broadcast('dalton:logged-out');
    }
    debug.log('exit broadcastState()');
  }

  //===========================================================================
  // * Public interface: requests from the UI
  //===========================================================================

  async function ckState() {
    if (await ckSDKReady()) {
      debug.log('ckState(): IDSP ready; to callback');
      IDSP.checkSession({ cb: handleLogInOut });
    } else {
      debug.log('ckState(): IDSP not ready; nothing done');
    }
  }

  async function login() {
    await ckSDKReady();
    debug.log('pop Dalton login modal');
    IDSP.showLogin({ cb: handleLogInOut });
    debug.log('returned from Dalton login modal');
  }

  async function logout() {
    await ckSDKReady();
    debug.log('do Dalton logout');
    IDSP.logoutUser({ cb: handleLogInOut });
  }

  async function register() {
    await ckSDKReady();
    debug.log('pop Dalton register screen');
    IDSP.showRegistration({ cb: handleLogInOut });
  }

  async function profile(opt) {
    await ckSDKReady();
    debug.log('insert Dalton profile form');
    IDSP.showProfile({ cb: handleProfile, ...opt });
  }

  async function resetPassword(opt) {
    await ckSDKReady();
    debug.log('insert Dalton reset password form');
    IDSP.showResetPassword({ cb: handleLogInOut, ...opt });
  }

  async function forgotPassword(opt) {
    await ckSDKReady();
    debug.log('insert Dalton forgot password form');
    IDSP.showForgotPassword({ cb: handleForgot, ...opt });
  }

  function closeForm() {
    IDSP.closeForms('user-canceled');
  }

  async function refresh() {
    await ckSDKReady();
    debug.log('AEM header log-in state refresh requested');
    IDSP.checkSession({ cb: handleLogInOut });
  }

  return {
    ckState,
    login,
    logout,
    register,
    profile,
    resetPassword,
    forgotPassword,
    closeForm,
    refresh,
  };

});
