SNI.Application.addBehavior('track-shopping', context => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  let $element,
      $shoppingModules,
      config;

  const modUtil = context.getService('utility'),
        debug = context.getService('logger').create('behavior.track-shopping'),
        shopping = context.getService('track-shopping'),
        defaults = {
          carouselSelector: '.m-Carousel',
          narrativeSelector: '.is-narrative',
          isGrid: false,
          activeClass: '.active',
          inspirationOrganism: 'o-InspirationalProduct',
          productListBodySelector: '.o-ProductList__m-Body',
          productSelector: '.m-Product'
        };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function getMetaData(el, container = el, narrative = false) {
    let $el = $(el),
        metadata1 = $(container).data('mdm') || {},
        metadata2 = {};

    if (narrative) {
      metadata2 = $el.data('mdm');
    } else {
      if (!$el.find('[data-module="carousel"]').length) {
        metadata2 = $(config.carouselSelector, el)
          .find('[data-mdm]')
          .data('mdm');
      } else {
        metadata2 = $el.find('[data-module="carousel"]')
          .find(config.activeClass)
          .find('[data-mdm]')
          .data('mdm');
      }
    }

    return updateObject(metadata1, metadata2);
  }

  function getParentMetadata(container) {
    let metadata1 = $(container).data('mdm') || {};
    return metadata1;
  }

  function trackProductDisplayOnScroll() {
    $shoppingModules.each((i, el) => {
      const elConfig = SNI.Application.getModuleConfig(el);
      const trackedEl = el.className.includes(config.inspirationOrganism) ? $(el).find(config.productListBodySelector)[0] : el;
      const inView = modUtil.isInViewport(trackedEl, 'partial');
      const narrative = $(el).find(config.narrativeSelector).length ? true : false;
      const isGrid = elConfig && elConfig.isGrid;

      if (inView) {
        if (narrative) {
          let parent = el;
          let $products = $(parent).find(config.productSelector).not('[data-viewed="1"]');
          let productCount = $products.length;
          $products.each((j, product) => {
            const productInView = modUtil.isInViewport(product, 'partial');
            if (productInView) {
              let metadata =  getMetaData(product, parent, true);
              shopping.trackProductImpression(metadata);
              $products = $products.not($(product));
              $(product).attr('data-viewed', 1);
              productCount--;
              if (productCount === 0) {
                $shoppingModules = $shoppingModules.not($(parent));
                $(parent).attr('data-viewed', 1);
              }
            }
          });
        } else if (isGrid) {
          let parent = el;
          let $products = $(parent).find(config.productSelector).not('[data-viewed="1"]');
          let productCount = $products.length;
          let meta = [];
          let prodsString = '';
          if (productCount === 0) {
            $(parent).attr('data-viewed', 1);
            return;
          }
          $products.filter((i, prod) => {
            return modUtil.isInViewport(prod, 'partial');
          }).each((k, prod) => {
            let prodMeta = $(prod).data('mdm');
            if (prodMeta.products) {
              meta.push(prodMeta.products);
            }
            $(prod).attr('data-viewed', 1);
          });
          if (meta && meta.length > 0) {
            prodsString = meta.join(',;');
            let finalMeta = updateObject(getParentMetadata(el), {products: prodsString});
            shopping.trackProductImpression(finalMeta);
          }
        } else {
          let metadata = getMetaData(el);

          shopping.trackProductImpression(metadata);

          $shoppingModules = $shoppingModules.not($(el));

          $(el).attr('data-viewed', 1);
        }
      }
    });
  }

  function getShoppingModules($context) {
    $shoppingModules = $context
      .find('[data-module="shopping"],[data-module="shopping-embed"]')
      .not('[data-viewed="1"]');
  }

  function init() {
    debug.log('init');

    const handler = modUtil.throttle(trackProductDisplayOnScroll, 100);

    $element = $(context.getElement());
    setConfig(defaults, context.getConfig());

    if ($element.has('[data-module|="shopping"]').length) {
      getShoppingModules($element);

      $(window).on('scroll.shopping', handler);
    }
  }

  //-----------------------------------------------------------
  // Public API
  //-----------------------------------------------------------

  return {
    messages: ['scroll-tracker.viewed'],

    init,

    onmessage(name, data) {
      switch (name) {
        case 'scroll-tracker.viewed':
          if ($element.has('[data-module|="shopping"]').length) {
            getShoppingModules($element);
          }
          break;
      }
    }
  };
});
