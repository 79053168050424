var SNI = window.SNI = window.SNI || {};

SNI.Config                        = SNI.Config || {};
SNI.Config.Video                  = SNI.Config.Video || {};
SNI.Config.enableShopThisLook     = true;
SNI.Config.enableHeaderLogin      = ['hgtv','diy','gac'];
SNI.Config.enableSocialComments   = ['diy'];

SNI.Config.isEnabled = function(key) {
  var getUrlParam = function(param) {
          param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + param + '=([^&#]*)'),
              results = regex.exec(location.search);
          return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      },
      capitalize = function(val) {
          return val.charAt(0).toUpperCase() + val.slice(1);
      },
      out = false,
      override = getUrlParam(key);
      key = 'enable' + capitalize(key);

  if (!override && Array.isArray(SNI.Config[key])) {
    out = (SNI.Config[key].indexOf(SNI.Config.brand) >= 0);
  } else {
    out = (override === 'true') ? true : (override === 'false') ? false : SNI.Config[key];
  }
  return out;
};
