/**
 * hover-intent
 *
 * This behavior listens for click events on a data-type of menu-action. It prevents
 * the first click from passing through, but allows a subsequent click.
 */

SNI.Application.addBehavior('hover-intent', context => {

  const debug = context.getService('logger').create('behavior.hover-intent');
  const check = context.getService('check').new(debug);
  const utils = context.getService('utility');
  const lazy = context.getService('lazy-load');

  utils.addJavaScriptCssClass();

  if (!check.jqueryPlugin('hoverIntent')) return {};

  const defaults = [{
    dataType: 'sub-menu-action',
    timeout: 350,
    reverse: {
      dropdown: false,
      flyout: false
    }
  }, {
    dataType: 'menu-action',
    reverse: {
      dropdown: false,
      flyout: true
    }
  }];

  const config = context.getConfig('hoverIntent') || defaults;
  const configGlobalIntent = context.getConfig('hoverIntentGlobal');
  const headerTheme = context.getConfig('darkThemeHeader');
  const $main = $(context.getElement()); // the "current element" is the o-Header__m-Area--Main
  const $navFlyout = $('.m-NavItemWrap--flyOutNavItem, .m-NavItemWrap--profile');
  const headerEl = document.querySelector('.o-Header');
  const overlayClass = 'has-Overlay';
  const modalClass = 'modal-overlay';
  const flyoutSelectors = '[data-animate-menu="animate"]';
  const searchForm = '.m-SearchForm__m-Area';
  const searchHasHintsClass = 'has-Hints';
  const $hintsContainer = $main.find('[data-typeahead-hints]');

  if (!Array.isArray(config)) {
    debug.error(`Expected context to be an array but got type ${typeof config}`);
    return {};
  }

  const hoverTypes = config.map(({ dataType }) => dataType);
  let modalTimeout;

  let lastElement = null;
  const emulationPlugin = check.jqueryPlugin('emulateTransitionEnd');
  const transition = $.support.transition && emulationPlugin;

  // Called by non-area header
  function openMenu() {
    $(this).addClass('is-Expanded');
    $main.addClass('is-Open');
    debug.log('openMenu(): $main.hasClass(is-Open): ', $main.hasClass('is-Open'));
  }

  // called by area header hover intent
  function hoverHeader(e){
    const $container = $('[data-module="header"]');
    lazy.lazyLoadImgsOutOfView($container);
    $main.addClass('is-Open');
    $(document.body).addClass(overlayClass);
    context.broadcast('hoverHeader');
    $('.flyout-animation').removeClass('flyout-animation');
    if (transition) {
      $main.one($.support.transition.end, () => {
        $('.is-OpenMenu').find('[data-animate-item]').addClass('flyout-animation');
      }).emulateTransitionEnd(300);
    } else {
      $('.is-OpenMenu').find('[data-animate-item]').addClass('flyout-animation');
    }
  }

  function transitionItems($items) {
    $items.each(function(i){
      $(this).css('transition-delay', (i * 30) + 'ms').addClass('flyout-animation');
    });
  }

  function overlayTouch(el) {
    $main.addClass('is-Open');
    $(document.body).addClass(overlayClass);
    $(el).parent('[data-animate-menu]').addClass('is-OpenMenu');
    $(el).find('.o-Header__m-DropdownMenu').addClass('is-Hovered');
    $('.flyout-animation').removeClass('flyout-animation');
    let $items = $(el).find('[data-animate-item]');
    if ($items.length>1) {
      transitionItems($items);
    } else {
      $items.addClass('flyout-animation');
    }
  }

  function removeOverlayTouch(el) {
    $main.removeClass('is-Open');
    $(document.body).removeClass(overlayClass);
  }

  function hoverOverlay(e, isTouch) {
    let $menu = $(this);
    let hasDropDown = $menu.find('[data-dropdown]').data('dropdown')||$menu.data('dropdown');

    // Hide hints
    if ($hintsContainer.length > 0) {
      $hintsContainer
        .empty()
        .closest(searchForm)
        .removeClass(searchHasHintsClass);
    }

    if (!hasDropDown) {
      debug.log('Exiting early, this menu does not open up.');
      return;
    }
    if (isTouch) {
      overlayTouch(this);
      addModalOverlay();
      return false;
    }
    if (!$menu.hasClass('is-OpenMenu')) {
      $(this).addClass('is-OpenMenu');
      $(this).find('.o-Header__m-DropdownMenu').addClass('is-Hovered');
      if ($main.hasClass('is-Open')) {
        $('.flyout-animation').removeClass('flyout-animation');
        if (transition) {
          $main.one($.support.transition.end, function() {
            let $items = $menu.find('[data-animate-item]');
            if ($items.length>1) {
              transitionItems($items);
            } else {
              $items.addClass('flyout-animation');
            }
          }).emulateTransitionEnd(300);
        } else {
          $menu.find('[data-animate-item]').addClass('flyout-animation');
        }
      }
    }
    addModalOverlay();
  }

  function addModalOverlay() {
    let overlay = $(`.${modalClass}`);
    clearTimeout(modalTimeout);
    if (!overlay.length) {
      let div = $(`<div class="${modalClass}"></div>`);
      $(document.body).prepend(div);
      if (!headerTheme && headerTheme !== null) {
        let {top} = headerEl.getBoundingClientRect();
        $(`.${modalClass}`).css({'top': (top + headerEl.clientHeight), 'padding-bottom': $(document).height()});
      } else {
        $(`.${modalClass}`).css({'padding-bottom': $(document).height()});
      }
    }
  }

  function removeModal(){
    $(`.${modalClass}`).remove();
    $('.is-OpenMenu').removeClass('.is-OpenMenu');
  }

  function removeHoverOverlay(e, isTouch) {
    // remove all animated
    let $menu = $(this);
    let $dd = $(this).find('.o-Header__m-DropdownMenu');
    $('.flyout-animation').removeClass('flyout-animation');

    if (isTouch) {
      $dd.removeClass('is-Hovered');
      $menu.removeClass('is-OpenMenu');
      removeOverlayTouch(this);
      removeModal();
      return false;
    }

    $dd.fadeOut({
      duration: 250,
      complete: function(){
        $dd.removeClass('is-Hovered');
        $menu.removeClass('is-OpenMenu');
      }
    });

    modalTimeout = setTimeout(() => {
      removeModal();
    });
  }

  function isExpanded() {
    debug.log('isExpanded(): ', $(this).hasClass('is-Expanded'));
    return $(this).hasClass('is-Expanded');
  }

  const openTouchMenu = el => {
    debug.log('openTouchMenu(), el: ', el);
    closeMenu.call(lastElement);
    openMenu.call(el);
    removeHoverOverlay.call(lastElement, {}, true);
    hoverOverlay.call(el, {}, true);
    let $outsideEl = configGlobalIntent ? $main : $(el);
    utils.bindClickOutside($outsideEl, 'hover-intent.clickOutside',
      function() {
        lastElement = null;
        closeMenu.call(el);
        removeHoverOverlay.call(el, {}, true);
        utils.unbindClickOutside('hover-intent.clickOutside');
      });
    lastElement = el;
  };

  function closeMenu() {
    debug.log('closeMenu()\n$(this): ', $(this));
    $(this).removeClass('is-Expanded');
    $main.removeClass('is-Open');
    debug.log('openMenu(): $main.hasClass(is-Open): ', $main.hasClass('is-Open'));
  }

  function outHeader() {
    debug.log('outHeader()');
    $main.removeClass('is-Open');
    $(document.body).removeClass(overlayClass);
    context.broadcast('outHeader');
  }

  function adjustOffScreenDropdowns(selector, container, reverse) {
    let hoverElements = container.find(selector);
    hoverElements.each((index, element) => {
      let dropdownEl = $(element).find('[data-type="dropdown-menu"]');
      if (!dropdownEl || !dropdownEl.length) {
        return;
      }

      // make dropdown element rendered on the page in hidden mode
      // to get proper width and make calculations
      dropdownEl.css({visibility: 'hidden', display: 'block'});

      let flyouts = dropdownEl.find('.is-Flyout');

      let documentWidth = $('body').outerWidth(true);
      let dropdownEnd = $(element).offset().left + dropdownEl.outerWidth();

      // if dropdown ends off the screen just make dropdown and its flyouts
      // reversed, so it will be displayed from right to left
      if (documentWidth <= dropdownEnd) {
        if (reverse.dropdown) {
          $(element).addClass('is-Reversed');
        }
        if (reverse.flyout) {
          dropdownEl.addClass('is-ReversedFlyout');
        }

      // otherwise, remove reversed state from dropdown and calculate
      // whether flyouts should be displayed in normal mode or from right to left
      } else {
        $(element).removeClass('is-Reversed');

        if (reverse.flyout) {
          // check each dropdown item with flyout
          let isFlyoutReversed = false;
          flyouts.each((index, element) => {
            if (isFlyoutReversed) {
              return;
            }

            let flyoutEl = $(element);
            flyoutEl.css({visibility: 'hidden', display: 'block'});

            let flyoutEnd = flyoutEl.outerWidth();
            flyoutEl.css({visibility: '', display: ''});

            isFlyoutReversed = (documentWidth <= dropdownEnd + flyoutEnd);
          });

          // All flyouts will be reversed if any flyout ends off screen
          if (isFlyoutReversed) {
            dropdownEl.addClass('is-ReversedFlyout');

          // otherwise remove possibly added classes from previous resize
          } else {
            dropdownEl.removeClass('is-ReversedFlyout');
          }
        }
      }

      // return dropdown to hidden state after all calculations performed
      dropdownEl.css({visibility: '', display: ''});
    });
  }

  function approvedKeyDown(event){
    if (event.type === 'keydown'){
      let code = event.keyCode;
      //space bar and enter
      if ((code === 32)|| (code === 13)){
        return true;
      }
    } else {
      return false;
    }
  }

  function showFlyout(event, parent){
    let $dd = parent.find('.o-Header__m-DropdownMenu');
    $dd.css('display', 'block');
  }

  function openKeyPressFlyout(event) {
    if (!event.target) return;
    if (approvedKeyDown(event) && $navFlyout.find(event.target).length > 0){
      let eTarget = $(event.target);
      let $dropdown = $('.o-Header__m-DropdownMenu');
      let parent = $(event.target).closest('.o-Header__m-NavItemWrap');
      let expAttr = event.target.getAttribute('aria-expanded');
      //Keydown events do not register as clicks, so we need to perform that here,
      //if the item is within the flyout menu (not a navigation item)
      if ($dropdown.find(eTarget).length>0){
        document.activeElement.click();
        return;
      }

      if (expAttr !== null && expAttr !== undefined && expAttr === 'false'){
        showFlyout(event, parent);
        hoverHeader(event);
        parent.on('keyup', hoverOverlay);
        parent.on( 'focusout', () => {
          setTimeout(function() {
            if (!parent[0].contains(document.activeElement)){
              clearFlyOut(event, parent, eTarget);
              parent.off('focusout');
              parent.off('keyup');
            }
          }, 100);
        });
        eTarget.attr('aria-expanded', 'true');
      } else if (expAttr !== null && expAttr !== undefined && expAttr === 'true'){
        parent.off('focusout');
        parent.off('keyup');
        clearFlyOut(event, parent, eTarget);
      }
      event.preventDefault();
      $('.flyout-animation').removeClass('flyout-animation');
    }
  }

  function clearFlyOut(e, p, t){
    outHeader(e);
    removeHoverOverlay.call(p, {}, false);
    t.attr('aria-expanded', 'false');
  }

  const navigateAway = (el, event) => {
    // always close menu; handles new "Recipe Box" drop-down where menu-action is not a live link
    closeMenu.call($(el));
    removeHoverOverlay.call($(el, {}, true));
    let isLink = (event && event.target && event.target.tagName.toLowerCase() === 'a');
    debug.log('navigateAway(): isLink: ', isLink, '\nevent: ', event);
    if (isLink && event.target.href && event.type === 'touchstart') {
      debug.log('navigateAway(): event target is a link & event type is touchstart, navigating to the event target\'s href: ', event.target.href);
      window.location = event.target.href;
    }
  };

  return {
    init() {
      debug.log('init()');

      $main.on('keydown', function(event){
        openKeyPressFlyout(event);
      });

      if (configGlobalIntent) {
        // This application of hover intent doesn't filter by selector
        let timeout = configGlobalIntent.timeout;
        debug.log('init(): $main: ', $main);
        $main.hoverIntent({
          over: hoverHeader,
          out: outHeader,
          timeout
        });
        $(flyoutSelectors)
          .on('mouseenter', hoverOverlay)
          .on('mouseleave', removeHoverOverlay);
      } else {
        config.forEach(({ timeout, dataType, reverse }) => {
          let selector = `[data-type=${dataType}]`;
          $main.hoverIntent({
            over: openMenu,
            out: closeMenu,
            timeout,
            selector: selector
          });

          if (reverse.dropdown || reverse.flyout) {
            adjustOffScreenDropdowns(selector, $main, reverse);
            $(window).on('resize', () => { adjustOffScreenDropdowns(selector, $main, reverse); });
          }
        });
      }
    },
    ontouchstart(event, element, elementType) {
      debug.log('ontouchstart()\nevent: ', event, '\nelement: ', element, '\nelementType: ', elementType, '\n$(element).children(\'[data-type="dropdown-menu"]\').length: ', $(element).children('[data-type="dropdown-menu"]').length);
      if (hoverTypes.includes(elementType) && $(element).children('[data-type="dropdown-menu"]').length) {
        event.stopPropagation();
        event.preventDefault();

        let $menuAction = $(event.target).closest('li[data-type="menu-action"]');

        debug.log('ontouchstart(): $(event.target).closest(\'li[data-type="menu-action"]\'): ', $menuAction);

        if (isExpanded.call($menuAction)) {
          navigateAway(element, event);
        } else {
          openTouchMenu(element);
        }

      } else if ($(element).parent().siblings('[data-type="dropdown-menu"]').length) {
        element = $(element).closest('.o-Header__m-NavItem');
        event.stopPropagation();
        event.preventDefault();
        let $menuAction = $(element);
        debug.log('ontouchstart(): $(element): ', $menuAction);
        if (isExpanded.call($menuAction)) {
          navigateAway(element, event);
        } else {
          openTouchMenu(element);
        }
      } else if (elementType === 'search-input') {
        removeHoverOverlay.call(lastElement, {}, true);
      } else {
        debug.log('ontouchstart(): either elementType of "', elementType,'" is not be in hoverTypes "', hoverTypes, '", or there are no dropdown menu children of the element: ', element);
        if ($(event.target).hasClass('o-Header__a-NavLink') && $(event.target).attr('href').length) {
          debug.log('ontouchstart(): the event target looks like a nav link, so we are going to navigate to that link');
          window.location = event.target.href;
        }
      }
    },
  };

});
