SNI.Application.addService('ajax', function(application) {
  'use strict';

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let service;
  const debug = application.getService('logger').create('service.ajax');
  const check = application.getService('check').new(debug);
  const metadata = application.getService('metadata');
  const analytics = application.getService('analytics');
  const template = application.getService('template');

  function initializeModules(currentContext) {
    let $currentContext = $(currentContext);

    application.startAll($currentContext);
    application.broadcast('ajax.modulesInitialized', {
      context: currentContext
    });
  }

  function loadAreaContent(data) {
    let target = data.target || '',
        $target = $(document).find('[data-sni-area=' + target + ']'),
        href =  data.href || '',
        targetType = $target.attr('data-sni-area-type') || 'replace',
        suppressLoadAnim = data.suppressLoadAnim ? data.suppressLoadAnim : false,
        $savingOldContent = '',
        ajaxURL = '';

    if (!href || !target) {
      debug.warn('href and target not provided correctly, aborting...');
      return false;
    }

    if (!$target.length) {
      $target = $(template.targetArea(target)).appendTo('body');
    }

    $target.removeClass('loaded').addClass('loading');

    if (targetType === 'replace') {
      scrollTo(0,0);
      if (!suppressLoadAnim) {
        $target.html(template.loadingHTML());
      }
      $savingOldContent = $target;
    } else {
      if (!suppressLoadAnim) {
        $target.append(template.loadingHTML());
      }
    }

    application.broadcast('ajax.areaContentLoading', {
      target: target
    });

    ajaxURL = cleanURLForCQ(href, target);

    if (check.isLocalhost() || check.isAuthor()) {
      if (ajaxURL.indexOf('wcmmode=disabled') === -1) {
        ajaxURL += '?wcmmode=disabled';
      }
    }

    $.ajax({
      'cache': false,
      'url': ajaxURL,
      'complete': function(data) {
        areaContentLoaded({
          target: target,
          suppressLoadAnim: suppressLoadAnim,
          href: href,
          content: data.responseText
        });
      },
      'error': function() {
        if (targetType === 'replace') {
          areaContentError({
            target: target,
            href: href,
            content: $savingOldContent
          });
        }
      }
    });
  }

  function cleanURLForCQ(url, target) {
    let cleanURL,
        relativeCqBasePage;

    if (!check.exists('cqBasePage')) {
      debug.error('cqBasePage is not defined! this is no bueno!');
      return;
    }

    if (window.location.host.indexOf('.mobile.') > -1) {
      target += '.mobile';
    }

    url = url.replace(/^https?:\/\/[^\/]*/,'');
    relativeCqBasePage = application.getGlobal('cqBasePage').replace(/^https?:\/\/[^\/]*/,'');

    if (url.lastIndexOf(relativeCqBasePage) === -1) {
      if (url.indexOf('.html') > -1) {
        cleanURL = url.replace('.html','.' + target + '.html');
      } else {
        if (window.location.href.indexOf('.html') > -1) {
          cleanURL = url + '.' + target + '.html';
        } else {
          cleanURL = url + '.' + target;
        }
      }
    } else {
      if ($('body').hasClass('photoLibraryViewerPage')) {
        cleanURL = relativeCqBasePage;
      } else {
        if (relativeCqBasePage.indexOf('.html') > -1) {
          cleanURL = relativeCqBasePage.replace('.html','.' + target + '.html');
        } else {
          if (window.location.href.indexOf('.html') > -1) {
            cleanURL = relativeCqBasePage + '.' + target + '.html';
          } else {
            cleanURL = relativeCqBasePage + '.' + target;
          }
        }
      }
      cleanURL = url.replace(relativeCqBasePage,cleanURL);
    }

    return cleanURL;
  }

  function areaContentError(data) {
    application.broadcast('ajax.areaContentError', data);
    window.location = data.href;
    debug.warn('Error with the ajax on: ', data.target);
  }

  function areaContentLoaded(data) {
    if (data.content) {
      insertAreaContentIntoDOM(data);
      application.broadcast('ajax.areaContentLoaded', data);
    } else {
      debug.warn('Error. Ajax came through but no data for: ', data.target);
    }
  }

  function insertAreaContentIntoDOM(data) {
    let $target,
        content,
        $context,
        targetType;

    content = $('<div>').html(data.content);
    content = $(content).find('[data-sni-area=' + data.target + ']').html();
    $target = $(document).find('[data-sni-area=' + data.target + ']');
    targetType = $target.attr('data-sni-area-type') || 'replace';
    $target.removeClass('loading').addClass('loaded');

    if (!data.suppressLoadAnim) {
      $target.find('[data-ui-loader]').remove();
    }

    if (targetType === 'append') {
      $context = $target.append(content);
    } else {
      $context = $target.html(content);
      updateMetrics(data.content, data.target);
    }

    initializeModules($context);
  }

  function getShoppingMetadata(data) {
    let shoppingMetadata = {},
        firstSlideMetadata;

    if (data) {
      firstSlideMetadata = (typeof data === 'object' ? data : JSON.parse(data));

      shoppingMetadata = {
        shopping: firstSlideMetadata.shopping,
        products: firstSlideMetadata.products,
        partner: firstSlideMetadata.partner
      };
    }
    return shoppingMetadata;
  }

  function updateMetrics(content, target) {
    let $sanitizedContent = $('<div>').html(content),
        mdmData = $($sanitizedContent).find('[data-sni-area=' + target + ']').data('mdm') || false,
        mdmObj = JSON.parse(mdmData),
        $firstSlide = $($sanitizedContent).find('.pv-slide').first(),
        isShopThisLook = $firstSlide.data('productEnabled'),
        partnerTag = 'partner;wayfair/shop_this_look',
        taggroup,
        behavioralInteraction,
        updatedUID,
        shoppingMetadata;

    mdmData = JSON.parse(mdmData.trim());

    if (isShopThisLook) {
      taggroup = (mdmData.TagGroup1) ? mdmData.TagGroup1 : mdmData.Overlay_TagGroup1;

      if (typeof taggroup !== 'undefined' && taggroup.indexOf(partnerTag) === -1) {
        taggroup =  partnerTag + ',' + taggroup;
        mdmData.TagGroup1 = taggroup;
        mdmData.Overlay_TagGroup1 = '';
      } else {
        debug.warn('updateMetrics: STL might not be tracked, taggroup is undefined or index of partnerTag is not -1, here is the MDM object: ', mdmData);
      }
    }

    updatedUID = metadata.updateConsecutiveViewCount(mdmObj);
    behavioralInteraction = metadata.getMediaStreamNumber(mdmData, 1, 1);

    if (mdmData) {
      shoppingMetadata = getShoppingMetadata($firstSlide.data('mdm'));
      mdmData = Object.assign({}, mdmData, shoppingMetadata);
      metadata.updateFromString(mdmData);
      if (updatedUID) {
        metadata.updateFromString({Overlay_UniqueId: updatedUID});
      }
      if (behavioralInteraction) {
        metadata.updateFromString({behavioralInteraction: behavioralInteraction});
      }
    } else {
      debug.error('updateMetrics: Hijax MDM data missing');
    }

    if ($('body').hasClass('photoGalleryPage')) {
      let ogDesc = $($sanitizedContent).find('[data-sni-area=' + target + ']').data('ogdesc') || false,
          ogTitle = $($sanitizedContent).find('[data-sni-area=' + target + ']').data('ogtitle') || false,
          ogUrl = $($sanitizedContent).find('[data-sni-area=' + target + ']').data('ogurl') || false,
          ogImg = $($sanitizedContent).find('[data-sni-area=' + target + ']').data('ogimg') || false;

      if (ogDesc) {
        $('meta[property="og:description"]').attr('content', ogDesc);
      }
      if (ogTitle) {
        $('meta[property="og:title"]').attr('content', ogTitle);
      }
      if (ogUrl) {
        $('meta[property="og:url"]').attr('content', ogUrl);
      }
      if (ogImg) {
        $('meta[property="og:image"]').attr('content', ogImg);
      }
    }

    analytics.callDynamicPageview();
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  service = {
    initializeModules,
    loadAreaContent,
    cleanURLForCQ,
    areaContentError,
    areaContentLoaded,
    insertAreaContentIntoDOM,
    getShoppingMetadata,
    updateMetrics
  };

  return service;
});
