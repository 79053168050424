SNI.Application.addService('device-type', (application) => {
  let debug = application.getService('logger').create('service.device-type');

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  let isMobile = !!application.getGlobal('cqMobile');
  let isTablet = (/ipad|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));
  let isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent)) && !application.getGlobal('MSStream');

  // Let's only use these guys when it's absolutely unavoidable! This is a code smell!
  let isChrome = /Chrome/.test(navigator.userAgent);
  let isSafari = /Safari/.test(navigator.userAgent);
  let isIE = /Trident\//.test(navigator.userAgent);
  let isEdge = /Edge/.test(navigator.userAgent);

  debug.log(`isMobile: ${isMobile}, isTablet: ${isTablet}`);

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {
    isIE,
    isEdge,
    isChrome,
    isSafari,
    isTablet,
    isMobile,
    isIOS
  };
});
