import { SEARCH_FORM_WRAPPER_SELECTOR } from '../../modules/search-form';

export const HAS_HINTS_CLASS = 'has-Hints';
export const TYPEAHEAD_HINTS_CONTAINER_SELECTOR = '[data-typeahead-hints]';

export const hideHints = (
  $element,
  debug,
  searchFormWrapper = SEARCH_FORM_WRAPPER_SELECTOR,
  hasHints = HAS_HINTS_CLASS
) => {
  let $hintsContainer = $element.find(TYPEAHEAD_HINTS_CONTAINER_SELECTOR);
  // debug.log('hideHints(): $hintsContainer: ', $hintsContainer);

  if ($hintsContainer.length > 0) {
    $hintsContainer
      .empty()
      .closest(searchFormWrapper)
      .removeClass(hasHints);
  }
};

export const showHints = (
  $element,
  hints,
  debug,
  searchFormWrapper = SEARCH_FORM_WRAPPER_SELECTOR,
  hasHints = HAS_HINTS_CLASS
) => {
  let $hintsContainer = $element.find(TYPEAHEAD_HINTS_CONTAINER_SELECTOR);
  // debug.log('showHints(): $hintsContainer: ', $hintsContainer);
  // debug.log('showHints(): hints: ', hints);

  if (typeof hints !== 'undefined' && hints !== '') {
    // debug.log('showHints(): hints isn\'t blank, adding hasHints class to $hintsContainer');
    $hintsContainer.closest(searchFormWrapper).addClass(hasHints);
  }

  $hintsContainer.html(hints);
};
