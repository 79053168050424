/**
 *
 * This behavior converts any data-daferred-module attributes to data-module as it's initialized,
 * thereby providing the ability to defer starting a module until a later point.
 *
 **/
SNI.Application.addBehavior('deferred-loader', context => {

  const deferAttr = 'data-deferred-module';
  const debug = context.getService('logger').create('behaviors.deferred-loader');

  let element  = context.getElement();
  let $element = $(element);

  return {

    init() {

      $element.find(`[${deferAttr}]`).each((index, el) => {
        let $el = $(el);

        debug.log(
          $el
            .attr('data-module', $el.attr(`${deferAttr}`))
            .removeAttr(`${deferAttr}`)
        );

      });

    }

  };

});
