SNI.Application.addBehavior('router', function(context) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let routingService = context.getService('router');

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {

    messages: ['stateChanged', 'previousUrlChanged'],

    onmessage: function(name, data) {
      switch (name) {
        case 'stateChanged':
          routingService.handleStateChange(data);
          break;
        case 'previousUrlChanged':
          routingService.setPreviousUrl(data.previousURL);
          break;
      }
    },

    onclick: function(event) {

      if (event.target.tagName.toLowerCase() === 'a') {
        routingService.routeClickHandling(event);
      }

    }

  };

});
