import './utility';

SNI.Application.addService('template', (application) => {

  let loadingHTML = $('[data-ui-loader-clone]:first').html() || '';
  let modalClose = '<button data-dismiss="modal" class="o-Modal__a-Button--Close m-ButtonWrap" data-type="modal-close" style="border:none"><span>Cancel</span></button>';
  const xCloseSVG = `<svg class="a-Icon a-Icon--close o-HeaderFresh__a-Icon o-HeaderFresh__a-Icon--close" role="img" data-type="button-close" style="display: block;"><symbol id="icon-close" viewBox="0 0 32 32">
        <rect height="5" rx="1.5" transform="translate(14.000000, 14.500000) rotate(-45.000000) translate(-14.000000, -14.500000) " width="34" x="-3" y="12"></rect>
        <rect height="5" rx="1.5" transform="translate(14.000000, 14.500000) rotate(-135.000000) translate(-14.000000, -14.500000) " width="34" x="-3" y="12"></rect></symbol><use xlink:href="#icon-close"></use></svg>`;
  const utils = application.getService('utility');

  return {

    modal: (obj) => {
      return `<div class="o-Modal__m-Dialog" id="${obj.id}" data-modal-dialog>
        <div class="o-Modal__m-Content">
          ${modalClose}

          <div class="o-Modal__m-Header" data-modal-header>
            <div class="o-Modal__a-Headline" data-modal-headline>${obj.heading}</div>
            ${obj.subHeading ?
    `<div class="o-Modal__a-Description">${obj.subHeading}</div>`
    : ''}
          </div>

          <form>
            <div class="o-Modal__m-Body" data-modal-body>${obj.body}</div>
            <div class="o-Modal__m-Footer" data-modal-footer ${!obj.cancelText && !obj.actionText ? 'style="display: none;"' : ''}>
              <button class="o-Modal__a-Button--Primary" data-button-primary type="button">${obj.actionText}</button>
              ${obj.cancelText ? `
                <span>or</span>
                <button data-dismiss="${obj.id}" class="o-Modal__a-Button--Secondary" data-button-secondary type="button">${obj.cancelText}</button>`
    : ''}
            </div>
          </form>
        </div>
      </div>`;
    },

    modalClose: () => {
      return modalClose;
    },

    modalWrap: (id) => {
      return '<div id="' + id + '" class="o-Modal"></div>';
    },

    modalBackdrop: (animate, backdropModifier='') => {
      if (backdropModifier===''){
        return `<div class="o-Modal__m-Backdrop ${animate}" data-modal-backdrop />`;
      }
      return  `<div class="o-Modal__m-Backdrop o-Modal__m-Backdrop--${backdropModifier} ${animate}" data-modal-backdrop />`;
    },

    socialPinterest: (data) => {
      let href = `https://www.pinterest.com/pin/create/button/?url=${data.url}&media=${data.media}&description=${data.description}`;
      let {img, useNewLogo} = data;

      let pinitSvgId = utils.getSvgIconSymbolId('pinterest');

      let newLogo = `<svg class="a-Icon a-Icon--pinterest o-PhotoGalleryPromo__a-Icon o-PhotoGalleryPromo__a-Icon--pinterest rsNoDrag" role="img" aria-label="Pinterest Logo">
                      <use xlink:href="#${pinitSvgId}"></use>
                    </svg>`;
      let oldLogo = `<img src="${img}" class="rsNoDrag" alt="Pinterest Logo"/>`;
      let dataPinRound = data.pinRound ? 'data-pin-round="true"' : '';

      let setPinPosition = () => {
        if (!data.position || data.position.split(' ').length !== 2) data.position = 'top right';

        let pinPosition = ''; // For 'top right' --> "top: 1rem; right: 1rem; bottom: auto; left: auto;"

        ['top', 'bottom', 'left', 'right'].forEach((val) => {
          data.position.toLowerCase().indexOf(val) !== -1 ? pinPosition += val+':1rem;' : pinPosition += val+':auto;';
        });

        return pinPosition;
      };
      return `<div class="a-Pinit rsNoDrag" data-type="pin-element" data-pinit-button style="${setPinPosition()}">
                <a class="rsNoDrag" data-pin-custom="true" data-pin-do="buttonPin" data-pin-media="${data.media}"
                data-pin-url="${data.url}" href="${href}" data-pin-log="button_pinit" ${dataPinRound}
                title="Save this image on Pinterest"
                   tabindex="0" role="button" aria-label="Save this image on Pinterest" data-type="pin-btn">
                  ${useNewLogo?newLogo:oldLogo}
                </a>
              </div>`;

    },

    rsSlide: (data) => {
      let {imgPath, imgMarkup} = data;
      return `<div class="o-PhotoGalleryPromo__m-Slide m-Slide slide">
        <div class="m-ResponsiveImageWrapper" data-og-url="${imgPath}">
          <div class="m-ResponsiveImage--3x2 m-ResponsiveImage">
            <div class="share-frame">
                ${imgMarkup}
            </div>
          </div>
        </div>
      </div>`;
    },

    rsImage: (slide) => {
      return `<img class="rsImg" src="${slide.image}" alt="${slide.title}" />`;
    },

    // Royal Slider lazy image: accepts a link & outputs an img; text supposed to become alt
    rsLazyImage: (slide) => {
      return `<a class="rsImg" href="${slide.image}" >${slide.title}</a>`;
    },

    slideCount: () => {
      return '<div class="rsSlideCount"><span class="rsCurr"></span> / <span class="rsLength"></span></div>';
    },

    overlayBigbox: () => {
      return '<div id="overlay_bigbox"></div>';
    },

    adText: () => {
      return '<p class="ad-text">Advertisement</p>';
    },

    adWrapper: (slot) => {
      let innerSlot = slot || '';
      return `<div class="ad-wrapper"><div class="bigbox-ad">${innerSlot}</div></div>`;
    },

    adWrapperLite: () => {
      return '<div class="bigbox-ad"></div>';
    },

    desktopRiverAd: (id, topMargin) => {
      return `<div id="dfp_bigbox_${id}" class="rr-ad bigbox-ad module text-center" style="margin-top: ${topMargin}px"></div>`;
    },

    riverAd: (id) => {
      return `<div id="mobile_rr_bigbox_${id}" class="rr-ad bigbox-ad module text-center"></div>`;
    },

    interstitialWrapper: () => {
      return '<div id="photo_interstitial_wrapper"></div>';
    },

    interstitialWrapperMobile: () => {
      return '<div id="photo_interstitial_wrapper_mobile" class="interstitial"></div>';
    },

    interstitialMobileCloseBtn: () => {
      return '<div id="photo_interstitial_mobile_close" data-type="close-mobile-interstitial"><i class="icon icon-times"></i></div>';
    },

    nextUpLabel: () => {
      return '<span class="label label-caution next-label">Next Up</span>';
    },

    targetArea: (target) => {
      return `<div class="area" data-sni-area=${target}></div>`;
    },

    productIndicator: (sel) => {
      return `<span class="${sel} icon icon-angle-up"></span>`;
    },

    collapsibleFooter: (site) => {
      return `<div class="back-to-top-desktop smooth-hide">
        <a href="#" class="collapsible-footer">MORE FROM ${site}</a>
        <a href="#" class="icon icon-angle-up back-to-top-btn">TOP</a>
      </div>`;
    },

    loadingHTML: (variant = false) => {
      return variant ? loadingHTML.replace(/(class\=)(\"|\')/, `$1$2${variant} `) : loadingHTML;
    },

    shopButton: (productId) => {
      return `<div class="a-ShopButton rsNoDrag">
        <button type="button" class="btn btn-primary" data-type="open-shop-overlay-btn" data-product-id="${productId}">Shop This Look</button>
      </div>`;
    },

    preloaderHTML: () => {
      return `<div class="rsPreloader">${loadingHTML}</div>`;
    },

    clonedCredit: () => {
      return '<div class="cloned-credit" data-type="launch-credit"></div>';
    },

    launchMeFiller: (numSlides) => {
      return `<span class="a-CtaTextSecondary">${numSlides} Photos</span>`;
    },

    photoCredit(data, simple) {
      let creditClass = simple ? '': 'photo-caption';
      if (data.credit) {
        return `<p class="${creditClass}">Photo By: ${data.credit}</p>`;
      }
      return '';
    },

    photoCopyright(data) {
      if (data.copyright) {
        return `<p>&copy;${data.copyright}</p>`;
      }
    },

    photoCopyandCredit(data) {
      let { credit, copy} = data;
      let creditCopy = `<div class="o-ImageEmbed__a-Credit photo-caption" >${credit||''}${copy||''}</div>`;
      return creditCopy;
    },

    galleryDescription: (item) => {
      return `<div class="${item.parentClass}__a-Description">${item.description}</div>`;
    },
    attributionMedia(item) {
      return `<div class="o-Attribution__m-MediaWrap">
                  <a href="${item.profile_url||''}">
                    <img width="91" alt="" title="" src="${item.avatar||''}" height="91" class="o-Attribution__a-Image">
                  </a>
              </div>`;
    },
    attributionName(item) {
      let title = item.profile_url ? `<a href="${item.profile_url}">${item.profile_name}</a>` : item.profile_name;
      let primaryClass = item.level === 1 ? 'a-Name--Primary' : '';
      return `<span class="o-Attribution__a-Name ${primaryClass}">${title}</span>`;
    },
    attributionLabel(item) {
      let {name, level} = item;
      let wrapper = (level === 1 || level === 2) ? `<span class="o-Attribution__a-Source--Prefix">${level === 2?' and ':''}` :', ';
      let wrapperClose = (level === 1 || level === 2) ? '</span>' : '';
      return `${wrapper}${name}${wrapperClose}`;
    },
    attributionText(labels) {
      return `<div class="o-Attribution__m-TextWrap">
                  <div class="o-Attribution__a-Source">
                      <span class="o-Attribution__a-Source--Label">From: </span>
                      ${labels}
                  </div>
              </div>`;
    },
    galleryAttribution(media, attr) {
      return `<div class="o-Attribution o-AttributionEmbedded">
                <div class="o-Attribution__m-Body">
                  ${media}
                  ${attr}
                </div>
              </div>`;
    },
    nextGalleryModal: (data) => {
      return `
        <div class="m-NextAsset" data-type="open-next-gallery">
          <div class="m-NextAsset__a-SubHeadline">${data.subHeadline}</div>
          <h4 class="m-NextAsset__a-Headline">
            <a href="${data.link}" data-next-gallery-link>
              <span class="m-NextAsset__a-HeadlineText">
                ${data.headline}
              </span>
            </a>
          </h4>
          ${data.voting ? `
          <div class="m-NextAsset__a-Cta">
            <a href="#" data-vote-ready-btn>
              I'm Ready to Vote.
            </a>
          </div>
          ` : ''}
        </div>`;
    },

    voteEndframe: function(title, message) {
      return `<div class="voteEndFrame"><div class="message"><p>Now That You've Seen</p><h2>${title}</h2><button class="btn btn-primary nextCat">${message}</button></div></div>`;
    },

    voteResultModalBody: function(data) {
      return `
      <div class="${data.mobile ? 'l-Columns l-Columns--1up' : 'l-List'}">
        <div class="o-Capsule__m-MediaBlock m-MediaBlock">
          <div class="m-MediaBlock__m-MediaWrap">
            <img alt="${data.title}" src="${data.image}" class="m-MediaBlock__a-Image" />
          </div>
          <div class="m-MediaBlock__m-TextWrap">
            <h6 class="m-MediaBlock__a-Headline">You Chose</h6>
            <p class="m-MediaBlock__a-Description">${data.title}</p>
            <h6>Share with Friends</h6>
            <div vote-share-container="true"></div>
          </div>
        </div>
      </div>
      `;
    },

    voteWinnerTitle: () => {
      return '<span class="winner-title">YOUR VOTE</span>';
    },

    trackableLink: (data) => {
      let {componentTitle, currentTitle} = data;
      return `<a href="#" title="${currentTitle}" data-analytics-comp='{"componentTitle": "${componentTitle}"}'>${currentTitle}</a>`;
    },

    voteResultGalleryItemTextWrap: (data) => {
      let yourVote = data.isWinner;
      return `
      <div class="m-MediaBlock__m-TextWrap">
        <h4 class="m-MediaBlock__a-Headline">
          ${yourVote}
          <span class="gallery-vote-percent is-Hidden"></span>
          <a data-analytics-comp='{"componentTitle": "${data.componentTitle}"}'>
            <span class="gallery-title">${data.title}</span>
          </a>
        </h4>
      </div>
      `;
    },

    // 'Cards' in 'VOTE' vertical photo gallery (mobile)
    renderVerticalGallery: function(i, data) {
      let { image, description, title, attributionAllowed, galleryIndex, renderIndex } = data,
          photoCredit = data.credit||'',
          photoCopyright = data.copyright ? ('&copy;' + data.copyright):'';

      return `<div class="o-PhotoGalleryPromo__m-Slide m-Slide photocard" data-card-index="${i}" data-gallery-index="${galleryIndex}" data-render-index="${renderIndex}" data-card data-asset-url="${image}" data-asset-title="${title}">
                <div class="o-PhotoGalleryPromo__m-MediaBlock">
                  <div class="m-MediaBlock__m-MediaWrap" data-pin-element >
                    <img class="m-MediaBlock__a-Image fade-out" alt="" data-src="${image}"/>
                  </div>
                  <div class="m-MediaBlock__m-TextWrap" data-text>
                    <section class="o-PhotoGalleryPromo__m-AssetData m-AssetData asset-info">
                      <h3 class="o-PhotoGalleryPromo__a-Headline">
                        <span class="o-PhotoGalleryPromo__a-HeadlineText">${title||''}</span>
                      </h3>
                      <div class="o-PhotoGalleryPromo__a-Description asset-description" data-truncate="75" >${description}</div>
                      <p class="o-PhotoGalleryPromo__a-Credit" >
                        ${photoCredit}
                        ${photoCopyright}
                      </p>
                      ${attributionAllowed ? '<div data-attributions=""></div>' : ''}
                    </section>
                  </div>
                </div>
              </div>`;
    },

    cta: (headline) => {
      return `<div class="m-Cta" data-type="close-gallery-cta" role="button"><div class="m-Cta__a-Headline" role="presentation">${headline}</div></div>`;
    },

    launchMeContent: () => {
      return '<div class="o-PhotoGalleryPromo__m-VisualCta" data-type="close-gallery-cta" role="button"><div class="m-VisualCtaOuterWrap" role="presentation"><div class="m-VisualCtaTextWrap" role="presentation"><span class="a-CtaTextPrimary" role="presentation">Open Gallery</span></div></div></div>';
    },

    shopItemDesktop: (item) => {
      let {image_url, sale_price, formatted_price, formatted_sale_price, product_url, name, has_free_shipping} = item;
      let price = sale_price ? formatted_sale_price : formatted_price;
      const escapedName = utils.escapeHtml(name);

      return `<div class="m-ProductsList__m-ProductSmall m-ProductSmall" data-product-title="${escapedName}">
                <a class="m-ProductSmall__m-Link" rel="nofollow noskim" href="${product_url}?refid=HGTV12&amp;ss_hgtv_product=1" target="_blank" title="${escapedName}" data-type="stl-product-link">
                    <div class="m-ProductSmall__m-MediaWrap">
                        <div class="m-ProductSmall__m-ImageWrap"><img data-sizes="auto" src="${image_url}"  alt="${escapedName}" sizes="70px"></div>
                    </div>
                    <div class="m-ProductSmall__m-TextWrap">
                        <h4 class="m-ProductSmall__a-Headline"><span class="m-ProductSmall__a-HeadlineText">${escapedName}</span></h4>
                        <div class="m-ProductSmall__a-Price">${price}</div>
                        <div class="m-ProductSmall__a-ShippingInfo">${has_free_shipping?'Free Delivery':''}</div>
                    </div>
                </a>
            </div>`;
    },

    shopOverlay: (isMobile, productId) => {
      let closeSelector = 'close-shop-overlay-btn',
          iframeSource = '//wayfair.com/v/inspiration_gallery/hgtv_widget?hgtvPhotoID='+productId,
          productDimenstions = isMobile ? 'width="300" height="400"' : '',
          productOffsetStart = isMobile ? '' : '<div class="products-offset">',
          productOffsetEnd = isMobile ? '' : '</div>',
          desktopProductsClose = isMobile ? '' : `<button class="btn btn-stroke btn-inverse is-ShopThisLook" data-type="${closeSelector}" data-shop-this-look-close>Back to Photo</button>`,
          mobileProductsClose = isMobile ? `<a class="o-Modal__a-Button--Close m-ButtonWrap is-ShopThisLook" data-type="${closeSelector}" data-shop-this-look-close><span>Cancel</span></a>` : '',
          smallText = isMobile ? '' : '<small>powered by:</small>',
          wayfairHref = '//wayfair.com/?refid=HGTV12';

      return `<div class="shop-overlay overlay modal">
        <div class="products-header">
          ${productOffsetStart}
            ${desktopProductsClose}
            <h3 class="products-overlay-heading">Shop This Look</h3>
            <div class="brand">${smallText}<a target="_blank" href="${wayfairHref}">Wayfair.com</a></div>
            ${mobileProductsClose}
          ${productOffsetEnd}
        </div>
        <div class="products">
          <div class="products-offset">
            <iframe src="${iframeSource}" ${productDimenstions} allowfullscreen="" frameborder="0" allowtransparency="true"></iframe>
          </div>
        </div>
      </div>`;
    },

    shopOverlayMobile: () => {
      return `
        <div class="shop-overlay overlay o-Modal">
          <div class="products-header">
            <h3 class="products-overlay-heading">Shop This Look</h3>
            <div class="brand"><a target="_blank" href="https://www.wayfair.com">Wayfair.com</a></div>
            <a class="o-Modal__a-Button--Close m-ButtonWrap"><span>Cancel</span></a>
          </div>
          <div class="products">
            <div class="products-offset">
              <iframe src="" width="300" height="400" allowfullscreen="" frameborder="0" allowtransparency="true"></iframe>
            </div>
          </div>
        </div>`;
    },

    shopOverlayDesktop: () => {
      return `
        <div class="shop-overlay overlay o-Modal">
          <div class="products-header">
            <div class="products-offset">
              <button class="btn btn-stroke btn-inverse">Back to Photo</button>
              <h3 class="products-overlay-heading">Shop This Look</h3>
              <div class="brand"><small>powered by:</small> <a target="_blank" href="//wayfair.com/?refid=HGTV12">Wayfair.com</a></div>
            </div>
          </div>
          <div class="products">
            <div class="products-offset">
              <iframe src="" allowfullscreen="" frameborder="0" allowtransparency="true"></iframe>
            </div>
          </div>
        </div>
      `;
    },

    hotspotToggle: (data) => {

      let setHotspotTogglePosition = () => {
        if (!data.position || data.position.split(' ').length !== 2) data.position = 'top right';
        let hotspotTogglePosition = ''; // "top: 1rem; right: 1rem; bottom: initial; left: initial;"

        ['top', 'bottom', 'left', 'right'].forEach((val) => {
          data.position.toLowerCase().indexOf(val) !== -1 ? hotspotTogglePosition += val+':1rem;' : hotspotTogglePosition += val+':initial;';
        });

        return hotspotTogglePosition;
      };

      return `<div class="galleryHotspotToggle btn" style="${setHotspotTogglePosition()}" data-type="toggle-hotspot"><a href="#">Hide</a><span class="icon icon-circle"> </span></div>`;
    },

    hotspotProduct: () => {
      return `<div class="hotspotProductBackdrop" data-type="close-product-modal"></div>
              <div class="hotspotProduct" data-type="view-product-modal">
                <div class="hotspotProductContent">
                  <div class="hotspotProductOverlayClose">
                    <a href="#" class="icon icon-times"><span>Close</span></a>
                  </div>
                  <div class="hotspotProductImage"><img src=""/></div>
                  <h2 class="hotspotProductTitle"></h2>
                  <div class="hotspotProductSponsor"></div>
                  <div class="hotspotProductDescription"></div>
                  <div class="hotspotProductMore"><button class="btn btn-primary">More Info</button></div>
                  <div class="hotspotProductAll"><a href="#">See all products in this gallery</a></div>
                </div>
              </div>`;
    },

    hotspotProductsAll: () => {
      return `<div class="hotspotProductsAllBackdrop" data-type="close-product-modal-all"></div>
              <div class="hotspotProductsAll" data-type="view-product-modal-all">
                <div class="hotspotProductsAllContent">
                  <div class="hotspotProductsAllTopBar">
                    <div class="hotspotProductsAllBack"><button class="btn btn-stroke btn-inverse">Back to Photo</button></div>
                    <div class="hotspotProductOverlayClose">
                      <a href="#" class="icon icon-times"><span>Close</span></a>
                    </div>
                    <h2 class="hotspotProductsAllTitle">Products In This Gallery</h2>
                  </div>
                  <div class="hotspotProductsAllProducts">
                    <div class="block-list-inline block-list-md-col4 block-list-xs-col1 list-pad-space-double list-pad-btm-space-double">
                      <ul>
                        <li class="block">
                          <div class="hotspotProductsAllProduct">
                            <div class="hotspotProductImage"><img src=""/></div>
                            <div class="hotspotProductTitleDescription">
                              <h3 class="hotspotProductTitle"><a href="#">Title</a></h3>
                              <div class="hotspotProductDescription">Description</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>`;

    },

    tourOverlay: (data) => {
      return `
        <div class="virtual-tour-wrapper">
          <div id="virtual-tour-embed-container"></div>
            <div id="virtual-tour-cta"><p>${data.roomName}</p></div>
          </div>
          <div class="tour-ad-wrapper">
            <div class="bigbox-ad"><div id="dfp_overlay_bigbox"></div>
          </div>
        </div>`;
    },

    productHotSpot: (data) => {
      function checkProductImg() {
        var productImgString;
        if (data.product.sponsor_url && data.product.product_img) {
          productImgString = '<a href="' + data.product.sponsor_url + '" target="_blank">' +
            '<img src="' + data.product.product_img + '.rend.hgtvcom.266.200.jpeg"/>' +
          '</a>';
        } else if (data.product.product_img) {
          productImgString = '<img src="' + data.product.product_img + '.rend.hgtvcom.266.200.jpeg"/>';
        } else {
          productImgString = '';
        }

        return productImgString;
      }

      function checkTitle() {
        var titleString;
        if (data.product.title) {
          titleString = '<h4 id="product-title">' + data.product.title.replace(/&amp;quot;/ig, '"') + '</h4>';
        } else {
          titleString = '';
        }
        return titleString;
      }

      function checkLogoImg() {
        var logoString;
        if (data.product.sponsor_url && data.product.logo_img) {
          logoString = '<a href="' + data.product.sponsor_url + '" target="_blank">' +
            '<img src="' + data.product.logo_img + '"/>' +
          '</a>';
        } else if (data.product.logo_img) {
          logoString = '<img src="' + data.product.logo_img + '"/>';
        } else {
          logoString = '';
        }
        return logoString;
      }

      function checkDesc() {
        var descString;
        if (data.product.descr) {
          if (checkProductImg() === '' || checkLogoImg() === '') {
            descString = '<h5 id="product-desc" class="no-img">' + data.product.descr.replace(/&amp;quot;/ig, '"') + '</h5>';
          } else {
            descString = '<h5 id="product-desc">' + data.product.descr.replace(/&amp;quot;/ig, '\"') + '</h5>';
          }
        } else {
          descString = '';
        }
        return descString;
      }

      function checkSponsor() {
        var sponsorString;
        if (data.product.sponsor_url) {
          if (checkProductImg() === '') {
            sponsorString = '<a href="' + data.product.sponsor_url + '" target="_blank">' +
              '<button type="button" class="btn btn-primary no-img more-info">More Info</button>' +
            '</a>';
          } else {
            sponsorString = '<a href="' + data.product.sponsor_url + '" target="_blank">' +
              '<button type="button" class="btn btn-primary more-info">More Info</button>' +
            '</a>';
          }
        } else {
          sponsorString = '';
        }
        return sponsorString;
      }

      return `
        <div class="product block-text-right">
          <div class="product-left media">
            <button type="button" class="btn btn-stroke btn-inverse" id="exit-product-overlay" onclick="SNI.ProductOverlay.overlayRestore()">
              Back to Tour
            </button>
            ${ checkProductImg() }
          </div>
          <div class="product-right text">
            <h5 id="product-count">Product ${data.currentProduct}  of ${data.totalProducts}</h5>
            ${ checkTitle() + checkLogoImg() + checkDesc() + checkSponsor() }
          </div>
          <a id="previous-product" onclick="SNI.ProductOverlay.showOverlay(${data.previous}, '${data.componentUrl}')">
            <span class="icon icon-chevron-left"></span>
          </a>
          <a id="next-product" onclick="SNI.ProductOverlay.showOverlay(${data.next}, '${data.componentUrl}')">
            <span class="icon icon-chevron-right"></span>
          </a>
        </div>`;
    },

    internationalModalBody: (data) => {
      let modalBodyHtml = '';
      // There are three parts to the International Modal that aren't already a part of the standard modal:
      //  1. The CTA: e.g. "Go to foodnetwork.co.uk". This is a link that directs the user to their native website.
      //  2. The Dismissal CTA: e.g. "Continue to foodnetwork.com". This link just dismisses the modal, letting the user carry on as they were.
      //  3. The Logo and Banner Image: these are images that appear to help identify the brand. This may vary depending on region,
      //       though with the original creation of this International Modal, FN is the only brand with the modal, and the same logo is used for all regions.
      //        banner images can vary by region, with some regions using the same image.

      modalBodyHtml = `<a class="o-InternationalDialog__a-Button" href=" ${data.nativeCtaUrl}"> ${data.nativeCtaTitle}</a>
        <a class="o-InternationalDialog__a-Button--Text" data-dismiss="${data.dismissModalAttr}"> ${data.usCtaLabel}</a>`;

      // We make the logo optional. Arguably, all of the parts of the International Modal would preferably be optional.
      //  But it wasn't certain when writing this whether certain regions would have a logo,
      //  so it was made optional even if none of the regions are without a logo now.
      if (data.logoUrl) {
        modalBodyHtml = modalBodyHtml +
          `<img class="o-InternationalDialog__a-Logo" src="//food.fnr.sndimg.com/${data.logoUrl}"/>`;
      }

      if (data.bannerImageUrl) {
        modalBodyHtml = modalBodyHtml +
          `<img class="o-InternationalDialog__a-BannerImage" src="//food.fnr.sndimg.com/${data.bannerImageUrl}"/>`;
      }

      return modalBodyHtml;
    },

    headline: (data) => {
      return `<h3 class="${data.parentClass}__a-SubHeadline a-SubHeadline"><span class="${data.parentClass}__a-Headline">${data.title}</span></h3>`;
    },

    subHeadline: (data) => {
      let modifiedClass = data.modifier ? `${data.parentClass}__a-SubHeadline--${data.modifier} `: '';
      return `<div class="${data.parentClass}__a-SubHeadline ${modifiedClass}a-SubHeadline">${data.text}</div>`;
    },

    button: (data) => {
      return `<a href="${data.url}" class="${data.parentClass}__a-Button a-Button">${data.text}</a>`;
    },

    onTVDropdownHeader: (data) => {
      let open = data.isMobile ? '<span class="m-ScheduleDropdown__m-ShowInfo">' : `<a href="${data.episodeUrl}" class="m-ScheduleDropdown__m-ShowInfo">`;
      let close = data.isMobile ? '</span>' : '</a>';

      let desc = data.isMobile && data.recipeCount === 0 ? '' : `<span class="m-ShowInfo__a-Description">${data.isMobile ? data.recipeCount + ' recipe' + (data.recipeCount === 1 ? '' : 's') : data.episodeTitle}</span>`;
      let title = '<span class="m-ShowInfo__a-Headline">' + (data.isMobile ? `<a href="${data.episodeUrl}">` : '') + `${data.showTitle}${data.isMobile ? '</a>' : ''}</span>`;
      let imgblock = data.isMobile || !data.showImage ? '' : `<span class="m-ShowInfo__a-ImageWrap"><img class="m-ShowInfo__a-Image" src="${data.showImage}" /></span>`;

      return `
      ${open}
        ${imgblock}
        <span class="m-ShowInfo__m-TextWrap">
          ${title}
          ${desc}
        </span>
      ${close}
      `;
    },

    // Create body template for addToBoard modal.
    // boards - list of boards wrapped in <li ... ></li> (created by wrapSavesBoard(board) call)
    addToBoardModalBody: (boards) => {
      return  `<div class="o-Modal__a-Boards">
                <span class="o-Modal__a-Button--Cancel" data-dismiss="modal" data-type="modal-close">Cancel</span>
                <ul>
                  <li class="o-Modal__a-Boards--new" data-create-board>
                    <span class="o-Modal__a-Boards--new__icon"></span><span class="o-Modal__a-Boards--new__title">New Board</span>
                  </li>
                  ${boards.join('')}
                </ul>
              </div>`;
    },

    wrapSavesBoard: (board) => {
      return board.id === 'my-recipes' ? '' : `<li class="o-Modal__a-Boards__board">
                                                 <label class="o-Modal__a-Boards__board-label">
                                                   <input class="o-Modal__a-Boards__board-input" type="checkbox" id="${ board.id }" value="${ board.id }" ${board.selected || board.containsCard ? 'checked' : ''} style="background-image: url(${board.image})" />
                                                   <span class="o-Modal__a-Boards__board-checkmark"></span>
                                                   <span class="o-Modal__a-Boards__board-title">${board.title}</span>
                                                 </label>
                                               </li>`;
    },

    watchButton: (title, brand) => {
      const icons = {
        food: `<svg class="a-Button__a-Icon--Watch a-Button__a-Icon a-Icon--Watch a-Icon" role="img">
                  <symbol id="icon-watch" viewBox="0 0 21 21">
                    <path d="M10.5 19a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0 2a10.5 10.5 0 1 1 0-21 10.5 10.5 0 0 1 0 21z"></path>
                    <path d="M15.4 10.9l-8.1 4v-8z"></path>
                  </symbol>
                  <use xlink:href="#icon-watch"></use>
                </svg>`,
        discovery: '<svg class="a-Button__a-Icon--Watch a-Button__a-Icon a-Icon--Watch a-Icon" role="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 44 44"><circle cx="22" cy="22" r="22"/><path fill="#141414" d="M28.5 22.7l-9.8 6.1a.2.2 0 0 1-.3 0 .2.2 0 0 1 0-.1V16.3a.2.2 0 0 1 .3-.1l9.8 6.1a.2.2 0 0 1 0 .3z"/></svg>',
        default: '<svg class="a-Button__a-Icon--Watch a-Button__a-Icon a-Icon--Watch a-Icon" role="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 44 44"><circle cx="22" cy="22" r="22"/><path fill="#141414" d="M28.5 22.7l-9.8 6.1a.2.2 0 0 1-.3 0 .2.2 0 0 1 0-.1V16.3a.2.2 0 0 1 .3-.1l9.8 6.1a.2.2 0 0 1 0 .3z"/></svg>'
      };

      return  ` <span class="a-Button__a-ButtonText">${title}</span>
                  ${icons[brand] ? icons[brand] : icons.default}
                </svg>`;
    },

    checkMarkSVG: () => {
      return '<svg class="check-mark-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 11" style="enable-background:new 0 0 15.7 11"><path d="M14.4.2c.1-.1.3-.2.5-.2s.4.1.6.2c.2.1.2.3.2.5s-.1.4-.2.6L6 10.8c-.1.1-.3.2-.5.2s-.4-.1-.6-.2L.2 6.1c-.1-.2-.2-.4-.2-.6s.1-.4.2-.6c.2-.2.4-.2.6-.2s.4.1.6.2L5.5 9 14.4.2z"/></svg>';
    },

    xCloseSVG: () => {
      return xCloseSVG;
    },

    voteBannerMarkup: () => {
      return `<div class="vote-banner"><p class="vote-banner__text">View each gallery before casting your VOTE</p><button class="vote-banner__close">${xCloseSVG}</button><div>`;
    },

    scrollRightButton: () => '<div data-type="scroll-right" class="a-ScrollButton a-ScrollButton--Right"></div>',
    scrollLeftButton: () => '<div data-type="scroll-left" class="a-ScrollButton a-ScrollButton--Left"></div>',

    idspLoginMarkup: (title = 'Account Information') => `<div class="o-UserProfile__login-container"><h1 class="o-UserProfile__login-title">${title}</h1><button class="o-UserProfile__login-btn" data-type="idsp-login">Log In</button></div>`,

    defaultAvatarHTML: () => {
      return `
      <svg class="o-Header__a-Icon a-Icon--Profile" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27" height="27" viewBox="0 0 27 27" aria-hidden="true">
        <defs>
            <path id="prefix__a" d="M17.5 13.75c0 2.761-2.239 5-5 5s-5-2.239-5-5h10z"/>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
            <path fill="#FFF" stroke="#1C1C1C" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M25 12.5C25 19.404 19.404 25 12.5 25S0 19.404 0 12.5 5.596 0 12.5 0 25 5.596 25 12.5z"/>
            <path fill="#000" d="M9.643 7.679c0 .592-.48 1.071-1.072 1.071-.59 0-1.071-.48-1.071-1.071 0-.592.48-1.072 1.071-1.072.592 0 1.072.48 1.072 1.072zM17.5 7.679c0 .592-.48 1.071-1.071 1.071-.592 0-1.072-.48-1.072-1.071 0-.592.48-1.072 1.072-1.072.592 0 1.071.48 1.071 1.072z"/>
            <path stroke="#1C1C1C" stroke-width="1.5" d="M18.202 13H6.798c-.238 1.882.434 3.614 1.636 4.816 1.04 1.04 2.478 1.684 4.066 1.684 1.588 0 3.025-.644 4.066-1.684 1.202-1.202 1.874-2.934 1.636-4.816z"/>
        </g>
      </svg>

      <svg class="o-Header__a-Icon a-Icon--Profile-hover" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" aria-hidden="true">
        <g fill="none" fill-rule="evenodd">
            <path fill="#FFDF56" stroke="#1C1C1C" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M25 12.5C25 19.404 19.404 25 12.5 25S0 19.404 0 12.5 5.596 0 12.5 0 25 5.596 25 12.5z" transform="translate(1 1)"/>
            <path fill="#000" d="M9.643 7.679c0 .592-.48 1.071-1.072 1.071-.59 0-1.071-.48-1.071-1.071 0-.592.48-1.072 1.071-1.072.592 0 1.072.48 1.072 1.072zM17.5 7.679c0 .592-.48 1.071-1.071 1.071-.592 0-1.072-.48-1.072-1.071 0-.592.48-1.072 1.072-1.072.592 0 1.071.48 1.071 1.072z" transform="translate(1 1)"/>
            <g>
                <path fill="#E6003D" stroke="#1C1C1C" stroke-linejoin="round" stroke-width="1.5" d="M11.202-.75H-.202C-.44 1.132.232 2.864 1.434 4.066 2.474 5.106 3.912 5.75 5.5 5.75c1.588 0 3.025-.644 4.066-1.684 1.202-1.202 1.874-2.934 1.636-4.816z" transform="translate(1 1) translate(7 13.75)"/>
                <path fill="#FF626A" d="M9.058 3.487C8.15 2.569 6.892 2 5.5 2c-1.418 0-2.698.59-3.608 1.538C1.03 4.436 4.167 5.25 6 5.25c1.5 0 3.95-.86 3.058-1.763z" transform="translate(1 1) translate(7 13.75)"/>
                <path stroke="#1C1C1C" stroke-linejoin="round" stroke-width="1.5" d="M11.202-.75H-.202C-.44 1.132.232 2.864 1.434 4.066 2.474 5.106 3.912 5.75 5.5 5.75c1.588 0 3.025-.644 4.066-1.684 1.202-1.202 1.874-2.934 1.636-4.816z" transform="translate(1 1) translate(7 13.75)"/>
            </g>
        </g>
      </svg>

      `;
    },

    navProfileDefaultMenu: () => {
      return `
        <ul
          class="o-Header__m-DropdownList logged-out"
          data-animate-item
        >
          <li class="o-Header__m-DropdownListItem">
          <a href="#" data-type="idsp-login">Log In</a>
          </li>
          <li class="o-Header__m-DropdownListItem">
          <a href="#" data-type="idsp-register">Sign Up</a>
          </li>
          <li class="o-Header__m-DropdownListItem">
          <a href="https://help.foodnetwork.com/hc/en-us/requests/new">Contact Us</a>
          </li>
        </ul>
      `;
    },

    navProfileRegisteredUserMenu: () => {
      return `
        <ul
          class="o-Header__m-DropdownList logged-in"
          data-animate-item
        >
          <li class="o-Header__m-DropdownListItem">
              <a href="#" class="profile-path">Profile</a>
          </li>
          <li class="o-Header__m-DropdownListItem">
              <a href="https://help.foodnetwork.com/hc/en-us/requests/new">Contact Us</a>
          </li>
          <li class="o-Header__m-DropdownListItem">
              <a href="#" data-type="idsp-logout">Log Out</a>
          </li>
        </ul>
      `;
    },

  };
});
