SNI.Application.addService('internationalDialog', (application) => { // (author: Brandon Foster, date: 2016-12-08).

  const util = application.getService('utility');
  const cookieService = application.getService('cookie');
  const debug = application.getService('logger').create('service.internationalDialog');
  const check = application.getService('check').new(debug);
  const modal = application.getService('modal');
  const template = application.getService('template');
  const cookieName = 'supressInternationalModal';
  const modalId = 'internationalModal'; // this ID ends up on the .o-Modal div tag, used for styling.
  const dismissModalAttr = 'international-modal';
  const modalImageClass = 'o-InternationalDialog__a-BannerImage';
  const modalContentClass = 'o-Modal__m-Content';
  const dialogClass = 'o-InternationalDialog';

  // the AJAX success function: used once data has been returned to create the modal
  function initModal(receivedData) {
    var data;

    try {
      // expecting receivedData to URL like /content/internationalModal/food.html be JSON object with the data we need.
      if (typeof receivedData === 'string') {
        data = JSON.parse(receivedData);
        data.dismissModalAttr = dismissModalAttr;
      } else if (typeof receivedData === 'object'){
        data = receivedData; // we can also receive data from other sources that don't use the AJAX request and create their own JSON data for the modal.
      } else {
        debug.error('Not sure what to do with this data: ', receivedData);
        return false; // bailing on the function since we can't make a modal without the data!
      }
    } catch (error) {
      debug.error('Failed to parse HTTP Response into JSON. receivedData: ', receivedData);
      return false; // bailing on the function since we can't make a modal without the data!
    }

    if (!data.title) {
      debug.warn('Title is not received over the data, so the country code may not be registered with a region.');
      return false; // no title is suspect, so we want to suppress the modal because the data probably isn't populated.
    }

    // We pass HTML to the modal service, which it then drops into the body of the modal.
    //  This allows us to use the default modal service (and not have to implement our own)
    //  and still achieve the custom functionality and content we want.
    modal.open(modalId, {
      header: {
        title: data.title,
        subtitle: data.description
      },
      body: {
        html: template.internationalModalBody(data)
      },
    }, false, {
      backdrop: 'static',
      keyboard: false,
      show: true
    });

    // The user needs to be able to close the modal with the Dismissal CTA (the a-Button--Text)
    //  which, for example, says something like "Continue to Foodnetwork.com"
    $(`[data-dismiss="${dismissModalAttr}"]`).on('click', function() {
      modal.close(modalId);
    });

    // create cookie to suppress future modals
    cookieService.set(cookieName, true, 30, false, true); // cookie lasts for 30 days and is domain specific but not path specific.

    //move the bannerImage and logo to the top of the international modal, add the dialog class to the modal
    $('.o-InternationalDialog__a-Logo').prependTo('#' + modalId + ' .' + modalContentClass);
    $('.' + modalImageClass).prependTo('#' + modalId + ' .' + modalContentClass);
    $('#' + modalId).addClass(dialogClass);

  }

  return {
    // named "modal" because internationalDialog may include other methods for creating different kinds of
    // international redirects that we don't know about currently. Modal, then, is just one possible instance of the International Dialog.
    modal(overrideData) { // parameter allows passing your own JSON object in, without having to use the AJAX request.

      var countryCode = sessionStorage.getItem('countryCode'),
          suppressInternationalModal = cookieService.get(cookieName),
          countryCodeQueryParam = util.getUrlParam(window.location.href, 'countryCode').toString(),
          modalDataPath = '/content/internationalModal/';

      // offer a way to override the country code for testing
      if (typeof countryCodeQueryParam !== 'undefined' && countryCodeQueryParam !== '') {
        // This allows for easy testing when Akamai's geolocation services aren't available, e.g. on author or publish nodes.
        countryCode = countryCodeQueryParam;
      }

      // if the cookie exists, the modal has already been seen - don't init the modal
      if (suppressInternationalModal && !util.getUrlParam(window.location.href, 'ignoreModalCookie')) { // you can bypass this suppression with a query param, for testing purposes
        debug.log('International Modal suppressed by cookie.');
        return false;
      } else if (typeof countryCode === 'undefined' || countryCode === '$(GEO{\'country_code\'})' || countryCode === '' || countryCode === 'null') {
        debug.warn('Country Code is missing, suppressing International Modal.');
        return false;
      } else if (countryCode === 'US'){ // if the country code is the US, the user is already at the correct website - no need for a modal to direct them to a native site.
        debug.log('Country Code is US, suppressing International Modal.');
        return false;
      } else if (!check.exists('SNI.Config.brand')) { // Just in case the global isn't defined...
        debug.warn('SNI.Config.brand was undefined, can\'t make a request to get the modal data, so the modal will not initialize.');
        return false;
      } else if (typeof overrideData !== 'undefined') { // assuming a JSON object was passed in to be used instead of the AJAX request.
        initModal(overrideData);
        return true; // necessary to let unit test know the modal was created.
      } else { // otherwise, we are good to move forward with creating the modal
        // First we make an AJAX call to a node, e.g. for FoodNetwork.com /content/internationalModal/food.html is requested.
        // The country code is passed as a query parameter.
        $.ajax({
          type: 'GET',
          url: modalDataPath + SNI.Config.brand + '.' + countryCode + '.html', // pass country code as an path selector so that Java Model can pull it out of the Request and return the proper region's data. For more on Selectors, see: https://sling.apache.org/apidocs/sling7/org/apache/sling/api/request/RequestPathInfo.html
          success: initModal,
          error: function(xhr, textStatus, errorThrown) {
            debug.warn('AJAX request error:', '\nXHR: ', xhr, '\nText Status: ', textStatus, '\nError Thrown:', errorThrown, '\n',
              'The request may have failed because the international Modal data for ' + SNI.Config.brand + ' may be missing.\n',
              'Check ' + modalDataPath + SNI.Config.brand + ' in CRX and see that the sling:resourceType is set to the correct value, and that there are region nodes under the brand node that have the correct properties.');
          }
        });
        return true; // necessary to let unit test know the modal was created.
      }
    }
  };


});
