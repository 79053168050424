SNI.Application.addService('router', function(application) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let previousURL,
      service;

  const ajax = application.getService('ajax');

  function setPreviousUrl(url) {
    let cleanedUrl = cleanURL(url);
    if (cleanedUrl) {
      previousURL = cleanedUrl;
    }
  }

  function retrievePreviousUrl() {
    if (typeof previousURL !== 'undefined') {
      return previousURL;
    } else {
      let defaultURL = cleanURL(window.location.href);
      previousURL = defaultURL;
      return defaultURL;
    }
  }

  function isWCMDisabled() {
    if (typeof application.getGlobal('cqWCMDisabled') !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  function isTargetValid(target) {
    return (target !== '' && typeof(application.getGlobal('cqBasePage')) !== 'undefined');
  }

  function isLinkValid(target) {
    let wcmDisabled = isWCMDisabled(),
        targetValid = isTargetValid(target);

    return (wcmDisabled && targetValid);
  }

  function isURLDifferent(url) {
    return (window.location.href.indexOf(url) === -1);
  }

  function routeGallery(launcher) {
    if (launcher && isURLDifferent(previousURL)) {
      application.broadcast('stateChanged', {
        href: window.location.href
      });
    }
  }

  function isString(val) {
    if (typeof val === 'string') {
      return true;
    } else {
      return false;
    }
  }

  function cleanURL(url) {
    if (typeof url !== 'undefined' && isString(url)) {
      return url.replace(/\??\&_suid.*/,'');
    } else {
      return false;
    }
  }

  function routeTarget(target, url) {
    // application.broadcast('ajax.areacontentreadytoload', {
    //   target: target,
    //   href: url
    // });
    ajax.loadAreaContent({
      target: target,
      href: url
    });
  }

  function determineRoute(data) {
    let target = data.target,
        gallery = data.gallery,
        launcher = data.launcher,
        cleanUrl = cleanURL(data.href);

    if (gallery) {
      routeGallery(launcher);
    }

    if (target && cleanUrl) {
      if (typeof window.history !== 'undefined') {
        window.history.pushState({},'',cleanUrl);
      }
      routeTarget(target, cleanUrl);
    }

  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  service = {

    setPreviousUrl,

    getPreviousURL() {
      return retrievePreviousUrl();
    },

    routeClickHandling: function(event) {
      let target = $(event.target).data('target') || '',
          href = $(event.target).attr('href') || '',
          isRouteableLink = isLinkValid(target);

      if (isRouteableLink && href) {
        event.preventDefault();
        event.stopPropagation();
        routeTarget(target, href);
      }

    },

    handleStateChange: function(data) {

      determineRoute(data);

    }

  };

  return service;

});
