/**
  * Check Utility
  * Author: jmoore
  *
  * Some tools used for consistency in how we check the environment our JavaScript runs in.
  * This could be either browser functionality or safety checks, such as that an object is defined.
  *
  * exists ( checkFor: String or Array of Strings [, parent: Object] )
  *   Allows a deep check for an object.  ie.  check.exists( ['mdManager', 'foo.nameSpace.someNewFeature.someNewMethod'], window )
  *   returns: Boolean
  *
  * supports ( featureName: String )
  *   Checks for a browser feature such as localStorage, sessionStorage, or history
  *   returns: Boolean, or -1 if featureName is unrecognized
  *
  * new ( debug Object )
  *   This allows checks to output relevant debug messages
  *   returns: a new instance of the check module with the debug object that was passed in attached to it
  * -------------------------------------------------------------
  */

SNI.Application.addService('check', (application) => {

  let _supports = {}; //private cache of feature support
  let check = {

    exists: function(obj, parent) {
      let logId = 'check.exists()';
      obj = ( typeof obj === 'string' ) ? [obj] : obj;

      if ( obj.constructor === Array && obj.length ) {
        for (let i=0; i < obj.length; i++) {
          let path = obj[i].split('.'),
              namespace = parent || window;

          for (let j=0; j < path.length; j++) {
            if (typeof namespace[path[j]] === 'undefined') {
              if (check.debug) {
                check.debug.warn(logId, '`' + path.slice(0, j+1).join('.') + '` does not exist.');
              }
              return false;
            } else {
              namespace = namespace[path[j]];
            }
          }
        }
      } else {
        if (check.debug) {
          check.debug.error(logId, 'Must be passed a string or an array of strings.');
        }
        return false;
      }

      return true;
    },

    jqueryPlugin: function(name) {
      let exists = (typeof $.fn[name] !== 'undefined');
      if (!exists && check.debug) {
        check.debug.error(`jQuery plugin ${name} is not registered! maybe another copy of jQuery overwriting ours?`);
      }
      return exists;
    },

    // Avoid using this! No new sites are being added to the list.
    isProd: function() {
      let isProd = !!window.location.hostname.match(/(www\.hgtv)|(www\.diynetwork)|(www\.greatamericancountry)|(www\.foodnetwork)|(www\.travelchannel)|(www\.cookingchanneltv)/g);
      check.debug.warn('isProd', isProd);
      return isProd;
    },

    isAuthor: function() {
      return (typeof window !== 'undefined') ? /^author/.test(window.location.hostname) : false;
    },

    isFriendlyHost(host = window.location.hostname) {
      return (!/^(author|dispatcher|publish)/.test(host) && (host !== 'localhost'));
    },

    isLocalhost: function() {
      return (window.location.hostname === 'localhost');
    },

    supports: function(name) {
      let result = -1;
      let storage = function(name) {
        //method borrowed from Modernizr
        let str = 'sniSuppTest';
        try {
          window[name].setItem(str,str);
          window[name].removeItem(str);
          return true;
        } catch (e) {
          return false;
        }
      };
      if (typeof _supports[name] !== 'undefined') {
        result = _supports[name];
      } else {
        if (name === 'localStorage' || name === 'sessionStorage') {
          result = storage(name);
        }
        if (name === 'history') {
          result = check.exists('history.pushState');
        }
        _supports[name] = result;
        if (check.debug) {
          check.debug.warn('check.supports()', name, 'is', (result === true ? 'supported' : result === -1 ? 'unrecognized test' : 'unsupported'));
        }
      }
      return result;
    },

    'new': function(obj) {
      return $.extend(true, check, { debug: obj });
    }
  };

  return check;
});
