/**
* Dalton Behavior to handle UI updates in old (Cooking Channel) header profile dropdown
*   in response to Dalton state (Login, logout)
*
*/

SNI.Application.addBehavior('dalton/nav-profile-cc', (context) => {

  let debug = context.getService('logger').create('behavior.dalton.nav-profile-cc'),
      deviceType = context.getService('device-type'),
      $element = $(context.getElement()),
      $ccMenu = $element.find(`.o-Header${deviceType.isMobile ? 'Mobile' : ''}__m-ProfileInfo`);

  /*
   * logged in? => show user nickname & log out control
   * logged out? => show log in & register controls
   * wonky use of attributes is due to backward compatibility with existing show/hide styles in G!gya implementation
   */
  function updateUI(isLoggedIn, dta) {
    debug.log('updateUI', isLoggedIn);
    if (isLoggedIn) {
      $ccMenu.find('[data-logged-in]').attr('data-logged-in-yes','').removeAttr('data-logged-in');
      $ccMenu.find('[data-logged-out-yes]').attr('data-logged-out','').removeAttr('data-logged-out-yes');
      $ccMenu.find('[data-social-nickname]').text(dta.nickname.length < 11 ? dta.nickname : dta.nickname.substring(0,10) + '...');
    } else {
      $ccMenu.find('[data-logged-out]').attr('data-logged-out-yes','').removeAttr('data-logged-out');
      $ccMenu.find('[data-logged-in-yes]').attr('data-logged-in','').removeAttr('data-logged-in-yes');
    }
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      updateUI(true, dta);
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      updateUI(false);
    },
  };

  function init() {
    debug.log('init');
  }

  return {

    init,

    messages: Object.keys(messageHandlers),
    onmessage(message, data) {
      messageHandlers[message](data);
    },

  };

});
