import { isEmpty } from 'lodash';

const CDN_LINK = 'https://cdn.optimizely.com';
const OPT_CLASS = 'isHiddenForOptimizely';

let isStyleAdded = false;

const onOptInited = () => {
  if (isStyleAdded) return;

  const style = document.createElement('style');
  style.id = 'opt-show-css';
  style.innerHTML = `.${OPT_CLASS}{ display: block; }`;
  document.head.appendChild(style);
  isStyleAdded = true;
  console.log('Added css stylesheet!');
};

// Firefox has Encanced Tracking Protection (ETP) in incognito mode and replace optimizely with shim.
// Only case to find out it is check if there are no available experiments.
// https://bugzilla.mozilla.org/show_bug.cgi?id=1714431
const FFScriptLoadHack = () => {
  const experiments = window.optimizely.get('state').getActiveExperimentIds();

  if (isEmpty(experiments)) onOptInited();
};

/**
 * For cases when optimizely loaded on the page, but experiment hasn't started yet
 * we need to show elements that were hidden for experiment.
 * @returns void
 */
export const runCheck = () => {
  const script = document.querySelector(`script[src^="${CDN_LINK}"`);
  const itHasOptScript = !!script;
  const isFirefox = /Firefox/.test(navigator.userAgent);

  if (!itHasOptScript) return;

  // Due the async nature of optimizely scripts in rare cases we can
  // face the situation when optimizely is already inited.
  // In this case "initialized" event won't be fired so we need to run callback.
  if (window.optimizely?.initialized) {
    console.log('Optimizely already inited!');
    onOptInited();
    return;
  }

  if (isFirefox) script.addEventListener('load', FFScriptLoadHack);

  // Official guideline how to add cb's for optimizely events:
  // https://docs.developers.optimizely.com/web-experimentation/reference/add-listener#example-call-1
  (window.optimizely = window.optimizely || []).push({
    type: 'addListener',
    filter: { type: 'lifecycle', name: 'initialized' },
    handler: onOptInited,
  });
};
