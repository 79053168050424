SNI.Application.addService('url', application => {

  const debug = application.getService('logger').create('services/url');

  /**
   * do not put gac in the map because gac has no watch live page
   * gac: 'greatamericancountry',
   */
  const domainMap = {
    hgtv: 'hgtv',
    diy: 'diynetwork',
    gac: 'greatamericancountry',
    food: 'foodnetwork',
    cook: 'cookingchanneltv',
    travel: 'travelchannel',
    discovery: 'discovery'
  };


  /**
   * @param {string} brand The brand slug of the site
   * @param {object} domains An object with a mapping of brand slug => base domain (ie diy => diynetwork)
   */
  const brandToDomain = (brand, domains = domainMap) => {
    if (brand in domainMap) {
      return domains[brand];
    }
    debug.error(`Expected brand ${brand} does not exist in the domainMap`);
    return false;
  };

  /**
   *
   * @param {string} brand The brand slug of the desired watch site (defaults to the current brand)
   */
  const watchSite = (brand = SNI.Config.brand) => {
    const domain = brandToDomain(brand);
    switch (domain) {
      case 'hgtv':
      case 'diynetwork':
      case 'foodnetwork':
      case 'cookingchanneltv':
      case 'travelchannel':
        return `http://watch.${domain}.com/?utm_source=marketingsite&utm_medium=ontvbutton`;
      case 'discovery':
        return `https://go.${domain}.com/watch/${domain}?utm_source=marketingsite&utm_medium=ontvbutton`;
    }

    return '';
  };

  const watchSiteOnRightRail = (brand = SNI.Config.brand) => {
    const wSite = watchSite(brand);
    return wSite.replace('ontvbutton', 'ontvrightrail');
  };

  return {
    brandToDomain,
    watchSite,
    watchSiteOnRightRail
  };

});
