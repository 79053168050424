import './logger';
import './check';

SNI.Application.addService('ads', function(application) {
  'use strict';

  /*
    SniAds usage follows this pattern throughout.  We should re-use this service wherever the pattern occurs
  */

  let debug = application.getService('logger').create('service.ads');
  let check = application.getService('check').new(debug);
  let SniAds = check.exists('SniAds') ? application.getGlobal('SniAds') : false;

  function subscribe() {
    let args = Array.prototype.slice.call(arguments);
    if (SniAds) {
      SniAds.Event.subscribe.apply(this, args);
    }
  }

  function getDynamicSlots($container, adLib = SniAds) {
    let slots = [];
    if (adLib) {
      $container.find('[data-has-ad]').each(function() {
        let id = $(this).attr('id'); // dfp_bigbox_<count> || dfp_leaderboard_<count>
        let adType = $(this).data('has-ad'); // dfp_bigbox || dfp_leaderboard

        debug.log('getDynamicSlots', id, adType);

        if (id && adType) {
          slots.push({
            id: id,
            type: adType
          });
        } else {
          debug.error(
            'getDynamicSlots(): one of these values is not defined:',
            `id: ${id}`,
            `adType: ${adType}`
          );
        }
      });
    }
    return slots;
  }

  function adsGallery(method, param){
    try {
      if (SniAds && SniAds.ready && SniAds.Gallery[method]) {
        SniAds.Gallery[method](param);
      } else {
        debug.log('SNIAds gallery error.  Could not call:', method, param);
      }
    } catch (e) {
      debug.error('SniAds Gallery:', e);
    }
  }

  function lazyLoadAd(slot) {
    if (SniAds && SniAds.ready && SniAds.appendSlot) {
      SniAds.ready(() => {
        SniAds.appendSlot(slot.id, slot.type, {}, true); // lazy-load dynamic slots
        debug.log(
          'SniAds.appendSlot(slot.id, slot.type, {}, true);',
          slot.id,
          slot.type
        );
      });
    }
  }

  function tryAppendSlot(id, slotType) {
    let slot = {
      id,
      type: slotType
    };
    try {
      lazyLoadAd(slot);
    } catch (e) {
      debug.error('Ads call error', e);
    }
  }

  return {
    adLib: SniAds,
    getDynamicSlots,
    adsGallery,
    lazyLoadAd,
    tryAppendSlot,
    event: {
      subscribe
    }
  };
});
