import './logger';
import './utility';

SNI.Application.addService('schedule', function(application) {

  let debug     = application.getService('logger').create('service-schedule');
  let utility   = application.getService('utility');

  function getOffsetFromZone(zone) {
    let offset = 0;
    // The schedule is in eastern time zone, so we need to apply an offset.
    switch (zone) {
      default:
      // Fall through to eastern;
      case 'eastern':
        offset = 0; // East coast schedule.
        break;
      case 'central':
        offset = 0; // East coast schedule. Ultimately 1 our behind of the EST offset to GMT.
        break;
      case 'mountain':
        offset = 3; // West coast schedule. Ultimately 1 our ahead of the EST offset GMT.
        break;
      case 'pacific':
        offset = 3; // West coast schedule. Ultimately the same as EST offset GMT.
        break;
    }
    return offset;
  }

  function compareDateTimeNowAndAirtime(dateTime, airTime, zone) {

    let offset = getOffsetFromZone(zone);

    // Manipulated this to follow biz rules.
    let compareTime = new Date(dateTime.getTime());

    // zero out the minutes and seconds for comparison purposes.
    compareTime.setSeconds(0);
    compareTime.setMilliseconds(0);

    if (offset !== 0) {
      // this seems to roll back the date too, if we pass a negative number, which is exactly what we want.
      compareTime.setHours(dateTime.getHours() - offset);
    }

    let diff = compareTime.getTime() - airTime.getTime(),
        millisPerMinute            = 1000 * 60,
        tenMinutesBeforeInMillis   = -10 * millisPerMinute,
        twentyMinutesAfterInMillis = 20 * millisPerMinute;

    let match = tenMinutesBeforeInMillis <= diff && diff < twentyMinutesAfterInMillis;
    return match ? 0 : diff;
  }

  let schedulePromise;

  function getScheduleData() {
    return schedulePromise ? schedulePromise : schedulePromise = $.ajax('//' + location.hostname + '/content/food-com/en.dailySchedule.html');
  }

  function adjustForTimezone(date, offset) {
    return new Date(date.getTime() + (60 * 60 * 1000 * offset));
  }

  function findCurrentlyAiring(html) {

    // Create separate document so we can still parse the markup using jQuery but without actually loading all the images
    var root = document.implementation.createHTMLDocument().body;
    root.innerHTML = html;
    var $html = $(root);

    let $results = $html.find('[data-time]:not([class])');
    debug.log('$results', $results);

    const offset = getOffsetFromZone(utility.getZone(new Date()));
    let nowTime = adjustForTimezone(new Date(), offset);
    debug.log('offset: ' + offset + ' nowTime ' + nowTime);

    // get the index of the currently airing showing:
    //   find the first one that has not begun and begins more than 10 minutes from now
    //   take the previous one
    const millisPerMinute = 1000 * 60;
    const tenMinutesInMillis = 10 * millisPerMinute;
    const oneHourInMillis = 60 * millisPerMinute;
    // array of showtimes in order
    let showTimes = $results.toArray().map(e => adjustForTimezone(new Date(e.getAttribute('data-time')), offset));
    // need a guard element at the end; assume last show of the day is 1 hour
    showTimes.push(new Date(showTimes[showTimes.length - 1].getTime() + oneHourInMillis));
    // find index of the currently airing show
    let idx = showTimes.findIndex((v, i, a) => v.getTime() - tenMinutesInMillis > nowTime.getTime()) - 1;
    debug.log('index', idx);

    let $currentShowing = (idx >= 0) ? $results.eq(idx) : null;

    debug.log('current showing');
    debug.log($currentShowing ? $currentShowing.get(0) : null);

    // Need to move it back to original owner document
    return $currentShowing ? $($currentShowing.prop('outerHTML')) : null;
  }

  return {
    getOffsetFromZone,
    adjustForTimezone,
    compareDateTimeNowAndAirtime,
    findCurrentlyAiring,
    getScheduleData,
  };
});
