SNI.Application.addBehavior('dropdown-menu', function(context) {
  let $element   = $(context.getElement());
  let deviceType = context.getService('device-type');
  let debug      = context.getService('logger').create('behavior.dropdown-menu');

  let defaults = {
    openClass: 'is-Open',
    openOnClick: (deviceType.isMobile || deviceType.isTablet)
  };

  let config = Object.assign({}, defaults, context.getConfig('dropdownMenu'));

  return {

    config,

    init: () => {

      if (!config.openOnClick) {
        debug.warn('config.openOnClick is false, exiting.');
        return false;
      }

    },

    onclick: function(event, element, elementType) {

      if (!config.openOnClick) return false;

      let $this = $(element);
      let dropdownmenu = '[data-type="dropdown-menu"]';
      let $items = $element.find('[data-type=sub-navigation-item]');
      let $openItem = function() { return $items.filter(`.${config.openClass}`); };

      function msg() {
        debug.log(`${$openItem().length ? 'opened' : 'closed'} dropdown in ${$element.attr('id')}`);
      }

      if (elementType !== '') {
        if (!$this.hasClass(config.openClass) && $this.find(dropdownmenu).length) {
          event.preventDefault();
        }
        //if a top level item links to #, don't follow the link, and close any open menus
        if ($this.children('a[href="#"]').length) {
          event.preventDefault();
          if ($openItem().length) {
            $items.removeClass(config.openClass);
            msg();
            return false;
          }
        }
      }

      if (elementType === 'sub-navigation-item') {
        // close menu if the target of the click isn't the nav item nor a descendant of it
        $(document)
          .off('mouseup.dropdown-menu')
          .on('mouseup.dropdown-menu', function(e) {
            if (!$this.is(e.target) && $this.has(e.target).length === 0) {
              $this.removeClass(config.openClass);
              $(document).off('mouseup.dropdown-menu');
              msg();
            }
          });

        if (deviceType.isMobile && $this.find(dropdownmenu).length) {
          $this.toggleClass(config.openClass);
          msg();
        } else {
          debug.log('this is device tablet: '+ deviceType.isTablet);
          if ($this.find(dropdownmenu).length && !$this.hasClass(config.openClass)) {
            $this
              .addClass(config.openClass)
              .siblings()
              .removeClass(config.openClass);
            msg();
            return false;
          }
        }
      }

    }

  };

});
