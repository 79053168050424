SNI.Application.addBehavior('toggle-on-message', context => {

  let el = context.getElement();
  let $el = $(el);
  let debug = context.getService('logger').create('behavior.toggle-on-message');
  let { deepMerge } = context.getService('utility');

  let defaults = {
    targetEl: $el,
    targetGlobal: false,
    hideOn: '',
    showOn: ''
  };

  const configCollection = context.getConfig('toggle-on-message');

  if (configCollection === null) {
    debug.warn(`toggle-on-message was included as a behavior for ${el.id}, but no config was given`);
    return {};
  }

  if (!Array.isArray(configCollection)) {
    debug.error(`toggle-on-message config expected to be array got type of ${typeof configCollection}`, $el, configCollection);
    return {};
  }

  // config is an array of toggleable targets that match the defaults object
  const config = configCollection
    .map(item => Object.assign({}, defaults, item))
    // if the targetEl is a string, map it to an element, otherwise leave it
    .map(item => typeof item.targetEl === 'string'
      ? Object.assign({}, item, { targetEl: item.targetGlobal ? $(item.targetEl) : $el.find(item.targetEl) })
      : item
    );

  const collectMessages = (config, key) =>
    config
      .map(item => ({ [item[key]]: [item.targetEl] }) )
      .reduce((msgs, msg) => msgs.concat(msg), [])
      .reduce((msgMap, msg) => deepMerge(msgMap, msg), {});

  const hideMessages = collectMessages(config, 'hideOn');
  const showMessages = collectMessages(config, 'showOn');

  const messages = [].concat(hideMessages, showMessages)
    .map(msgMap => Object.keys(msgMap))
    .reduce((msgs, msg) => msgs.concat(msg), [])
    .filter((msg, pos, msgs) => msgs.indexOf(msg) === pos);

  return {
    messages,

    onmessage(name, data) {

      if (hideMessages.hasOwnProperty(name)) {
        hideMessages[name].forEach(el => {
          debug.log('hiding', el, 'due to', name);
          el.hide();
        });
      }

      if (showMessages.hasOwnProperty(name)) {
        showMessages[name].forEach(el => {
          debug.log('showing', el, 'due to', name);
          el.show();
        });
      }

    }

  };

});
