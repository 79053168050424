import './logger';
import './utility';
import './device-type';

SNI.Application.addService('lazy-load', function(context) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let _$images = false,  //this will be an object that holds all the images in the current page that we'll be lazy loading, populated by addImages()
      modUtil = context.getService('utility'),
      debug = context.getService('logger').create('service.lazy-load'),
      deviceType = context.getService('device-type'),
      defaultOffset = 2000,
      $window = $(window),
      loadEvent = 'scroll.lazy-load resize.lazy-load',
      loadEventEnglish = loadEvent.split(' ').join('|'),
      settingsKey = 'sni.lazyLoadSettings';

  function applyToBackground(el, url) {
    $(el).parent().addClass('has-LazyLoadedBackground').css({
      backgroundImage: `url('${url}')`,
    });
  }

  function shouldApplyToBackground(settings) {
    let value = settings.moveImageToBackground;
    return typeof value !== 'undefined' && value.constructor === Array && (value.includes('desktop') && !deviceType.isMobile || value.includes('mobile') && deviceType.isMobile);
  }

  /**
   * Iterate through an array of images and modify each image to use replace the src attribute with the data-src
   * attribute.
   *
   * @private
   * @param  {array} images    Array of images to load
   */
  function loadImages($images) {
    debug.warn('Loading images');
    let count = 0,
        modulesNeedingMessages = ['editorial-promo', 'content-feed'],
        dataModuleId = $images.eq(0).closest('[data-module]').attr('id');

    $images.each(function() {
      let el = this,
          tempSettings = getSettings($(el)),
          settings = typeof tempSettings !== 'undefined' ? tempSettings : false,
          currentSrc = el.getAttribute('src'),
          dataSrc = el.getAttribute('data-src'),
          dataSrcResponsive = el.getAttribute('data-src-responsive'),
          allowed = settings.usePlaceholder ? (settings.placeholder === currentSrc) : !currentSrc;

      if (dataSrc && allowed) {
        if (shouldApplyToBackground(settings)) {
          applyToBackground(el, dataSrc);
        } else {
          el.setAttribute('src', dataSrc);
          debug.log(`${loadEventEnglish} fired. on ${dataSrc}`);
        }
        count++;
      } else if (dataSrcResponsive && allowed) {
        let dataSrcset = el.getAttribute('data-srcset'),
            dataSizes = el.getAttribute('data-sizes'),
            currentSizes = el.getAttribute('sizes'),
            currentSrcset = el.getAttribute('srcset');
        if (dataSizes && (dataSizes !== currentSizes)) {
          el.setAttribute('sizes', dataSizes);
        }
        if (dataSrcset && (dataSrcset !== currentSrcset)) {
          el.setAttribute('srcset', dataSrcset);
        }
        if (currentSrc !== dataSrcResponsive) {
          el.setAttribute('src', dataSrcResponsive);
        }
        count++;
      }
    });

    if (count > 0) {
      debug.log(`${count} images started loading in ${dataModuleId}.`);
      if (dataModuleId && modulesNeedingMessages.some(el => dataModuleId.includes(el))) {
        debug.log('Image loading message sent to:', dataModuleId);
        context.broadcast('lazy-load.image-loaded', dataModuleId);
      }
    }
    return removeLoading($images);
  }

  /** Remove loaded images from the list of images so we'll know when there are no more to load */
  function removeLoading($loading) {
    _$images = _$images.not($loading);
    return $loading;
  }

  /**
   * Given an array of images, determine which images are in the viewport and return an array of only the
   * visible images.
   *
   * @private
   * @param  {object} $window   Window object
   * @param  {array}  $images   Array of all images available to the module
   * @return {array}            Array of visible images
   */
  function getVisibleImages($window, $images, offset) {
    let inview = $images.filter(function() {
      return modUtil.isInViewport(this, 'partial', offset);
    });
    return inview;
  }

  /**
   * Polling function fired every time the window is scrolled. Calls getVisibleImages to determine which
   * images are in the viewport and then calls loadImages on the visible images.
   *
   * @private
   * @param  {jQuery object} $window   The window
   * @param  {array} $images      Array of all images to be tracked
   * @param  offset
   */
  function trackInview($window, $images, offset) {
    let $inview = getVisibleImages($window, $images, offset),
        $loading = $();

    if ($inview.length > 0) {
      $loading = loadImages($inview);
    }
    return $loading;
  }

  function getSettings($el) {
    return $el.data(settingsKey);
  }

  function getImagesByType(triggerType, $images) {
    return _$images.filter(function() {
      let s = getSettings($(this));
      return (s && (s.trigger === triggerType));
    });
  }


  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  let service = {

    init(earlyLoad = false) {
      if (!service.inited) {
        /** Track on scroll and resize until all _$images are loaded. */
        $window.on(loadEvent, modUtil.throttle(function() {
          if (_$images === false) {
            debug.warn('...before addImages() did.');
          } else {
            trackInview($window, getImagesByType('inview'), earlyLoad ? defaultOffset : 0);
            loadImages(getImagesByType('scroll'));

            if (_$images.length === 0) {
              debug.log('no more images left to lazy load.');
              service.destroy();
            }
          }
        }, 100));
        service.inited = true;
      }
      return service;
    },

    forceScroll() {
      let count = 1;
      let fire = setInterval(function() {
        //allow some time for dom changes and fire again, as dom changes are the use case for this method
        if (count > 10) {
          clearInterval(fire);
        } else {
          $window.trigger(loadEvent.split(' ')[0]);
        }
        count++;
      }, 30);
    },

    inited: false,

    /**
     * Given module parameters, add images and application data to the $_images list of images we'll be lazy loading
     *
     * @public
     * @param  {object} obj     In the following format:
     *  {
     *    container: [module element wrapped in jQuery object],
     *    settings:  [settings object merged from defaults in behavior and markup],
     *  }
     */
    addImages(obj) {
      let settings = obj.settings,
          $element = obj.container,
          earlyLoad = settings && settings.earlyLoad,
          $addImgs = $element.find('img'),
          $hasDataSrc = $addImgs.filter('[data-src]');

      if ($addImgs.length !== $hasDataSrc.length) {
        debug.warn('found images missing data-src attributes.', $addImgs.not($hasDataSrc));
      }

      /** Replace the existing src with a base64 encoded placeholder. A blank src attribute can trigger http requests and should be avoided. The placeholder gives the image position in the page so that it's not skipped as a hidden element */
      if (settings.usePlaceholder) {
        $addImgs.filter('[data-src],[data-src-responsive]').attr('src', settings.placeholder);
      }

      _$images = (_$images === false) ? $addImgs : _$images.add($addImgs);

      if ($addImgs.length) {
        trackInview($window, $addImgs.data(settingsKey, settings), earlyLoad ? defaultOffset : 0);
      } else {
        debug.warn('no lazy-loadable images found in element:', $element);
      }
    },

    /**
     *
     * @param $container
     * Utility method for lazy loading images outside of the viewport.
     * By default it will not reload images that have already been lazy loaded
     */
    lazyLoadImgsOutOfView($container) {
      if ($container) {
        let $addImgs = $container.find('img');
        if ($addImgs.length) {
          $addImgs.each( (index, img) => {
            const src = img.getAttribute('src');
            const dataSrc = img.getAttribute('data-src');
            if ( dataSrc && src !== dataSrc ) {
              img.setAttribute('src', dataSrc);
              debug.log(`${loadEventEnglish} fired. on ${dataSrc}`);
            }
          });
        }
      }
    },

    loadImages, //  Used when images are not in view but we still need to load them dynamically

    destroy() {
      service.inited = false;
      _$images = false;
      $window.off(loadEvent);
      debug.log(`destroying service and unbinding ${loadEventEnglish}.`);
      return service;
    }

  };

  return service;

});
