SNI.Application.addModule('page', (context) => {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  const deviceType = context.getService('device-type');
  const cookie = context.getService('cookie');
  const utils = context.getService('utility');
  const loadBehaviors = ['deferred-loader', 'router', 'media-stream', 'track-shopping'];
  const debug = context.getService('logger').create('module.page');
  const internationalDialog = context.getService('internationalDialog');

  if (window.location.search.match(/[\&|\?]themeSelect/gi) || context.getGlobal('cqWCMshowThemeSelector')) {
    loadBehaviors.push('theme-selector');
  }

  //when author tools are specifically turned off, keep query string params while clicking around the website
  function adjustUrl() {
    let eles = [];
    let sels = [
      'a[href$="/en"]',
      'a[href^="/"][href$=".html"]',
      'a[href^="/content/"]:not([href^="/content/dam"],[href$=".html"])',
      '[data-module="select-button"] option[value^="/"][value$=".html"]'
    ];
    $(sels.join(', ')).each(function() {
      let $ele = $(this);
      let nodeName = $ele[0].nodeName.toLowerCase();
      let attrib = (nodeName === 'option') ? 'value' : 'href';
      let url = $ele.attr(attrib);
      if (attrib && url) {
        if (url.match(/[^.html]$/g) && (url.match(/\/en$/g) || url.match(/^\/content\//g))) {
          url += '.html';
        }
        if (deviceType.isMobile && !url.match('\.mobile\.html$')) {
          url = url.replace(/\.html$/, '.mobile.html');
        }
        $ele.attr(attrib, url + window.location.search);
        eles.push($ele[0]);
      }
    });
    if (eles.length) {
      debug.log(`adjustUrl() adjusted ${eles.length} urls`, eles);
    }
  }

  function setLayoutCookie(param) {  //create easy way to toggle layout cookie
    let current = cookie.get('layout');
    if (param === 'desktop') {
      if (current) {
        cookie.remove('layout');
        debug.log('toggling layout cookie to desktop (by removing layout cookie)');
        window.location.reload();
      }
    } else if (current !== param) {
      cookie.set('layout', param);
      debug.log(`toggling layout cookie to ${param}`);
      window.location.reload();
    }
  }

  let layout = utils.getUrlParam(window.location.href, 'layout');
  if (layout.length) {
    setLayoutCookie(layout);
  }

  if (utils.getUrlParam(window.location.href, 'wcmmode') === 'disabled') {
    adjustUrl();
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {
    behaviors: loadBehaviors,

    init() {
      utils.addJavaScriptCssClass();
      // Check country code and show modal to redirect to native site if not from US
      internationalDialog.modal();
    }

  };

});
