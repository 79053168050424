SNI.Application.addModule('header-mobile', function(context) {
  let debug      = context.getService('logger').create('module.mobile-header');
  let $element = $(context.getElement());
  let ads      = context.getService('ads');
  let utility  = context.getService('utility');
  let deviceType = context.getService('device-type');
  let lazy = context.getService('lazy-load');
  let $siteNav, $profileNav;
  let menuState = false;
  let profileState = false;
  let searchState = false;

  let defaults = {
    setNavTop:        true,
    searchExposed:    false,
    highlightHeader:  false,
    useFixedMenus:    false,   // Area header uses fixed menus
    headerSelector:   '[data-header-main]',
    openClass:        'is-Open',
    activeClass:      'is-Active',
    searchOpenClass:  'has-OpenSearch',
    fixMenuOpen:      'has-FixedMenu',
    navMenuSel:       '[data-module="mobile-nav"]',
    navButtonSel:     '[data-type="button-header-nav"]',
    navProfileSel:    '[data-module="nav-profile"]',
    profileButtonSel: '[data-type="button-header-profile"]',
    profileBtnDefault: '.a-Icon--Profile',
    profileBtnActive:  '.a-Icon--Profile-hover',
    searchBoxSel:     '[data-mobile-search-box]',
    searchInputSel:   '[data-type="search-input"]',
    mainNavBar:       '.o-Header__m-Area--Main',
    smartphoneBanner:  '#dfp_smartphone_banner',
    showClass:        'show-nav',
    hideClass:        'hide-nav',
    modalClass:       'modal-overlay',
    overlayClass:     'has-Overlay',
    noScrollClass:    'scroll-off',
    branchBannerClass: 'branch-banner-is-active',
    hasBigBox:         false,
  };

  let config = Object.assign({}, defaults, context.getConfig());

  let $mainHeader = $(config.headerSelector);

  let behaviors = ['toggle-on-message'];

  let hasWindow = typeof window !== 'undefined' && window.document ? true : false;
  const isRecipe =  $(document) && $(document.body).hasClass('recipePage') ? true : false;

  // add IDSP behaviors if Dalton enabled
  if (SNI.Config.useDaltonLogin) {
    if (SNI.Config.brand === 'food')
      behaviors.push('dalton/nav-profile-fn');
    else if (SNI.Config.brand === 'cook')
      behaviors.push('dalton/nav-profile-cc');
    behaviors.push('dalton/login-logout');
    debug.log('Dalton identity service enabled. Adding behaviors: ' + behaviors.join(', '));
  }

  function toggleNav(state) {
    let $menuBtn = $element.find(config.navButtonSel);
    const $container = $('[data-module="mobile-nav"]');
    menuState = state = (typeof state === 'undefined') ? !menuState : state;
    if (searchState) {
      toggleSearch(false);
    }
    lazy.lazyLoadImgsOutOfView($container);
    setNavTop();
    $menuBtn.toggleClass(config.activeClass, state);
    $siteNav.toggleClass(config.openClass, state);
    if (config.useFixedMenus) $(document.body).toggleClass(config.fixMenuOpen, state);  // prevents body scroll
    context.broadcast(state ? 'mobileNav.show' : 'mobileNav.hide');
    $element.find('[data-type="button-close"]').toggle(state);
    setHeaderPosition(state);
    if (state) {
      if (!deviceType.isMobile || config.closeMenuOnClickAnywhere) {
        utility.bindClickOutside($siteNav, 'mobile-header-nav', function(e) {
          toggleNav(false);
        }, $menuBtn[0]);
      }
      if (config.useFixedTopNav) $element.find(defaults.mainNavBar).addClass(defaults.openClass);
      if (SNI.Config.brand === 'hgtv' || SNI.Config.brand === 'tlc') {
        $element.find('.o-Header__m-NavItem').each(function(el) {
          $('.o-Header__a-NavLink', this).text().toLowerCase().indexOf('trending') < 0 ?
            $(this).removeClass('is-Expanded'):
            $(this).addClass('is-Expanded');
        });
      }
    } else {
      utility.unbindClickOutside('mobile-header-nav');
      if (config.useFixedTopNav) $element.find(defaults.mainNavBar).removeClass(defaults.openClass);
    }
  }

  function setNavTop() {
    if ($siteNav.length) {
      if (config.setNavTop) {
        $siteNav.css('top', $element.position().top + $element.outerHeight(true));
      }
    } else {
      debug.error('mobile nav not found!');
    }
  }

  function setHeaderPosition(isOpen) {
    // Recipe with branch banner
    if (deviceType.isMobile && hasWindow && isRecipe) {
      if ($(document.body).hasClass(config.branchBannerClass)) {

        let $altHeader = $('.o-HeaderFresh');
        let $banner = $('#branch-banner-iframe'),
            bannerPosition = 0;

        if (($banner && $banner.length > 0) && !($altHeader && $altHeader.length > 0)) {
          bannerPosition = $banner.position().top;
          // while banner is on
          if ((bannerPosition === 0) && isOpen) {
            $element.find(config.mainNavBar).css('margin-top', $banner.height());
            $element.find(`.${config.openClass}`).css('margin-top', $banner.height());
          } else if (!isOpen) {
            $element.find(config.mainNavBar).css('margin-top', 0);
            $element.find(`.${config.openClass}`).css('margin-top', 0);
          }
        }
      } else {
        // no banner - reset
        $element.find(config.mainNavBar).css('top', 0);
        $element.find(`.${config.openClass}`).css('top', 0);
        $element.find(config.mainNavBar).css('margin-top', 0);
        $element.find(`.${config.openClass}`).css('margin-top', 0);
      }
    }

    // Bigbox
    if (deviceType.isMobile && SNI.Config.brand === 'food' && config.hasBigBox && hasWindow) {
      let $bannerSlot = $('#CS_ratioAdSlot');
      $(window).scrollTop($bannerSlot && $bannerSlot.length > 0 ? $bannerSlot.height() : 0);
    }
  }

  function toggleSearch(state) {
    if (config.searchExposed) return;
    searchState = state = (typeof state === 'undefined') ? !searchState : state;
    let $searchWrap = $element.find(config.searchBoxSel);

    if (state) {
      if (menuState) {
        toggleNav(false);
      }
      // $('html, body').animate({ scrollTop: 0 });
      utility.bindClickOutside($searchWrap, 'mobile-header-search', function(e) {
        toggleSearch(false);
      }, null, ['click']);
      if (config.highlightHeader) {
        $mainHeader.addClass(config.searchOpenClass); // used for main header
        $(document.body).addClass(config.fixMenuOpen);
        context.broadcast('openSearch');
      }
    } else {
      utility.unbindClickOutside('mobile-header-search');
      $element.find(config.searchInputSel).val('');
      if (config.highlightHeader) {
        $mainHeader.removeClass(config.searchOpenClass);
        $(document.body).removeClass(config.fixMenuOpen);
        context.broadcast('closeSearch');
      }
    }
    if (config.lockScroll && context.getService('device-type').isMobile) {
      $(document.body).toggleClass('no-scroll', state);
    }
    $searchWrap.toggleClass(config.openClass, state);
    $element.toggleClass(config.searchOpenClass, state);
    if (state) {
      $element.find(config.searchInputSel).focus();
    }
  }

  function toggleProfile(state = !profileState) {
    debug.log('header nav - profile menu: ', state ? 'open' : 'close');
    let $profileBtn = $element.find(config.profileButtonSel);
    profileState = state;
    if (searchState) {
      toggleSearch(false);
    }
    $profileNav.toggleClass(config.openClass, state);
    $element.find('[data-type="button-close"]').toggle(state);
    if (state) {
      addOverlay();
      if (menuState) {
        toggleNav(false);
      }
      $profileBtn.find(config.profileBtnActive).show();
      $(config.profileBtnDefault).hide();
      if ($profileNav.hasClass(defaults.openClass)) {
        $siteNav.toggleClass(config.openClass, false);
      }
      if (config.useFixedTopNav) $element.find(defaults.mainNavBar).addClass(defaults.openClass);
      if (hasWindow) $(document.body).addClass(config.fixMenuOpen);
    } else {
      removeOverlay();
      if (hasWindow) $(document.body).removeClass(config.fixMenuOpen);
      var bannerSlot = $('#CS_ratioAdSlot');
      var bannerHeight = bannerSlot && bannerSlot.length > 0 ? bannerSlot.height() : 0;
      $('html, body').animate({ scrollTop: bannerHeight });
      utility.unbindClickOutside('mobile-header-profile');
      $profileBtn.find($(config.profileBtnActive)).hide();
      $(config.profileBtnDefault).show();
      if (config.useFixedTopNav) $element.find(defaults.mainNavBar).removeClass(defaults.openClass);
    }
    setHeaderPosition(state);
  }

  function addOverlay() {
    let overlay = $(`.${config.modalClass}`);
    if (!overlay.length) {
      let div = $(`<div class="${config.modalClass}"></div>`);
      if (hasWindow) {
        $(document.body).addClass(`${config.overlayClass} ${config.noScrollClass}`).prepend(div);
        $(`.${config.modalClass}`).css({'top': 0, 'padding-bottom': $(document).height()});
      }
    }
  }

  function removeOverlay() {
    if (hasWindow) {
      $(document.body).removeClass(`${config.overlayClass} ${config.noScrollClass}`);
    }
    $(`.${config.modalClass}`).remove();
  }

  let clickHandler = {
    'button-header-profile': (event, element) => {
      toggleProfile();
    },
    'profile-button-close': (event, element) => {
      toggleProfile();
    },
    'button-header-nav': (event, element) => {
      toggleNav();
    },
    'button-search-open': (event, element) => {
      toggleSearch(true);
    },
    'button-search-toggle': (event, element) => {
      let state = !$mainHeader.hasClass(config.searchOpenClass);
      toggleSearch(state);
    },
    'menu-action': (event, element) => {
      if (!$(event.target).hasClass('o-Header__a-NavLink--Cta') &&
          !$(event.target).hasClass('o-Header__a-Cta')) {
        event.stopPropagation();
        event.preventDefault();


        (SNI.Config.brand === 'hgtv' || SNI.Config.brand === 'tlc') && $element.find('.o-Header__m-NavItem').each(function() {
          if (this !== element) {
            $(this).removeClass('is-Expanded');
          }
        });


        $(element).toggleClass('is-Expanded');
      }
    }
  };

  let messageObject = {
    'mobile-nav.toggle': function(state) {
      toggleNav(state);
    },
    'nav-profile.toggle': function(state) {
      toggleProfile(state);
    },
    'remove-nav-overlay': (el) => {
      if (hasWindow) $(document.body).removeClass(config.fixMenuOpen);
      removeOverlay();
      if (el === 'profile') {
        profileState = false;
      }
    }
  };

  return {

    config,

    behaviors: behaviors,

    init: function() {
      utility.addJavaScriptCssClass();
      $siteNav = $(config.navMenuSel);
      $profileNav = $(config.navProfileSel);

      if (hasWindow) {
        $(window).on('load', function() {
          let bannerSlot = $('#CS_ratioAdSlot');
          if (bannerSlot && bannerSlot.length > 0) config.hasBigBox = true;
        });
      }

      //keep menu in the right position even after ads load
      ads.event.subscribe('slotRenderComplete', function(slotName, slot) {
        setNavTop();
      });

      // Added this because the typeahead plugin is eating events
      $('[data-type=button-search-cancel]').on('click', event => {
        event.preventDefault();
        toggleSearch(false);
      });
    },

    onclick: (event, element, elementType) => {
      if (elementType in clickHandler) {
        if (elementType !== 'menu-action') {
          event.preventDefault();
          event.stopPropagation();
        }
        clickHandler[elementType](event, element);
      }
    },

    messages: Object.keys(messageObject),

    onmessage(name, data) {
      messageObject[name](data);
    }

  };

});
