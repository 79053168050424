SNI.Application.addModule('asset-description', (context) => {
  const mName = 'asset-description';
  const debug = context.getService('logger').create('module.' + mName);
  
  return {
    behaviors: ['truncate'],
    init() {
      debug.log(`init: ${mName}`);
      SNI.Application.broadcast('behavior-truncate:truncate', {
        moreLessLinks: true,
        suffixString: 'Show More',
        suffixStringLess: 'Show Less',
        dotsWithLinks: '',
      });
    }
  };
});