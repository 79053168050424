/**
 * This file uses a special webpack variable to dynamically set webpack's public
 * path at run time. The reason this is necessary is because our resources
 * currently serve from two paths:
 * 
 * http://food.fnr.sndimg.com
 * http://hgtvhome.sndimg.com
 * 
 * The SNI.JS_RESOURCE_PATH variable is defined on the page with with
 * the correct domain and public path which webpack uses for any
 * dynamic requests it issues. Go here for more info about public
 * paths in webpack:
 * 
 * https://webpack.js.org/configuration/output/#output-publicpath
 */

__webpack_public_path__ =  window.SNI ? window.SNI.JS_RESOURCE_PATH : '' // eslint-disable-line