/**
 * Returns a function taking searchText and an array of typeahead hints.
 * This function iterates over the given array of hints, finds up to
 * maxResults, and appends those to a new array. It then returns an
 * object with the passed in searchText and the new array of hints.
 * 
 * @param {number} maxResults Max number of hints to return from the filter
 * @param {number} searchTermLength Minimum amount of characters a search term must be to return a result
 */
const resultFilter = (maxResults, searchTermLength) => (searchText, results) => {
  const hints = [];

  // Escape valve for search terms too short
  if (searchText.length < searchTermLength) {
    return {
      searchText,
      hints
    };
  }

  for (let i = 0; i < results.length; ++i) {
    let result = results[i].toLowerCase();
    if (result.includes(searchText.toLowerCase())) {
      hints.push(result);
    }
    if (hints.length === maxResults) {
      break;
    }
  }

  return {
    searchText,
    hints
  };

};

export default resultFilter;
