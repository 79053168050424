SNI.Application.addService('media-stream', application => {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  let service,
      ssHistory,
      ssIndex,
      instance,
      defaults;

  let debug = application.getService('logger').create('service.media-stream');
  const check = application.getService('check').new(debug);
  const metadata = application.getService('metadata');
  const deviceType = application.getService('device-type');
  const router = application.getService('router');

  defaults = {};

  function setInstance(newInstance) {
    instance = newInstance;
  }

  function getInstance() {
    if (typeof instance !== 'undefined') {
      return instance;
    } else {
      return false;
    }
  }

  function recordCurrentAndPreviousSS(slideshow) {
    if (check.supports('sessionStorage')) {
      let viewedSlideshows = sessionStorage.getItem('viewedSlideshows') || '[]',
          found = false;

      viewedSlideshows = JSON.parse(viewedSlideshows);

      $.each(viewedSlideshows, function() {
        if (slideshow.url === this.url) {
          found = true;
          return;
        }
      });

      if (!found) {
        viewedSlideshows.push({
          'url': slideshow.url
        });
      }

      if (!ssHistory) {
        ssHistory = [];
      }

      if (!ssIndex) {
        ssIndex = 0;
      }

      ssHistory.push({
        'url': slideshow.url,
        'title': slideshow.title
      });

      sessionStorage.setItem('viewedSlideshows', JSON.stringify(viewedSlideshows));
    }
  }

  function setupNextSS(mediaStreamObject) {
    if (check.supports('sessionStorage')) {
      let viewedSlideshows = sessionStorage.getItem('viewedSlideshows') || '[]',
          viewed = false,
          foundNonViewedSS = false;

      viewedSlideshows = JSON.parse(viewedSlideshows);

      $.each(mediaStreamObject, function(index) {
        let currentStreamItem = this,
            currentUrlMatch = '',
            currentUrl;

        $.each(viewedSlideshows, function(index) {
          if (this.url && this.url.lastIndexOf(currentStreamItem.url) > -1) {
            viewed = true;
            return;
          }
        });

        if (viewed) {
          viewed = false;
        } else {
          foundNonViewedSS = true;
          /*
            REX-414 adding some checks to see if there is a protocol at all... if there isn't then we don't want to run match on the URL
          */
          currentUrl = currentStreamItem.url.match(/^https?:\/\/[^\/]*/);
          if (currentUrl) {
            currentUrlMatch = currentUrl[0];
          }
          if (document.location.origin === currentUrlMatch || currentStreamItem.url.indexOf('http') === -1) {
            defaults.nextSS = currentStreamItem;
            return false;
          }
        }
      });

      if (!foundNonViewedSS) {
        defaults.nextSS = mediaStreamObject[0];
      }
    }
  }

  function getNextSS() {
    if (defaults.nextSS) {
      return defaults.nextSS;
    } else {
      return false;
    }
  }

  function getPreviousSS() {
    if (ssHistory && ssIndex > 0) {
      return ssHistory[(ssIndex - 1)];
    } else {
      return false;
    }
  }

  function gotoSS(direction) {
    let fullURL = (defaults.nextSS && defaults.nextSS.url ? defaults.nextSS.url : false),
        previousUrl;

    if (!fullURL) {
      return false;
    }

    defaults.direction = direction;
    // eslint-disable-next-line no-restricted-properties
    window.cqBasePage = fullURL;

    if (direction === 'next') {
      ssIndex = ssIndex + 1;
    } else {
      fullURL = ssHistory[ssIndex - 1].url;
      ssIndex = ssIndex - 1;
      ssHistory.pop();
      ssHistory.pop();
    }

    defaults.consecutiveGallery = metadata.getGalleryData();

    if (typeof defaults.consecutiveGallery !== 'undefined') {
      defaults.consecutiveGallery++;

      application.broadcast('mediaStream.consecutiveGalleryUpdated', {
        consecutiveGallery: defaults.consecutiveGallery
      });
    }

    if ($('.container-site').length && $('.container-site').hasClass('inline-horizontal') && !deviceType.isMobile) {
      defaults.$curtain = $('.area').clone().addClass('cloneArea').removeClass('area');
      defaults.$curtain.find('.rsArrowLeft').remove();
      defaults.ajaxScrollOffset = $(window).scrollTop();
    }

    application.broadcast('stateChanged', {
      target: 'content',
      href: fullURL
    });

    defaults.streaming = true;

    previousUrl = fullURL;
    application.broadcast('previousUrlChanged', {
      previousURL: previousUrl
    });
  }

  function initializeNewContent(data) {
    if (defaults.direction === 'previous') {
      application.broadcast('mediaStream.headingToPreviousSS');
    } else {
      application.broadcast('mediaStream.headingToNextSS');
    }

    if (data.context.hasClass('area')) {
      let timestamp = new Date().getTime(),
          previousURLMediaStream = router.getPreviousURL();

      application.broadcast('stateChanged', {
        gallery: timestamp,
        launcher: true,
        href: previousURLMediaStream
      });

      // if (deviceType.isMobile && !$('.container-site').hasClass('vertical') || ($('.container-site').length && !$('.container-site').hasClass('inline-horizontal'))) {
      //   $('.photo-gallery-collection').photoLauncher('launchGallery');
      // }
    }
  }

  function lowerCurtain() {
    if ($('.container-site').length && $('.container-site').hasClass('inline-horizontal') && !deviceType.isMobile) {
      defaults.$curtain.insertAfter('.area');
      $('.area').hide();
      $(window).scrollTop(defaults.ajaxScrollOffset);
    }
  }

  function raiseCurtain() {
    if ($('.container-site').length && $('.container-site').hasClass('inline-horizontal') && !deviceType.isMobile) {
      defaults.$curtain.remove();
      $('.area').show();
      if (check.exists('SniAds.Gallery')) {
        application.getGlobal('SniAds').Gallery.reset();
      }
    }
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  service = {
    recordCurrentAndPreviousSS,
    setupNextSS,
    getNextSS,
    getPreviousSS,
    gotoSS,
    initializeNewContent,
    lowerCurtain,
    raiseCurtain,
    setInstance,
    getInstance
  };

  return service;
});
