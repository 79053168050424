import {hideHints} from '../behaviors/typeahead/hints';

/**
 * NOTE: There's an explicit dependency on this selector by the typeahead behavior
 * This selector appears along side the hints class which decorates some css
 * properties on the search form when there are typeahead hints.
 **/
export const SEARCH_FORM_WRAPPER_SELECTOR = '[data-search-form-wrapper]';

/**
 * This selector targets the element that houses the populated class which
 * reveals the clear button when there is text in the field.
 */
export const SEARCH_FORM_FIELD_SELECTOR = '[data-search-form-field]';

/**
 * Present on the field when there is some text in the search form. CSS
 * hooks into this to reveal the clear button.
 */
export const IS_POPULATED_CLASS = 'is-Populated';


/**
 *
 * @param {jQueryElement} $element The jQuery element representing the current module
 * @param {jQueryElement} $input The jQuery element representing the input field to be reset
 * @param {Boolean} focus If the input should be given focus after clearing
 */
export const resetInput = (
  $element,
  $input,
  focus = false
) => {
  focus
    ? $input.val('').focus()
    : $input.val('');
  $element.find(SEARCH_FORM_FIELD_SELECTOR).removeClass(IS_POPULATED_CLASS);
  hideHints($element);
};


SNI.Application.addModule('search-form', context => {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  const defaults = {
    searchType: 'module search'
  };

  let $element = $(context.getElement());
  let config = Object.assign({}, defaults, context.getConfig());
  let debug = context.getService('logger').create('module.search-form');
  let check = context.getService('check').new(debug);
  let util = context.getService('utility');

  let mdManager = context.getGlobal('mdManager');
  let location = context.getGlobal('location');
  let cqWCMDisabled = context.getGlobal('cqWCMDisabled');

  let $searchInput;

  let deviceType = context.getService('device-type');
  let siteSlug = config.searchSite
    ? config.searchSite
    : check.exists('mdManager') && mdManager.getParameter('site')
      ? mdManager.getParameter('site').replace(/-.*$/, '')
      : '';

  // TODO: Break this down and clean it up
  const doSubmit = $form => {
    let formAction = $form.attr('action');

    if (!check.isFriendlyHost() && $form.attr('id') === 'photoSearchInput') {
      formAction = `/content/${siteSlug}-photos/en/photo-library.html`;
    }
    if (deviceType.isMobile && !check.isFriendlyHost()) {
      if (
        formAction.match(/\.html/g) &&
        !formAction.match(/\.mobile.\.html/g)
      ) {
        formAction = formAction.replace(/\.html/g, '.mobile.html');
      }
    }
    if (formAction.indexOf('//') === -1) {
      formAction =
        location.protocol + '//' + util.getMainHostname() + formAction;
    }

    if (config.forceUrl) {
      formAction = config.forceUrl;
    }

    let queryActionSuffix = check.isAuthor()
      ? '-.html' + (cqWCMDisabled ? '?wcmmode=disabled' : '')
      : '-';
    let formTerm = decodeURI($searchInput.val())
      .replace(/\%3D/g, '')
      .replace(/\=/g, '')
      .replace(/\%3C/g, '')
      .replace(/</g, '')
      .replace(/\%28/g, '')
      .replace(/\(/g, '')
      .replace(/\"/g, '')
      .replace(/\%3F/g, '')
      .replace(/\?/g, '');

    $searchInput.val(formTerm);

    let searchQueryAction = formTerm
      .replace(/\-/g, '_')
      .replace(/\s/g, '-')
      .replace(/\%20/g, '-');

    let href = formAction + '/' + searchQueryAction + queryActionSuffix;
    let searchFormMetrics = {internalSearchType: config.searchType};

    if (check.supports('localStorage')) {
      localStorage.setItem(
        'searchFormMetrics',
        JSON.stringify(searchFormMetrics)
      );
    }

    /** If in an iframe, open search in a new window.*/
    if (window.top !== window.self) {
      window.open(href);
    } else {
      /** doing window location here for CQ. If we just change the action and natively submit this form, it will add a querystring (or POST). Either of these methods break CQ caching, so we cannot do it. Because of that, we have to hack a bit with the form submission */
      window.location = href;
    }

    return false;
  };

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  return {
    behaviors: ['typeahead'],
    init() {
      $searchInput = $element.find('[data-type="search-input"]');

      $searchInput.on('input', event => {
        $element.find(SEARCH_FORM_FIELD_SELECTOR).toggleClass(IS_POPULATED_CLASS, event.target.value.length > 0);
      });

      if (config.checkFocusState) {
        $searchInput.on('focus', event => {
          let isActive = $element.data('search-active');
          if (isActive) return;
          $element.data('search-active', true);
          $element.parents('.o-SiteSearch').addClass('has-OpenSearch');
        });
        $('[data-type=button-search-cancel]').on('click', event => {
          event.preventDefault();
          $element.data('search-active', false);
          $element.parents('.o-SiteSearch').removeClass('has-OpenSearch');
        });
      }

    },
    onmousedown(event, element, elementType) {
      if (elementType === 'reset-btn') {
        event.preventDefault();
      }
      if (elementType === 'button-search-cancel') {
        resetInput($element, $searchInput, true);
      }
    },
    onsubmit(event) {
      event.preventDefault();
      doSubmit($element);
    },
    onclick(event, element, elementType) {
      switch (elementType) {
        case 'reset-btn':
          resetInput($element, $searchInput, true);
          break;
        case 'search-button':
          if (config.checkFocusState) {
            event.stopPropagation();
          }
          break;
        case 'search-input':
          if (config.checkFocusState) {
            let isActive = $element.data('search-active');
            if (isActive) return;
            $element.data('search-active', true);
            $element.parents('.o-SiteSearch').addClass('has-OpenSearch');
          }
      }
    }
  };
});
