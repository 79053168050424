/**
 * Modal
 * @author Matt Heisig
 * This module provides a class to handle the creation and control of modals. The basic class creates an empty wrapper,
 * turns it into a modal using the Bootstrap modal, loads content into the modal if provided, and sets up some
 * basic event handlers: show, hide, dismiss, toggle. Returns the Modal class as well an open() function that serves
 * as a wrapper around the Modal class with some presets. The examples below are equivalent, they just provide
 * different levels of control over the modal during creation.
 *
 * @example If you want fine-grained control of the modal instance:
 * var modal = context.getService('modal');
 *
 * var myModal = new modal.create({
 *   modalId: 'emailModal'
 * });
 *
 * myModal.loadContent({
 *   override:  $(modalContent)
 * });
 *
 * myModal.show();
 *
 *
 * @example If you want to pass an ID and some content and not worry about the rest:
 * modal.open('emailModal', modalContent);
 */

SNI.Application.addService('modal', (application) => {

  const modUtil = application.getService('utility');
  const debug = application.getService('logger').create('service.modal');
  const check = application.getService('check').new(debug);
  const emulationPlugin = check.jqueryPlugin('emulateTransitionEnd');
  const template = application.getService('template');
  const modals = [];
  const openClass = 'modal-open';
  const openClassForce = 'modal-force-open';

  /**
   * Modal class definition.
   * @param {object} options    Options to override the defaults
   */
  var Modal = function(options) {
    /** Bail out if no ID is provided */
    if (!options.modalId) {
      return;
    }

    var self = this,
        defaults = {
          content: '',
          closeButtonParent: '',
          isCentered: false,
          hasTransition: false,
          skipFade: false,
          useDefaultTemplate: true,
          plugin: {
            isVideoOverlay: false,
            backdrop: true,
            show: false,
            keyboard: true
          }
        };

    self.settings = $.extend(true, {}, defaults, options);
    self.id = options.modalId;

    /** Register the Bootstrap plugin that does most of the heavy lifting
     *  Now allows the use of two override variables:
     *  backdropAlternate - allows for custom css on a background element
     *  noScroll - allows mobile modal do not allow scrolling when modal is up*/
    if (self.settings.overrides) {
      _registerPlugin(self.settings.overrides);
    } else {
      _registerPlugin();
    }

    /** Add a shell container to the bottom of the DOM that will receive the modal content */
    _addModalContainer(self.settings.modalId);

    self.$container = $('#' + self.settings.modalId);

    /** If we've got content at instantiation, go ahead and add it to the container */
    if (self.settings.content) {
      if (self.settings.content instanceof jQuery) {
        self.settings.content = {
          override: self.settings.content
        };
      }
      self.loadContent(self.settings.content);
    }

    // Turn the DOM node into a modal using Bootstrap's plugin
    self.$container.modal(self.settings.plugin);

    // We're done, so broadcast loaded
    application.broadcast('modal.loaded', {
      modal: self
    });

    debug.log(this.settings.modalId + ' instantiated');
    modals.push(this);
  };


  Modal.prototype.show = function() {
    var self = this,
        hasTransition = this.settings.hasTransition,
        isCentered = this.settings.isCentered,
        skipFade = this.settings.skipFade;

    self.$container.modal('show');

    if (hasTransition) {
      self.$container.addClass('has-Transition');
    }

    if (isCentered) {
      self.$container.addClass('is-Centered');
    }

    if (skipFade) {
      self.$container.addClass('skip-Fade');
    }
    // unbind and cleanup anything that might have leaked out
    self.$container.off('click', '[data-dismiss="modal"]');
    $('body').off('keyup.modaldismiss');

    /** Attach this to the body so that we can escape close it no matter where the user's insertion point is */
    $('body').on('keyup.modaldismiss', $.proxy(function(e) {
      e.which === 27 && this.hide();
      debug.log('escape key pressed on ' + this.settings.modalId);
    }, self));

    /** Setup handler on the close button */
    self.$container.on('click touchend', '[data-dismiss="modal"]:first', e => {
      e.preventDefault();
      e.stopImmediatePropagation();
      self.hide();
    });

    application.broadcast('modal.shown', {
      modal: self
    });

    application.startAll(self.$container);

    debug.log(this.settings.modalId + ' modal shown');
  };

  Modal.prototype.hide = function() {
    let deferStop = this.settings.overrides && this.settings.overrides.deferStop;
    let preventStop = this.settings.overrides && this.settings.overrides.preventStop;

    this.$container.modal('hide');

    this.$container.off('click', '[data-dismiss="modal"]');
    $('body').off('keyup.modaldismiss');

    application.broadcast('modal.hidden', {
      modal: this
    });

    if (!deferStop && !preventStop) { // The modal creator has requested that modules be stopped after modal is hidden
      application.stopAll(this.$container);
    }

    debug.log(this.settings.modalId + ' modal hidden');
  };

  Modal.prototype.toggle = function() {
    var self = this;

    self.$container.modal('toggle');
    application.broadcast('modal.toggled', {
      modal: self
    });

    debug.log(this.settings.modalId + ' modal toggled');
  };

  /**
   *
   * @param {object} modalOptions - DOM share element the user clicked on
   */
  Modal.prototype.loadContent = function(modalContent) {
    var self = this,
        $closeTarget;

    if (!modalContent) {
      return;
    }

    if (typeof modalContent === 'string') {
      modalContent = {
        override: modalContent
      };
    }

    if (modalContent.override) {
      self.$container.html(modalContent.override);
      if (this.settings.closeButtonParent) {
        $closeTarget = self.$container.find(this.settings.closeButtonParent);
      } else {
        $closeTarget = self.$container;
      }
      $closeTarget.prepend(template.modalClose());
      debug.log(this.settings.modalId + ' modal content loaded');

    } else {
      let modalTemplate = template.modal({
        id: modalContent.modalId,
        heading: modalContent.header.title,
        subHeading: modalContent.header.subtitle,
        body: modalContent.body.html,
        actionText: (typeof modalContent.footer !== 'undefined' && typeof modalContent.footer.actionText !== 'undefined') ? modalContent.footer.actionText : '',
        cancelText: (typeof modalContent.footer !== 'undefined' && typeof modalContent.footer.cancelText !== 'undefined') ? modalContent.footer.cancelText : ''
      });

      self.$container.html(modalTemplate);

      debug.log(this.settings.modalId + ' modal content loaded: ', modalTemplate);
    }
  };

  Modal.prototype.addClass = function(className) {
    if (typeof className === 'string') {
      this.$container.addClass(className);
    } else {
      debug.log(`Expect className to be a string, received ${typeof className}`);
    }
  };


  /**
   * Utility function to add a modal container to the dom
   * @private
   *
   * @param {string} modalId  ID of the modal container, e.g. '#myModal'
   */
  function _addModalContainer(modalId) {
    if (!$('#' + modalId).length) {
      $('body').append(template.modalWrap(modalId));
    }
  }




  /**
   * Modal plugin definition. Pulled directly from Bootstrap source with a few notable changes:
   * 1) We've removed the .bs.modal events in favor of using application.broadcast
   * 2) The data- API options have been removed; we're handling those via our module and getSettings utilities,
   * 3) The jQuery plugin registration is performed through the module-utilities
   *
   * @author Bootstrap
   */
  function _registerPlugin(overrides = false) {

    var Modal = function(element, options) {
      this.overrides = overrides;
      this.options = options;
      this.$body = $(document.body);
      this.$element = $(element);
      this.$backdrop =
        this.isShown = null;
      this.scrollbarWidth = 0;
    };

    Modal.DEFAULTS = {
      backdrop: true,
      keyboard: true,
      show: true
    };

    Modal.prototype.toggle = function(_relatedTarget) {
      return this.isShown ? this.hide() : this.show(_relatedTarget);
    };

    Modal.prototype.show = function(_relatedTarget) {
      var that = this;
      var e = $.Event('modal.show', {
        relatedTarget: _relatedTarget
      });

      if (this.isShown || e.isDefaultPrevented()) return;

      this.isShown = true;

      this.checkScrollbar();

      if (overrides.noScroll) {
        overrides.currPosition = $(document).scrollTop();
        this.$body.addClass(openClassForce);
        if (overrides.currPosition) {
          this.$body.css('top', `-${overrides.currPosition}px`);
        }
      } else {
        this.$body.addClass(openClass);
      }

      this.setScrollbar();

      this.backdrop(() => {
        var hasTransition = that.$element.hasClass('fade') || that.$element.hasClass('has-Transition');
        var transition = $.support.transition && emulationPlugin && hasTransition;

        if (!that.$element.parent().length) {
          that.$element.appendTo(that.$body); /** don't move modals dom position  */
        }


        if (hasTransition) {
          that.$element.fadeIn(250, () => {
            // fadeIn is normally fine, but video overlays shouldn't display:none
            //  and need visibility:visible as well as the fadeIn
            if (that.options.isVideoOverlay) {
              that.$element.css('visibility', 'visible');
            }
          });
        } else {
          this.$element.show();
        }

        if (!that.options.preventScrollHijack) {
          window.scrollTo(0, 0);
        } else {
          that.$element.css('margin-top', $(document).scrollTop());
        }

        if (transition) {
          that.$element[0].offsetWidth; /** force reflow  */
        }

        that.$element
          .addClass('in')
          .attr('aria-hidden', false);

        that.enforceFocus();

        transition ?
          that.$element.find('[data-modal-dialog]') /** wait for modal to slide in  */
            .one($.support.transition.end, () => {
              that.$element.trigger('focus');
            })
            .emulateTransitionEnd(300) :
          that.$element.trigger('focus');
      });
    };

    Modal.prototype.hide = function(e) {
      debug.log('proto hide() handle transition end');
      let hasTransition = this.$element.hasClass('fade') || this.$element.hasClass('has-Transition');
      var skipFade = this.$element.hasClass('skip-Fade');
      if (e) e.preventDefault();

      e = $.Event('modal.hide');

      if (!this.isShown || e.isDefaultPrevented()) return;

      this.isShown = false;

      if (overrides.noScroll) {
        this.$body.removeClass(openClassForce);
        if (overrides.currPosition) {
          $(document).scrollTop(overrides.currPosition);
          this.$body.css('top', 'inherit');
        }
      } else {
        this.$body.removeClass(openClass);
      }

      if (hasTransition && !skipFade) {
        this.$element.fadeOut(300);
      }

      this.resetScrollbar();

      $(document).off('focusin.bs.modal');

      this.$element
        .removeClass('in')
        .attr('aria-hidden', true)
        .off('click.dismiss.bs.modal');

      if ($.support.transition && emulationPlugin && hasTransition) {
        this.$element
          .one($.support.transition.end, $.proxy(this.hideModal, this))
          .emulateTransitionEnd(300);
      } else {
        this.hideModal();
      }
    };

    Modal.prototype.enforceFocus = function() {
      $(document)
        .off('focusin.bs.modal') /** guard against infinite focus loop  */
        .on('focusin.bs.modal', $.proxy(function(e) {

          if (this.$element[0] !== e.target && !this.$element.has(e.target).length) {
            this.$element.trigger('focus');
          }
        }, this));
    };

    Modal.prototype.hideModal = function() {
      debug.log('hideModal()');
      var that = this;

      this.$element.trigger('hide');

      // 1.4 Universal Player updates:
      // video player shouldn't be display: none'd
      // please read https://sni-digital.atlassian.net/wiki/display/VPC/Working+with+the+player+in+a+Modal
      if (this.options.isVideoOverlay) {
        this.$element.css('visibility', 'hidden');
      } else {
        this.$element.hide();
      }

      this.backdrop(() => {
        that.removeBackdrop();
      });

      if (this.overrides && this.overrides.deferStop && !this.overrides.preventStop) {
        debug.log('Deferred cleanup!');
        application.stopAll(this.$element);
      }

      if (this.overrides && this.overrides.clearContents) {
        this.$element.get(0).innerHTML = '';
      }
    };

    Modal.prototype.removeBackdrop = function() {
      this.$backdrop && this.$backdrop.remove();
      this.$backdrop = null;
    };

    Modal.prototype.backdrop = function(callback) {
      var animate = this.$element.hasClass('fade') ? 'fade' : '';

      if (this.isShown && this.options.backdrop) {
        var doAnimate = $.support.transition && emulationPlugin && animate;

        debug.log('this.overrides : ', this.overrides);
        this.$backdrop = this.overrides.backdropAlternate ? $(template.modalBackdrop(animate, this.overrides.backdropAlternate)).appendTo(this.$body) :
          $(template.modalBackdrop(animate)).appendTo(this.$body);

        this.$element.on('click.dismiss.bs.modal', $.proxy(function(e) {
          if (e.target !== e.currentTarget) return;
          this.options.backdrop === 'static' ?
            this.$element[0].focus.call(this.$element[0]) :
            this.hide.call(this);
        }, this));

        if (doAnimate) this.$backdrop[0].offsetWidth; /** force reflow  */

        this.$backdrop.addClass('in');

        if (!callback) return;

        doAnimate ?
          this.$backdrop
            .one($.support.transition.end, callback)
            .emulateTransitionEnd(150) :
          callback();

      } else if (!this.isShown && this.$backdrop) {
        this.$backdrop.removeClass('in');

        $.support.transition && emulationPlugin && this.$element.hasClass('fade') ?
          this.$backdrop
            .one($.support.transition.end, callback)
            .emulateTransitionEnd(150) :
          callback();

      } else if (callback) {
        callback();
      }
    };

    Modal.prototype.checkScrollbar = function() {
      if (document.body.clientWidth >= window.innerWidth) return;
      this.scrollbarWidth = this.scrollbarWidth || this.measureScrollbar();
    };

    Modal.prototype.setScrollbar = function() {
      var bodyPad = parseInt(this.$body.css('padding-right') || 0);
      if (this.scrollbarWidth) this.$body.css('padding-right', bodyPad + this.scrollbarWidth);
    };

    Modal.prototype.resetScrollbar = function() {
      this.$body.css('padding-right', '');
    };

    Modal.prototype.measureScrollbar = function() { /** thx walsh */
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'modal-scrollbar-measure';
      this.$body.append(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      this.$body[0].removeChild(scrollDiv);
      return scrollbarWidth;
    };

    modUtil.registerjQueryPlugin('modal', Modal);
  }

  /** Return the class and the 'open()' convenience wrapper */
  return {

    create(options) {
      return new Modal(options);
    },

    open(modalId, content, isVideo, pluginOverride, resetScroll = false) {
      debug.log('open()');
      debug.table([{
        modalId,
        content,
        isVideo
      }]);
      let modalConfig = {
        modalId,
        content,
        // needed for video player overlay
        plugin: typeof pluginOverride !== 'undefined' ? pluginOverride : {
          isVideoOverlay: (typeof isVideo !== 'undefined') ? isVideo : false,
          preventScrollHijack: resetScroll
        }
      };
      // This extra step is required in order to preserve the parameter sequence
      // Ideally, open should take only one options param
      if (pluginOverride && pluginOverride.fullConfig) {
        modalConfig.closeButtonParent = pluginOverride.fullConfig.closeButtonParent;
      }
      var modal = new Modal(modalConfig);

      modal.show();

      return modal;
    },

    close(modalId) {
      debug.log('close: modalId: ', modalId);

      for (let modal of modals) {
        debug.log('modalId: ', modal.settings.modalId, modalId);
        if (modal.settings.modalId === modalId) {
          debug.log('close: modal.hide');
          modal.hide();
          break;
        }
      }
    },

    getModal(modalId) {
      for (let modal of modals) {
        if (modal.settings.modalId === modalId) {
          return modal;
        }
      }
      return null;
    }

  };
});
