SNI.Application.addModule('header-live-now', (context) => {
  const debug = context.getService('logger').create('module.header-live-now'),
        schedule  = context.getService('schedule'),
        utility   = context.getService('utility');

  let element;

  /**
   * Find and display the currently airing tv show. Or the one
   * on tonight if there's not one currently airing
   */
  function showWhatsLiveNow($element) {
    let lastAired = {};
    let now = new Date();
    let zone = utility.getZone(now);
    let offset = schedule.getOffsetFromZone(zone);
    let nowTime = now;
    let hour = (1000 * 60) * 60;
    nowTime.setHours(now.getHours() - offset);

    // This could be optimized, currently search is Big O n.
    $element.find('[data-time]').each((indexInArray, showNode) => {
      const airTime = new Date($(showNode).attr('data-time'));

      let currentShow = {
        show: showNode,
        airTime: airTime,
      };

      if (nowTime.getTime() > airTime.getTime()) {
        lastAired = currentShow; // this will end up being the last show that started before the current time (generally, the one that is still playing)
      }
    });
    if (nowTime && lastAired.airTime && ((nowTime.getTime() - lastAired.airTime.getTime()) < hour)) {
      //if the last aired show started less than 1 hr ago, it's probably still playing
      $(lastAired.show).show();
      $('[data-watch-btn]').show();
      debug.log('header-live-now: Live Now found:', lastAired);
    } else {
      //otherwise we don't display anything
      $(element).addClass('is-Empty'); // Will hide using CSS
      debug.log('header-live-now: Nothing live now. Last aired show is:', lastAired);
    }

    //remove the other shows from the DOM. they are not needed anymore
    $element.find('[data-time]:not(:visible)').remove();
  }

  function init() {
    element = context.getElement();
    
    // The width here is reduced and optimized for an "inline" live now bar
    // but not for a stacked live now bar like on Discovery.
    // We could add more logic to determine the desired width depending on layout

    let width = window.innerWidth - 120;
    debug.log('header-live-now: initializing');
    
    showWhatsLiveNow($(element));
    $(element).find('.m-LiveNow__a-Headline').css('max-width', width);

    debug.log('header-live-now: initialized');
  }

  return {
    init
  };
});
