SNI.Application.addService('truncate', function(application) {
  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */
  //let debug = application.getService('logger').create('service.truncate');
  //let check = application.getService('check').new(debug);

  let re_str_tagname = '([a-z0-9\-]+)';
  let re_opener = function() { return new RegExp(`<${re_str_tagname}[^\>\<]*\>`, 'gi'); };
  let re_closer = function() { return new RegExp(`</${re_str_tagname}>`, 'gi'); };
  let re_inside_tags = function() { return new RegExp(`(<${re_str_tagname}>)(.*?)(</${re_str_tagname}>)`, 'gi'); };
  let re_ends_in_tag = function(tag = re_str_tagname) { return new RegExp(`</${tag}>$`, 'gi'); };

  const defaults = {
    trimPunctuation: true,
    keepVerticalWhitespaces: false
  };

  function html(html, maxlen, trailer = '', moreoptions = {}) {
    let pieces;
    let output = {};
    let trials = [];
    let attachTrailer = '';

    const config = Object.assign(defaults, moreoptions);

    // Add space before new line for long strings to be truncated well.
    html = config.keepVerticalWhitespaces ? html.replace(/\n/g, ' \n') : html.replace(/\s/g, ' '); //remove vertical whitespace since it's inconsequential and we do not expect it. note this makes <pre> tags unsupported

    if (removeTags(html).length <= maxlen) return html;

    pieces = splitRetainDelimiter(html, '>');
    pieces = splitWithin(pieces, ' <');
    pieces = splitWithin(pieces, ' ', true);

    for (var p=0; p < pieces.length; p++) {
      let trial = getTrial(pieces, 0, p+1, config);
      if (trial.text.length > 0) {
        if (trial.text.length <= maxlen) {
          output = trial;
          trials.push(trial);
          attachTrailer = trailer;
        } else {
          break;
        }
      }
    }
    if (trials.length > 1) {
      for (var q=trials.length-1; q >= 0; q--) {
        if (output.text.length > trials[q].text.length) {
          break;
        } else {
          output = trials[q];
        }
      }
    }
    if (output.html && output.html !== '') {
      output.html = appendTrailer(output.html.trim(), attachTrailer);
    }
    return output.html ? output.html : '';
  }
  function removeTags(str) {
    return removeBrokenTags(
      str
        .replace(re_opener(), '')
        .replace(re_closer(), '')
        .replace(/\ +/g, ' ')
    ).trim();
  }
  function closeTags(string) {
    let openers = findOpeners(string);
    let closers = findClosers(string);
    let unique = openers.filter(function(value, index, self) {
      return self.indexOf(value) === index;
    }).reverse();
    for (var i=0; i < unique.length; i++) {
      let missing = countArrayItems(openers, unique[i]) - countArrayItems(closers, unique[i]);
      if (missing > 0) {
        for (var j=0; j < missing; j++) {
          string += `</${unique[i]}>`;
        }
      }
    }
    return (removeTags(string) === '') ? '' : string;
  }
  //remove final tags cut off in the middle
  function removeBrokenTags(str) {
    return str.replace(new RegExp(`<\/*${re_str_tagname}* *[^>]*$`, 'gi'), '');
  }
  function getTrial(pieces, start, current, options) {
    let html = pieces[start] && pieces[current] ? pieces.slice(start, current).join('') : '';
    html = removeBrokenTags(html);
    if (options.trimPunctuation) {
      html = trimPunctuation(html);
    }
    return {
      html: html,
      text: html.length ? removeTags(html) : ''
    };
  }
  function findOpeners(string) {
    let arr = string.match(re_opener());
    return Array.isArray(arr) ? arr.map(function(item) {
      let pieces = re_opener().exec(item);
      return pieces && pieces.length > 1 ? pieces[1] : item;
    }) : [];
  }
  function findClosers(string) {
    let arr = string.match(re_closer());
    return Array.isArray(arr) ? arr.map(function(item) {
      let pieces = re_closer().exec(item);
      return pieces && pieces.length > 0 ? pieces[1] : item;
    }) : [];
  }
  function appendTrailer(str, trailer) {
    //if ends in a tag, put the trailer inside it, unless it's a link
    let closed = closeTags(str);
    let re_ends_in_link = re_ends_in_tag('a');
    if (closed.match(re_ends_in_link)) {
      str = closed.replace(re_ends_in_link, '</a>' + trailer);
    } else {
      let re_final_tag = re_ends_in_tag();
      let finalTag = re_final_tag.exec(closed);
      finalTag = finalTag && finalTag.length ? finalTag[0] : false;
      if (finalTag) {
        str = closed.replace(re_final_tag, trailer + finalTag);
      } else {
        str += trailer;
      }
    }
    return str;
  }

  // semi-generic functions:

  //split a string into an array, but retain the delimiter appended to the end of each item
  function splitRetainDelimiter(input, delimiter) {
    let marker = '[,|,]';
    return input
      .replace(RegExp(`${delimiter}`, 'gi'), `${delimiter}${marker}`)
      .split(marker);
  }
  function splitWithin(input, delimiter=' ', insideTags = false) {
    let output = [];
    for (var i=0; i < input.length; i++) {
      if (insideTags === false || !input[i].match(re_opener())) { //but not within in tags (attributes are defined that way)
        let addThese = [];
        let text = insideTags ? rangeOfText(input[i]) : false;
        if (text) {
          addThese.push(input[i].substring(0, text.begin));
          addThese = addThese.concat(splitRetainDelimiter(input[i].substring(text.begin, text.end), delimiter));
          addThese.push(input[i].substring(text.end));
        } else {
          addThese = splitRetainDelimiter(input[i], delimiter);
        }
        output = output.concat(addThese);
      } else {
        output.push(input[i]);
      }
    }
    return output;
  }
  function countArrayItems(arr, item) {
    let count = 0;
    for (var i=0; i < arr.length; i++) {
      if (item === arr[i]) count++;
    }
    return count;
  }
  function rangeOfText(str) {
    let m = re_inside_tags().exec(str);
    if (m) {
      return {
        begin: m.index + m[1].length,
        end: m.index + m[1].length + m[2].length
      };
    }
  }
  function trimPunctuation(string) {
    return string.trim().replace(/[^a-z0-9\>]$/gi, '');
  }


  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {
    html,
    removeTags,
    closeTags,
    removeBrokenTags,
  };
});
