/*
 * cookie service: to allow creation of cookies that store key/value pairs and data can be appended or updated
 * code adpated from http://www.quirksmode.org/js/cookies.html
 */
SNI.Application.addService('cookie', (application) => {

  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------

  // creates an object from key/value pairs
  let getPersistValues = function(cookie) {
    let values = {};
    let temp = '';

    $.each(cookie.split('&'), function(k, v) {
      temp = v.split('=');

      if (temp[0]) {
        values[temp[0]] = unescape(temp[1]);
      }
    });

    return values;
  };

  // creates a string like a 'query string'
  let buildPersistString = function(values) {
    let result = [];

    $.each(values, function(k, v) {
      // skip/delete the value if it's null
      if (v !== null) {
        result.push(k + '=' + escape(v));
      }
    });

    return result.join('&');
  };


  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------

  let get = function(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  // leave 'days' blank to expire at end of session
  let set = function(name, value, days, path, domain) {

    let expires =  '';
    let sharedDomain = window.location.hostname.match(/[^\.]*\.*[a-z]*$/g)[0];
    if (sharedDomain && sharedDomain !== 'localhost')
      sharedDomain = '.' + sharedDomain;
    domain = (domain && sharedDomain) ? '; domain=' + sharedDomain : '';

    if (days) {
      let date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = '; expires=' + date.toGMTString();
    }

    if (!path) {
      path = '/';
    }

    document.cookie = name + '=' + value + expires + '; path=' + path + domain;
  };

  let remove = function(name) {
    set(name, '', -1);
  };

  /**
   * reads/writes a single persistent cookie that can be used to store basic key/value information
   * if the value doesn't exist it will be created
   * persist(cookieName, key) - retrieve a value or null if not found
   * persist(cookieName, key, value, [days]) - store a value
   * If the 'value' parameter is null the key/value pair is deleted from the cookie
   */
  let persist = function(cookieName, key, value, days) {
    let cookie = get(cookieName);
    let values;
    days = days || 30;

    // just need to read the value
    if (typeof value === 'undefined') {

      // cookie has not been created so no info exists
      if (cookie === null) {
        return null;

      // try to find the key/value pair
      } else {
        values = getPersistValues(cookie);
        return values[key];
      }

    // writing the value
    } else {

      // create new cookie
      if (cookie === null) {
        values = {};
        values[key] = value;

      // append to the current cookie
      } else {
        values = getPersistValues(cookie);
        values[key] = value;
      }

      set(cookieName, buildPersistString(values), days);
    }
  };

  return {
    SEARCH: 'S',
    UI: 'U',
    get,
    set,
    remove,
    persist
  };

});
