if (!window.MetaDataManager) {

/**
 * MetaData Manager
 * 
 * Any updates to mdManager should be ported over to loader-support-js.jsp on sni-foundation
 * 
 * 
 */

SNI.MetaData = {};

// Parameter name mapping from mdManager to PageDataManager.
SNI.MetaData.CQParamMapping = {
  'CATEGORYDSPNAME': 'categoryDisplayName',
  'CONTENTTAG1': 'contentTag1',
  'CONTENTTAG2': 'contentTag2',
  'DETAILID': 'detailId',
  'DELIVERY_CHANNEL': 'deliveryChannel',
  'DIMENSIONVALUES': 'dimensionValues',
  'FASTFWDID': 'fastFwdId',
  'FILTER': 'searchFilter',
  'HUBPATH': 'hubPath',
  'KEYWORDS': 'searchKeywords',
  'MEALTYPE': 'mealType',
  'MAININGREDIENT': 'mainIngredient',
  'NOSEARCHRESULTS': 'noSearchResults',
  'PAGENUMBER': 'pageNumber',
    'PUBLISHTIME': 'publishTime',
  'SCTNDSPNAME': 'sectionDisplayName',
  'SHOW_ABBR': 'showAbbreviation',
  'SPONSORSHIP': 'sponsorship',
  'SITE': 'siteName',
  'SEARCHTERMS': 'searchTerms',
  'SORT': 'searchSort',
  'SPOTLIGHT_1': 'spotlight1',
  'SPOTLIGHT_2': 'spotlight2',
  'SPOTLIGHT_CENTER_1_NAME': 'spotlightCenter1Name',
  'SPOTLIGHT_CENTER_1_STYLE': 'spotlightCenter1Style',
  'SPOTLIGHT_CENTER_2_NAME': 'spotlightCenter2Name',
  'SPOTLIGHT_CENTER_2_STYLE': 'spotlightCenter2Style',
  'TALENTNAME': 'talentName',
  'UNIQUEID': 'uniqueId',
  'USERID': 'userId',
  'ADKEY1': 'adkey1',
  'ADKEY2': 'adkey2',
  'CLASSIFICATION': 'classification',
  'CUISINE': 'cuisine',
  'DIMENSIONS': 'dimensions',
  'DIFFICULTY': 'difficulty',
  'DISH': 'dish',
  'KEYTERM': 'keyterm',
  'NUTRITION': 'nutrition',
  'OCCASIONS': 'occasions',
  'SOURCE': 'source',
  'TASTE': 'taste',
  'TECHNIQUE': 'technique',
  'TITLE': 'title',
  'TYPE': 'type',
  'URL': 'url',
  'RESTRICTED':'restricted',
  'SUBSECTION': 'SubSection',
  'SUBSECTIONL2': 'SubSectionL2',
  'TAGGROUP1': 'TagGroup1',
  'TAGGROUP2': 'TagGroup2',
  'SHOW': 'Show',
  'PROFESSIOAL': 'Professioal',
  'TALENT': 'Talent',
  'EDITOR': 'Editor',
  'PARTNER': 'Partner',
  'EDITORIALTRACKING': 'EditorialTracking',
  'PUBLISHEDDATE': 'PublishedDate',
  'OVERLAY_UNIQUEID': 'Overlay_UniqueID',
  'OVERLAY_URL': 'Overlay_URL',
  'OVERLAY_TAGGROUP1': 'Overlay_TagGroup1',
  'OVERLAY_TAGGROUP2': 'Overlay_TagGroup2',
  'OVERLAY_SHOW': 'Overlay_Show',
  'OVERLAY_PROFESSIONAL': 'Overlay_Professional',
  'OVERLAY_TALENT': 'Overlay_Talent',
  'OVERLAY_EDITOR': 'Overlay_Editor',
  'OVERLAY_PARTNER': 'Overlay_Partner',
  'TOURPOSITION': 'TourPosition'
};

SNI.MetaData.Parameter = function(){
  var parameters = {};    // object to store parameters

  this.addParameter = function(key, value){
    key = key.toUpperCase();    // always force key to uppercase before insert (case-insensitive insert and lookup)
    if(!parameters[key]) {
      parameters[key] = [];
    }
    parameters[key].push(value);
  };

  this.getParameter = function(key, separator){
    key = key.toUpperCase();    // always force key to uppercase before retrieval (case-insensitive insert and lookup)
    if(!parameters[key]) {
      return;
    }
    return parameters[key].join(separator);
  };

  this.getKeys = function(){
    return parameters;
  };

  this.setParameter = function(key, value){
    key = key.toUpperCase();    // always force key to uppercase before insert (case-insensitive insert and lookup)
    parameters[key] = [];
    parameters[key].push(value);
  };

  this.resetKeys = function() {
    parameters = {};
  };

};



/* -------------------------------------------
      MetaDataManager
------------------------------------------- */
SNI.MetaData.Manager = function(){

  var m = new SNI.MetaData.Parameter();

  this.addParameter = function(key, value) {
    m.addParameter(key, value);

    if (typeof CQ_Analytics == 'object' && CQ_Analytics.PageDataMgr && SNI.MetaData.CQParamMapping) {
      key = key.toUpperCase();
      if (key in SNI.MetaData.CQParamMapping) {
        CQ_Analytics.PageDataMgr.setProperty(SNI.MetaData.CQParamMapping[key], this.getParameterString(key));
      }
    }
  };
  this.addAttribute = this.addParameter;
  this.getParameter = m.getParameter;
  this.getKeys = m.getKeys;
  this.setParameter = function(key, value) {
    m.setParameter(key, value);

    if ( window.CQ_Analytics ){
      if (CQ_Analytics.PageDataMgr && SNI.MetaData.CQParamMapping) {
        key = key.toUpperCase();
        if (key in SNI.MetaData.CQParamMapping) {
          CQ_Analytics.PageDataMgr.setProperty(SNI.MetaData.CQParamMapping[key], this.getParameterString(key));
        }
      }
    }
  };
  this.resetKeys = m.resetKeys;

  // gets the value of the parameter, but returns empty string if parameter doesn't exist
  this.getParameterString = function(key) {
    var s =  this.getParameter(key," ");
    if ( s == null ) {
      s = "";
    }
    return s;
  };

  // these getters are for backwards compatibility; should use getParameterString() now

  // generic getters
  this.getPageType =    function() { return this.getParameterString("Type"); };
  this.getPageTitle =   function() { return this.getParameterString("Title"); };
  this.getSite =      function() { return this.getParameterString("Site"); };
  this.getSctnId =    function() { return this.getParameterString("SctnId"); };
  this.getSctnName =    function() { return this.getParameterString("SctnName"); };
  this.getSponsorship =   function() { return this.getParameterString("Sponsorship"); };
  this.getAbstract =    function() { return this.getParameterString("Abstract"); };
  this.getKeywords =    function() { return this.getParameterString("Keywords"); };
  this.getClassification =function() { return this.getParameterString("Classification"); };
  this.getSctnDspName =   function() { return this.getParameterString("SctnDspName"); };
  this.getCategoryDspName=function() { return this.getParameterString("CategoryDspName"); };
  this.getShowAbbr =    function() { return this.getParameterString("Show_Abbr"); };
  this.getRole =      function() { return this.getParameterString("Role"); };
  this.getDetailId =    function() { return this.getParameterString("DetailId"); };
  this.getPageNumber =  function() { return this.getParameterString("PageNumber"); };
  this.getUniqueId =    function() { return this.getParameterString("UniqueId"); };
  this.getUserId =    function() { return this.getParameterString("UserId"); };
  this.getFilters =     function() { return this.getParameterString("Filters"); };

  // other methods

  this.getSearchTerm = function() {
    var args = parseQueryString ();
    for (var arg in args) {
      var s = arg.toUpperCase();
      if ( s == 'SEARCHSTRING' ){
        return args[arg];
      }
    }
    return "";
  };

  this.setMultimediaFlag = function(flag) {
    if ( flag != null ) {
      this.addParameter("MultimediaFlag",flag);
    } else {
      this.addParameter("MultimediaFlag","");
    }
  };

  this.parseQueryString = function(str) {
    str = str ? str : document.location.search;
    var query = str.charAt(0) == '?' ? str.substring(1) : str;
    var args = {};
    if (query) {
      var fields = query.split('&');
      for (var f = 0; f < fields.length; f++) {
        var field = fields[f].split('=');
        args[unescape(field[0].replace(/\+/g, ' '))] =
      unescape(field[1].replace(/\+/g, ' '));
      }
    }
    return args;
  };

};

// MetaDataManager for backwards compatibility
var MetaDataManager = window.MetaDataManager = SNI.MetaData.Manager;

}
