import '../vendor/sni/sni-metadata';
import './device-type';
import './logger';
import './check';

/**
  * Metadata
  * Author: jpencola
  * A utility object for working with metadata. First use-case is
  * dealing with updates to the existing MDManager global that Scripps
  * exposes. It is assumed/expected that the MdManager object has already
  * been instantiated and is exposed on the window.
  * -------------------------------------------------------------
  */

SNI.Application.addService('metadata', (application) => {
  'use strict';

  let debug     = application.getService('logger').create('service.metadata'),
      device    = application.getService('device-type'),
      check     = application.getService('check').new(debug);

  let galleryData;

  return {

    setGalleryData(data) {
      galleryData = data;
    },

    getGalleryData() {
      if (typeof galleryData !== 'undefined') {
        return galleryData;
      } else {
        return false;
      }
    },

    /**
     * Accepts a valid JSON object and maps the
     * key:value pairs into the mdManager.
     * setParameter() overwrites by default.
    */
    updateFromJSON(json) {

      //debug.log('updateFromJSON() called');

      if (!check.exists('mdManager')) {
        debug.warn('updateFromJSON() mdManager doesn\'t exist');
        return;
      }

      if (typeof json === 'undefined') {
        debug.error('ERROR, No JSON!');
        return;
      }

      //debug.log('updateFromJSON(): Setting mdManager params', json);

      $.each(json, function(key, val) {
        application.getGlobal('mdManager').setParameter(key, val);
      });

    },

    /**
     * Accepts a string, parses and updates mdManager
     */
    updateFromString(string) {

      let mdmJSON = '';

      //debug.log('updateFromString: string: ', string);

      if (typeof(string) === 'undefined') {
        debug.error('ERROR, No String!');
        return;
      }

      if (typeof(string) === 'object') {
        mdmJSON = string;
      } else {
        mdmJSON = JSON.parse(string);
      }

      try {
        this.updateFromJSON(mdmJSON);
      } catch (e) {
        debug.error('invalid mdM json string');
        return false;
      }
    },

    /**
     * Accepts a mdmData object.
     * Returns a string if required properties exist in mData, else returns false.
     */
    updateConsecutiveViewCount(mData) {
      let meeData = JSON.parse(JSON.stringify(mData));
      let isInline = (($('.container-site').hasClass('inline-horizontal') && !device.isMobile) || $('body').hasClass('photoLibraryViewerPage')),
          // check to see if we're dealing with an inline gallery. This is an ugly way of checking - would be nice to have a centralized, agreed-upon way to check for this that isn't so fragile.
          pageNumber,
          uniqueId; // variable to handle inline vs overlay options

      if (meeData && galleryData) {
        if (meeData.PageNumber && (meeData.Overlay_UniqueId || meeData.UniqueID) && galleryData.consecutiveGallery && typeof galleryData.originalGallery !== 'undefined') {
          // if desktop inline or viewer, use uniqueId. otherwise use overlay_uniqueId
          if (isInline){
            if (typeof meeData.UniqueId !== 'undefined'){ // metadata's UniqueId exists
              uniqueId = meeData.UniqueId;
            } else if (typeof meeData.UniqueID !== 'undefined'){ // Perhaps UniqueID exists?
              uniqueId = meeData.UniqueID;
            } else if (typeof meeData.Overlay_UniqueId !== 'undefined'){ // hmm, no inline ID? fall back to Overlay_UniqueId
              uniqueId = meeData.Overlay_UniqueId;
            } else if (typeof meeData.Overlay_UniqueID !== 'undefined'){ // try ID instead of Id
              uniqueId = meeData.Overlay_UniqueID;
            } else {
              debug.error('Inline gallery recognized, but no UniqueId, UniqueID, Overlay_UniqueId, or Overlay_UniqueID are found!');
            }
          } else { // assume Overlay if not inline
            if (typeof meeData.Overlay_UniqueId !== 'undefined'){ // Try Overlay_UniqueId first
              uniqueId = meeData.Overlay_UniqueId;
            } else if (typeof meeData.Overlay_UniqueID !== 'undefined'){ // Try ID instead of Id
              uniqueId = meeData.Overlay_UniqueID;
            } else if (typeof meeData.UniqueId !== 'undefined'){ // No overlay UniqueId? Try just regular UniqueId!
              uniqueId = meeData.UniqueId;
            } else if (typeof meeData.UniqueID !== 'undefined'){ // Try ID instead of Id
              uniqueId = meeData.UniqueID;
            } else {
              debug.error('Overlay gallery recognized, but no UniqueId, UniqueID, Overlay_UniqueId, or Overlay_UniqueID are found!');
            }
          }
          pageNumber = meeData.PageNumber;
          return uniqueId.replace(/^(.+\|)([0-9\-])+$/m, '$1' + pageNumber);
        }
      } else {
        return false;
      }
    }, // end of updateConsecutiveViewCount function
    /**
     * Accepts a mdmData object and an offset.
     * Returns a string of galleryNumber-slideNumber-totalSlideNumber
     */
    getMediaStreamNumber(mData, offset, consec) {
      if (galleryData) {
        let originalGallery = galleryData.originalGallery,
            iterate = consec || 0,
            consecutiveGallery = galleryData.consecutiveGallery,
            pageNumber = (typeof mData !== 'undefined' && JSON.parse(JSON.stringify(mData)).PageNumber) ? JSON.parse(JSON.stringify(mData)).PageNumber : 1;

        if (typeof galleryData.consecutiveGallery !== 'undefined') {
          galleryData.consecutiveGallery = consecutiveGallery + iterate;
        }

        if (typeof galleryData.originalGallery !== 'undefined') {
          galleryData.originalGallery = originalGallery + iterate;
        }

        if (typeof consecutiveGallery !== 'undefined' && typeof originalGallery !== 'undefined' && typeof pageNumber !== 'undefined'){
          return (consecutiveGallery + iterate) + '-' + pageNumber + '-' + (originalGallery + offset + iterate);
        } else {
          debug.error('getMediaStreamNumber(): failed to form media stream number for metdata\'s behavioralInteraction.\n' +
            ' consecutiveGallery: ' + consecutiveGallery + ', originalGallery: ' + originalGallery + ', pageNumber: ' + pageNumber);
          return false;
        }
      }
    }

  };

});
