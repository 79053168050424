SNI.Application.addModule('mobile-nav', (context) => {

  let debug = context.getService('logger').create('mobile-nav');
  let behaviors = [];

  // add IDSP behaviors if Dalton enabled
  if (SNI.Config.useDaltonLogin) {
    if (SNI.Config.brand === 'food')
      behaviors.push('dalton/nav-profile-fn');
    else if (SNI.Config.brand === 'cook')
      behaviors.push('dalton/nav-profile-cc');
    behaviors.push('dalton/login-logout');
    debug.log('Dalton identity service enabled. Adding behaviors: ' + behaviors.join(', '));
  }

  return {
    behaviors,

    messages: ['openSearch'],

    onclick: function(event, element, elementType) {
      if (elementType === 'button-close') {
        event.preventDefault();
        context.broadcast('mobile-nav.toggle', false);
      }
    },

    onmessage: function(name, data) {
      switch (name) {
        case 'openSearch':
          context.broadcast('mobile-nav.toggle', false);
          break;
      }
    }
  };

});
