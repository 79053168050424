/**
 * This fuction returns the HTML template of a single hint.
 * Not to be used alone.
 * 
 * @param {string} result A typeahead hint entry
 * @param {string} searchText The text currently entered into the search box
 */
export const resultItem = (result, searchText) => `
  <li data-type="typeahead-hint">
    <a href="javascript:;">
      <span class="typeahead-display">
        ${result.replace(searchText, `<strong>${searchText}</strong>`)}
      </span>
    </a>
  </li>
`;

/**
 * A function that returns the HTML template of the hints dropdown
 * 
 * @param {string[]} results An array of typeahead hints
 * @param {string} searchText The text currently entered into the search box
 */
export const resultsList = (parentMoleculeClass, results, searchText) => `
  <div class="${parentMoleculeClass}__m-TypeAheadMenu">
    <ul class="typeahead-list">
      ${results.map(result => resultItem(result, searchText)).join('\n')}
    </ul>
  </div>`;
