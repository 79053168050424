/**
 * Newsletter/Reminder Service
 * API for subscribing to newsletters/reminders or getting a list of available newsletters.
 * Author: pfitzgerald
 * Modified: agoodman
 */

SNI.Application.addService('newsletter-subscribe', (application) => {

  /** ==========================================================================
   * BEGIN Deprecated section
   * Avoid using this!
   * Pass subscription URL down through x-config instead from
   * the configuration property subscription-endpoint-<type> where type
   * (as of 4/1/2019) is one of: newsletter | reminder
   */
  const debug = application.getService('debug'),

        // Service call timeout (ms) before giving up
        timeout = 10000,

        getSubscribeUrl = () => {
          return window.location.origin + '/apps/sni-foundation/servlets/blueshiftNewsletterAPI';
        };

  let subscribeType = 'reminder';
  /**
   * END Deprecated section
   * ==========================================================================
   */

  return {
    /**
     * Subscribe an email to one or more newsletter.
     *
     * @params Object settings A set of key/value pairs to configure the request.
     *
     * @params String settings.email The users email address.
     *
     * @params String settings.name The users first name.
     *
     * @params String|Array settings.nl The newsletter id to subscribe.
     * This can be a single newsletter string such as "hgtv_gardens", or an array of newsletter strings
     * such as ['hgtv_gardens', 'hgtv_inspiration']
     *
     * @params String [settings.source] An identifier that describes where this newsletter subscription
     * came from. For example: "2014_HGTV_Dynamic_Rail_Module"
     *
     * @params String [settings.timeout] Timeout in ms for the service call.
     * If not provided will use module.timeout.
     *
     * @returns Promise
     *
     * @example
     * // Subscribe to a single newsletter
     * service.subscribe({
     *   email:'test@mailinator.com',
     *   name:'Admin',
     *   source: '2014_HGTV_Dynamic_Rail_Module',
     *   nl: 'hgtv_gardens'
     * }).done(() => {
     *   alert('You are subscribed!');
     * }).fail(() => {
     *   alert('There was a problem subscribing, try again later.');
     * });
     *
     * @example
     * // Subscribe to multiple newsletters
     * service.subscribe({
     *   email:'test@mailinator.com',
     *   name:'Admin',
     *   source: '2014_HGTV_Dynamic_Rail_Module',
     *   nl: ['hgtv_gardens', 'hgtv_inspiration']
     * }).done(() => {
     *   alert('You are subscribed!');
     * }).fail(() => {
     *   alert('There was a problem subscribing, try again later.');
     * });
     */
    subscribe(settings = {}) {
      let deferred,
          nlData;

      subscribeType = settings.subscribeType || subscribeType;
      let subscribeUrl = settings.subscriptionEndpoint ? window.location.origin + settings.subscriptionEndpoint : getSubscribeUrl(subscribeType);

      // Construct the data for the ajax call
      nlData = {
        email: settings.email,
        firstname: settings.firstname,
        utm_source: settings.source || '',
        brand: settings.nlbrand,
        subscribe_type: settings.subscribeType || 'newsletter'
      };

      if (settings.listId) {
        nlData[settings.listId] = true;
      }

      if (settings.nl) {
        // the nl can be an array or a string
        // if it's not an array, make it one
        if (!Array.isArray(settings.nl)) {
          settings.nl = [settings.nl];
        }

        // add all of the setting in the nl array to
        // the ajax data
        for (let i = 0; i < settings.nl.length; i++) {
          nlData[settings.nl[i]] = true;
        }
      }

      // Create a deferred object that we will return
      deferred = $.Deferred();

      // Don't call the service if we're missing required parameters
      if (subscribeType === 'newsletter') {
        if (!(settings.email && settings.nl)) {
          deferred.reject(undefined, 'malformed', 'Missing required parameters');
          return deferred.promise();
        }
      } else {
        if (!(settings.email && settings.listId)) {
          deferred.reject(undefined, 'malformed', 'Missing required parameters');
          return deferred.promise();
        }
      }

      // Call the service
      $.ajax({
        url: subscribeUrl,
        timeout: settings.timeout || timeout,
        data: nlData,
        dataType: 'json',

      }).done((data, textStatus, jqXHR) => {
        // The service responded to us; however, this does not mean the user
        // was successfully subscribed. We must check the response.
        if (data.success === 'true') {
          deferred.resolve(data, textStatus, jqXHR);
        }  else {
          deferred.reject(jqXHR, 'error', '');
        }
      }).fail((jqXHR, textStatus, errorThrown) => {
        debug.error(arguments);

        // textStatus = "timeout", "error", "abort", "parsererror
        // Although since this is JSONP if there is a parseerror it will probably just fail silently
        deferred.reject(jqXHR, textStatus, errorThrown);
      });

      // Return a promise based on our deferred object
      return deferred.promise();
    }
  };
});
