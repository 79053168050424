let JWT;

export let SAVES_API_ENDPOINT = 'https://api.foodnetwork.com/fn-web/v1'; //  'https://api.foodnetwork.com/fn-web-qa/v1'

// JWT is just the Dalton token (previously a real G!gya JWT)
function makeRequest(type, url, data, success, error) {
  return $.ajax({
    type,
    url,
    headers: {
      Authorization: JWT
    },
    data,
    success,
    error
  });
}

// Set API endpoint
export const setApiEndpoint = (endpoint) => {
  endpoint ? SAVES_API_ENDPOINT = endpoint : false;
};

// Get saved Assets (formerly getRecipes())
export const getAssets = (success, error) => {
  return makeRequest('GET', `${SAVES_API_ENDPOINT}/cards`, {}, success, error);
};

// Save Asset (formerly saveRecipe())
export const saveAsset = (data, success, error) => {
  return makeRequest('POST', `${SAVES_API_ENDPOINT}/cards`, data, success, error);
};

// get asset card (formerly getRecipeCard())
export const getAssetCard = (asset, success, error) => {
  return makeRequest('GET', `${SAVES_API_ENDPOINT}/card?asset_id=${asset.id}&type=${asset.type}`, {}, success, error);
};

// Get Boards (formerly getCollections())
export const getBoards = (cardId, success, error) => {
  return makeRequest('GET', `${SAVES_API_ENDPOINT}/folders?card_id=${cardId}`, {}, success, error);
};

// Add to Board (formerly addToCollection())
export const addToBoard = (assetID, folderID, success, error) => {
  return makeRequest('PUT', `${SAVES_API_ENDPOINT}/folders/${folderID}/cards/${assetID}`, {}, success, error);
};

// Remove asset from Board (formerly removeFromCollection())
export const removeFromBoard = (assetID, folderID, success, error) => {
  return makeRequest('DELETE', `${SAVES_API_ENDPOINT}/folders/${folderID}/cards/${assetID}`, {}, success, error);
};

// Create Board (formerly createCollection())
export const createBoard = (title, success, error) => {
  return makeRequest('POST', `${SAVES_API_ENDPOINT}/folders`, { title }, success, error);
};

// Get Private Notes
export const getPrivateNotes = (recipeID, success, error) => {
  return makeRequest('GET', `${SAVES_API_ENDPOINT}/notes/foodnetwork/recipes/${recipeID}`, {}, success, error);
};

// Save Private Notes
export const savePrivateNotes = (recipeID, note, success, error) => {
  return makeRequest('PUT', `${SAVES_API_ENDPOINT}/notes/foodnetwork/recipes/${recipeID}`, { note }, success, error);
};

// Delete Private Notes
export const deletePrivateNotes = (recipeID, data, success, error) => {
  return makeRequest('DELETE', `${SAVES_API_ENDPOINT}/notes/foodnetwork/recipes/${recipeID}`, {}, success, error);
};

// for Dalton: just pass in the auth token:
export const setSavesToken = (token) => { JWT = token; };
