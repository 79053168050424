SNI.Application.addModule('sub-navigation', (context) => {

  //let debug = context.getService('logger').create('module.sub-navigation');

  return {
    behaviors: [
      'dropdown-menu'
    ]
  };

});
