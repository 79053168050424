/**
* Behavior to handle UI updates to the FN user profile header drop-down in response to Dalton state
* REFACTORING: taking the existing functionality of the module/nav-pofile.js and tweaking for Dalton
*/

SNI.Application.addBehavior('dalton/nav-profile-fn', (context) => {

  const debug = context.getService('logger').create('behavior.dalton.nav-profile-fn'),
        template = context.getService('template'),
        $element = $(context.getElement());

  let defaults = {
    openClass:          'is-Open',
    componentEl:        '.m-NavItemWrap--profile',
    profileIcon:        '.a-Icon--Profile',
    profileIconActive:  '.a-Icon--Profile-hover',
    profileButtonSel:   '[data-type="button-header-profile"]',
    profileLink:        '.profile-path',
    profilePageUrl:     '/user-profile-page',
    menu:               '.o-Header__m-DropdownMenu',
    loggedinMenu:       '.logged-in',
    loggedoutMenu:      '.logged-out',
    defaultAvatar:      template.defaultAvatarHTML()
  };

  const config = Object.assign({}, defaults, context.getConfig()),
        $wrapperEl = $element.find(config.componentEl),
        $menu = $wrapperEl.find(config.menu),
        $avatarEl = $wrapperEl.find(config.profileButtonSel);

  // Registered user avatar
  let showAvatar = (avatar) => {
    if (avatar) {
      const avatarImg = `<img src=${avatar} alt=""/>`;
      $avatarEl.html(avatarImg);
    }
  };

  // SVG smiley avatar for FN header
  let showDefaultAvatar = () => {
    if (config.defaultAvatar) $avatarEl.html(config.defaultAvatar);
  };

  // Default user profile menu (logged-out version) --->  <ul class=".logged-out"></ul>
  // Registered user profile menu --->  <ul class=".logged-in"></ul>
  let hideDefaultMenu = () => {
    if ($menu.find(config.loggedinMenu).length === 0) $menu.append(template.navProfileRegisteredUserMenu);
    $menu.find(config.loggedoutMenu).hide();
    $menu.find(config.loggedinMenu).show();
    // set profile path
    $menu.find(config.profileLink).attr('href', config.profilePageUrl);
  };

  let showDefaultMenu = () => {
    $menu.find(config.loggedinMenu).hide();
    $menu.find(config.loggedoutMenu).show();
  };

  let showRegisteredUserNav = (dta) => {
    showAvatar(dta.avatar);
    requestAnimationFrame(hideDefaultMenu);
  };

  let showLoggedOutUserNav = () => {
    requestAnimationFrame(showDefaultAvatar);
    requestAnimationFrame(showDefaultMenu);
  };

  function updateUI(isLoggedIn, dta) {
    debug.log('updateUI', isLoggedIn);
    if (isLoggedIn) {
      showRegisteredUserNav(dta);
    } else {
      showLoggedOutUserNav();
    }
  }

  const messageHandlers = {
    'dalton:logged-in': (dta) => {
      debug.log('msg recd: Dalton user is logged in ' + JSON.stringify(dta));
      updateUI(true, dta);
    },
    'dalton:logged-out': () => {
      debug.log('msg recd: Dalton user is logged out');
      updateUI(false);
    },
  };

  function init() {
    // inserts markup for logged-out state
    if ($menu.find(config.loggedoutMenu).length === 0) $menu.append(template.navProfileDefaultMenu);
  }

  return {

    init,

    messages: Object.keys(messageHandlers),
    onmessage(message, data) {
      messageHandlers[message](data);
    },

  };

});
