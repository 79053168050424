SNI.Application.addService('dependency-loader', application => {

  const debug = application.getService('logger').create('service.dependency-loader');
  let loadedScripts = [];

  function load(script) {
    let promise = new Promise(
      (resolve, reject) => {
        if (script in loadedScripts) {
          resolve(true);
        } else {
          $.getScript(script)
            .done((script, textStatus) => {
              debug.log('ajaxLoadScript()loaded successfully! It was a: ', textStatus);
              loadedScripts.push(script);
              resolve(true);
            })
            .fail((jqxhr, settings, exception) => {
              debug.log('ajaxLoadScript() source error! Exception: ', exception);
              reject(exception);
            });
        }
      });

    return promise;
  }

  return {
    load
  };

});
