import './logger';

SNI.Application.addService('cloudinary', (application) => {

  const debug = application.getService('logger').create('service.cloudinary');
  const cloudinaryIndicator = /(upload\/)([^\/]*)(\/)*(v1)/gi;

  function cropImg(img, transformOptions = 'w_400,h_300,fl_progressive,q_80,c_fill') {
    const cloudinaryReplacement = `upload/${transformOptions}/v1`;

    // If there is no image or it isn't a string, return an empty string.
    if (img === null || typeof img !== 'string') {
      debug.log(`Expecting img to be a 'String', got ${img}`);
      return '';
    }

    // Make sure it's a Cloudinary image.
    if (cloudinaryIndicator.test(img)) {
      // Replace the current transform with transformOptions.
      let newImg = img.replace(cloudinaryIndicator, cloudinaryReplacement);

      return newImg;
    } else {
      debug.log(`'${img}' is not a Cloudinary image.`);
      return img;
    }
  }

  return {
    cropImg,
  };

});
