SNI.Application.addBehavior('lazy-load', function(context) {
  //-----------------------------------------------------------
  // Private
  //-----------------------------------------------------------
  let $element  = $(context.getElement());
  // let debug = context.getService('logger').create('behavior.lazy-load');

  let config = context.getConfig('lazyLoad');
  let earlyLoad = config && config.earlyLoad;

  let service   = context.getService('lazy-load').init(earlyLoad);

  const defaults = {
    placeholder     : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', // 1x1 transparent gif
    trigger         : 'inview',   // 'scroll', 'inview'
    usePlaceholder  : true
  };

  //-----------------------------------------------------------
  // Public
  //-----------------------------------------------------------
  return {

    init() {
      service.addImages({
        container:  $element,
        settings:   Object.assign({}, defaults, config)
      });
    }

  };

});
