import { runCheck } from './optimizelyCheck';

let util = SNI.Application.getService('utility');
let debug = util.isDebug();

const eventTypes = [
  'click',
  'mouseover',
  'mouseout',
  'mousedown',
  'mouseup',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'keydown',
  'keyup',
  'submit',
  'change',
  'contextmenu',
  'dblclick',
  'input',
  'focusin',
  'focusout',
  'touchstart',
  'touch',
];

SNI.Application.setErrorHandler(function errorHandler(exception) {
  if (debug) {
    console.error(exception); // eslint-disable-line
  } else {
    SNI.Application.fire('error', {
      exception,
    });
  }
});

$(() => SNI.Application.init({ debug, eventTypes }));

runCheck();
